import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import * as en from './assets/locale/en/*.json'
import * as es from './assets/locale/es/*.json'
import * as revolusendEn from './assets/locale/en/revolusend/*.json'
import * as revolusendEs from './assets/locale/es/revolusend/*.json'
import * as revoluexEn from './assets/locale/en/revoluex/*.json'
import * as revoluexEs from './assets/locale/es/revoluex/*.json'
import * as visaInstantEn from './assets/locale/en/visaInstant/*.json'
import * as visaInstantEs from './assets/locale/es/visaInstant/*.json'
import * as directBankingEN from './assets/locale/en/directBanking/*.json'
import * as directBankingES from './assets/locale/en/directBanking/*.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        ...en,
        ...revolusendEn,
        ...revoluexEn,
        ...visaInstantEn,
        ...directBankingEN
      },
      es: {
        ...es,
        ...revolusendEs,
        ...revoluexEs,
        ...visaInstantEs,
        ...directBankingES
      }
    },
    supportedLngs:['en','es'],
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;