import React, { Reducer, Dispatch } from 'react';
import { RevolusendApi } from './services/revolusendApi.service';
import { Config } from './config';
import { Auth } from './services/auth.service';
import { RevoluexApi } from './services/revoluexApi.service';
import { VisaInstantApi } from './services/visaInstantApi.service';
import { DirectBankingMiddlewareApi } from './services/directBankingMiddlewareApi.service';

const config = new Config();
type Data = {
  initializing: boolean;
  isSignedIn: boolean;
  userEmail: string | undefined;
};

export type Context = {
  data: Data;
  setData: Dispatch<Partial<Data>>
};

const RevoluadminContext = React.createContext<Context | undefined>(undefined);

export function useVisaInstantApi(): VisaInstantApi {
  const context = React.useContext(RevoluadminContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluadminProvider');
  }
  return new VisaInstantApi(config, new Auth(config, context));
}

export function useRevolusendApi(): RevolusendApi {
  const context = React.useContext(RevoluadminContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluadminProvider');
  }
  return new RevolusendApi(config, new Auth(config, context));
}

export function useDirectBankingMiddlewareApi(): DirectBankingMiddlewareApi {
  const context = React.useContext(RevoluadminContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluadminProvider');
  }
  return new DirectBankingMiddlewareApi(config, new Auth(config, context));
}

export function useRevoluexApi(): RevoluexApi {
  const context = React.useContext(RevoluadminContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluadminProvider');
  }
  return new RevoluexApi(config, new Auth(config, context));
}

export function useAuth(): Auth {
  const context = React.useContext(RevoluadminContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluadminProvider');
  }
  return new Auth(config, context);
}

export function useContext(): Context {
  const context = React.useContext(RevoluadminContext);
  if (context === undefined) {
    throw new Error('Use Store must be used inside of RevoluadminProvider');
  }
  return context;
}

export const RevoluadminProvider = ({ children }: { children: any }) => {
  const reducer: Reducer<Data, Partial<Data>> = (state: Data, action: Partial<Data>) => {
    return {
      ...state,
      ...action
    };
  }

  const [data, setData] = React.useReducer(
    reducer,
    {
      initializing: true,
      isSignedIn: false,
      userEmail: undefined
    }
  );

  return (
    <RevoluadminContext.Provider value={{ data, setData }}>
      {children}
    </RevoluadminContext.Provider>
  )
}
