import { appendFile } from 'fs';
import { Config } from '../config';
import { Auth } from './auth.service';
import { TransactionListItem } from './types/visaInstant/transactionListItem.type';
import { Card } from './types/visaInstant/card.type';
import { CardRequest } from './types/visaInstant/cardRequest.type';
import { CardRequestStatus } from './types/visaInstant/cardRequestStatus.enum';
import { CardStatus } from './types/visaInstant/cardStatus.enum';
import { Country } from './types/visaInstant/country.type';
import { Currency } from './types/visaInstant/currency.type';
import { DeliveryMethod } from './types/visaInstant/deliveryMethod.type';
import { DetailedCard } from './types/visaInstant/detailedCard.type';
import { DetailedCardRequest } from './types/visaInstant/detailedCardRequest.type';
import { TopupListItem } from './types/visaInstant/topupListItem.type';
import { TopupStatus } from './types/visaInstant/topupStatus.enum';
import { TransactionType } from './types/visaInstant/transactionType.enum';


export class VisaInstantApi {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async getCountries(): Promise<Country[]> {
    const response = await fetch(this.config.VISA_INSTANT_API_URL + '/countries')
    if (!response.ok) {
      throw new Error('Could not fetch countries');
    }
    return (await response.json()).countries;
  }

  async getDeliveryMethods(): Promise<DeliveryMethod[]> {
    const response = await fetch(this.config.VISA_INSTANT_API_URL + '/delivery_methods')
    if (!response.ok) {
      throw new Error('Could not fetch delivery methods');
    }
    return (await response.json()).delivery_methods;
  }

  async listCardRequests(
    filters: {
      countryId?: number;
      deliveryMethodId?: number;
      userPhone?: string;
      userName?: string;
      status?: CardRequestStatus;
    },
    limit: number,
    offset: number
  ): Promise<{ count: number; card_requests: CardRequest[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    if (filters.countryId) {
      params.append('country_id', filters.countryId.toString(10));
    }
    if (filters.deliveryMethodId) {
      params.append('delivery_method_id', filters.deliveryMethodId.toString(10));
    }
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.userPhone) {
      params.append('user_phone', filters.userPhone);
    }
    if (filters.userName) {
      params.append('destination_name', filters.userName);
    }
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/card/requests?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch visa instant card requests');
    }
    return response.json();
  }

  async listCards(
    filters: {
      last4?: string;
      mp?: string
      userPhone?: string;
      userId?: string;
      status?: CardStatus;
    },
    limit: number,
    offset: number
  ): Promise<{ count: number; cards: Card[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    if (filters.last4) {
      params.append('last4', filters.last4);
    }
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.userPhone) {
      params.append('phone_user', filters.userPhone);
    }
    if (filters.userId) {
      params.append('user_id', filters.userId);
    }
    if (filters.mp) {
      params.append('card_mp', filters.mp);
    }
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/cards?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch visa instant cards');
    }
    return response.json();
  }

  async getCardRequest(cardRequestId: string): Promise<DetailedCardRequest> {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/card/requests/' + cardRequestId,
      {
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (response.ok) {
      return response.json();
    }
    throw new Error('Could not get detailed card request');
  }

  async refundRevolupayOrder(revolupayOrderId: string) {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/revolupay_orders/' + revolupayOrderId + '/refund',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not refund revolupay order');
    }
  }
  async cancelCardRequest(cardRequestId: string) {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/card/requests/' + cardRequestId + '/cancel',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not cancel card request.');
    }
  }

  async assignCards(cardRequestId: string, cardMp: string[]) {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/card/requests/' + cardRequestId + '/process',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'content-type': 'application/json'
        },
        body: JSON.stringify({ card_mps: cardMp })
      }
    );
    if (!response.ok) {
      throw new Error('Could not assign cards to card request.');
    }
  }

  async getCardLetter(cardRequestId: string): Promise<string> {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/card/requests/' + cardRequestId + '/letter',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not get card request letter.');
    }
    return (await response.json()).download_url;
  }

  async shipCard(cardRequestId: string, tracking: string | null) {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/card/requests/' + cardRequestId + '/shipped',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'content-type': 'application/json'
        },
        body: JSON.stringify({ tracking_details: tracking })
      }
    );
    if (!response.ok) {
      throw new Error('Could not mark card request as shipped.');
    }
  }

  async getCard(cardId: string): Promise<DetailedCard> {
    const response = await fetch(
      `${this.config.VISA_INSTANT_API_URL}/admin/cards/${cardId}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could get card details.');
    }
    return response.json();
  }

  async cancelCard(cardId: string) {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/cards/' + cardId + '/cancel',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not cancel card.');
    }
  }

  async listTopups(
    filters: {
      status?: TopupStatus;
      cardId?: number;

    },
    limit: number,
    offset: number
  ): Promise<{ count: number; topups: TopupListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.cardId) {
      params.append('card_id', filters.cardId.toString(10));
    }
    params.append('order', 'DESC');
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/topups?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch visa instant topups');
    }
    return response.json();
  }

  async listTransactions(
    filters: {
      cardId?: number;
      valid?: boolean;
      type?: TransactionType;
    },
    limit: number,
    offset: number
  ): Promise<{ count: number; transactions: TransactionListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    if (filters.type) {
      params.append('type', filters.type);
    }
    if (filters.cardId) {
      params.append('card_id', filters.cardId.toString())
    }

    if (filters.valid) {
      params.append('valid', filters.valid ? 'true' : 'false');
    }
    params.append('order', 'DESC');
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/admin/transactions?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch visa instant transactions');
    }
    return response.json();
  }

  async getCurrencies(): Promise<Currency[]> {
    const response = await fetch(
      this.config.VISA_INSTANT_API_URL + '/currencies',
      {
        method: 'GET',
      }
    );
    if (!response.ok) {
      throw new Error('Could not get visa instant currencies');
    }
    return (await response.json()).currencies;
  }

  async markTopupAsSuccess(topupId: number) {
    const response = await fetch(
      `${this.config.VISA_INSTANT_API_URL}/admin/topups/${topupId.toString(10)}/success`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Couldnt mark topup as success.')
    }
  }

  async buildShippingFile(cardRequestsIds: number[]): Promise<string> {
    const response = await fetch(
      `${this.config.VISA_INSTANT_API_URL}/admin/cards/requests/delivery_file`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'content-type': 'application/json'
        },
        body: JSON.stringify({ card_request_ids: cardRequestsIds })
      }
    );
    if (!response.ok) {
      throw new Error('Couldnt build delivery file.');
    }
    return (await response.json()).download_url;
  }

}