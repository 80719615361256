import React, { BaseSyntheticEvent } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useRevolusendApi } from '../../context';
import { ProductListItem } from '../../services/types/revolusend/product.type';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { Country } from '../../services/types/revolusend/country.type';
import { Provider } from '../../services/types/revolusend/provider.type';
import { Currency } from '../../services/types/revolusend/currency.type';
import { TransactionType } from '../../services/types/revolusend/transactionType';
import { Agent } from '../../services/types/revolusend/agent.type';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { DecoratedFee } from '../../services/types/revolusend/decoratedFee';
import { LoadingButton } from '../../components/loadingButton';
import { ProductTenant } from '../../services/types/revolusend/productTenant.type';
import { Tenant } from '../../services/types/revolusend/tenant.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

type FeeFormErros = {
  tenant: boolean,
  minAmount: boolean,
  maxAmount: boolean,
  fixedAmount: boolean,
  currency: boolean,
  percentageAmount: boolean
};

const UpsertFeeModal = (
  props: {
    open: boolean,
    onClose: () => void,
    fee?: DecoratedFee
    product: ProductListItem
  }) => {
  const api = useRevolusendApi();
  const { open, onClose, fee, product } = props;
  const { t } = useTranslation('revolusendProducts');

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [minAmount, setMinAmount] = React.useState('');
  const [maxAmount, setMaxAmount] = React.useState('');
  const [fixedAmount, setFixedAmount] = React.useState('');
  const [currency, setCurrency] = React.useState<Currency | null>();
  const [tenant, setTenant] = React.useState<Tenant | null>();
  const [percentageAmount, setPercentageAmount] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [errors, setErros] = React.useState<FeeFormErros>({
    tenant: false,
    minAmount: false,
    maxAmount: false,
    fixedAmount: false,
    currency: false,
    percentageAmount: false
  });

  React.useEffect(() => {
    if (props.open) {
      utils.runAsync(async () => {
        setCurrencies(await api.listRevolusendCurrenciesSource());
        setTenants(await api.listTenants());
      });
    }
  }, [props]);

  React.useEffect(() => {
    setMinAmount(utils.getStringOrDefault(fee?.fee.min_amount));
    setMaxAmount(utils.getStringOrDefault(fee?.fee.max_amount));
    setFixedAmount(utils.getStringOrDefault(fee?.fee.cost_fixed_amount, ''));
    let sourceCurrency: Currency | null = null;
    if (fee?.source_currency) {
      sourceCurrency = fee.source_currency;
    }
    let feeTenant: Tenant | null = null;
    if (fee?.tenant) {
      feeTenant = fee.tenant;
    }
    setTenant(feeTenant);
    setCurrency(sourceCurrency);
    setPercentageAmount(utils.getStringOrDefault(fee?.fee.cost_percentage, ''));
    setErros({
      tenant: false,
      minAmount: false,
      maxAmount: false,
      fixedAmount: false,
      currency: false,
      percentageAmount: false
    });
  }, [props]);

  const validate = () => {
    const formErrors = {
      tenant: false,
      minAmount: false,
      maxAmount: false,
      fixedAmount: false,
      currency: false,
      percentageAmount: false
    }
    if (minAmount.length > 0 && isNaN(Number.parseFloat(minAmount))) {
      formErrors.minAmount = true;
    }
    if (maxAmount.length > 0 && isNaN(Number.parseFloat(maxAmount))) {
      formErrors.maxAmount = true;
    }
    if (Number.parseFloat(minAmount) > Number.parseFloat(maxAmount)) {
      formErrors.minAmount = true;
      formErrors.maxAmount = true;
    }
    if (fixedAmount.length > 0 && isNaN(Number.parseFloat(fixedAmount))) {
      formErrors.fixedAmount = true;
    }
    if (currency === null) {
      formErrors.currency = true;
    }
    if (tenant === null) {
      formErrors.tenant = true;
    }
    if (percentageAmount.length > 0 &&
      (
        isNaN(Number.parseFloat(percentageAmount)) ||
        Number.parseFloat(percentageAmount) > 100 ||
        Number.parseFloat(percentageAmount) < 0
      )
    ) {
      formErrors.percentageAmount = true;
    }
    setErros(formErrors);
    return Object.values(formErrors).find(v => v === true) ? false : true
  }

  const remove = () => {
    utils.runAsync(async () => {
      setDeleting(true);
      await api.revolusendDeleteFee(fee!.fee.id);
      onClose();
    }, () => {
      setDeleting(false);
    });
  }
  const upsert = () => {
    if (validate()) {
      utils.runAsync(async () => {
        setLoading(true);
        await api.revolusendUpsertFee(
          product.product.transaction_type_id,
          product.product.destination_country_id,
          product.product.destination_currency_id,
          currency!.id,
          tenant!.id,
          fee?.fee.id,
          {
            min_amount: minAmount.length > 0 ? parseFloat(minAmount) : null,
            max_amount: maxAmount.length > 0 ? parseFloat(maxAmount) : null,
            cost_fixed_amount: fixedAmount.length > 0 ? parseFloat(fixedAmount) : null,
            cost_percentage: percentageAmount.length > 0 ? parseFloat(percentageAmount) : null,
          }
        );
        onClose();
      }, (e) => {
        setLoading(false);
      });
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{fee ? t('EDIT_FEE') : t('ADD_FEE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('MIN_MAX_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setTenant(value);
              }}
              value={tenant}
              disabled={fee?.fee.id !== undefined}
              options={tenants}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('TENANT')}
                  variant='standard'
                  fullWidth={true}
                  error={errors.tenant}
                  helperText={errors.tenant ? t('REQUIRED_ERROR') : ''}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setCurrency(value);
              }}
              value={currency}
              options={currencies}
              autoHighlight
              getOptionLabel={(option) => option.iso}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SOURCE_CURRENCY')}
                  variant='standard'
                  fullWidth={true}
                  error={errors.currency}
                  helperText={errors.currency ? t('CURRENCY_ERROR') : ''}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="minAmount"
              label={t('MIN_AMOUNT')}
              onChange={(e) => setMinAmount(e.target.value)}
              value={minAmount}
              error={errors.minAmount}
              helperText={errors.minAmount ? t('MIN_AMOUNT_ERROR') : ''}
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('MAX_AMOUNT')}
              onChange={(e) => setMaxAmount(e.target.value)}
              value={maxAmount}
              error={errors.maxAmount}
              helperText={errors.maxAmount ? t('MAX_AMOUNT_ERROR') : ''}
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('FIXED_AMOUNT')}
              error={errors.fixedAmount}
              value={fixedAmount}
              helperText={errors.fixedAmount ? t('FIXED_AMOUNT_ERROR') : ''}
              onChange={(e) => setFixedAmount(e.target.value)}
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('PERCENTAGE_AMOUNT')}
              value={percentageAmount}
              error={errors.percentageAmount}
              helperText={errors.percentageAmount ? t('PERCENTAGE_AMOUNT_ERROR') : ''}
              onChange={(e) => setPercentageAmount(e.target.value)}
              type='number'
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'%'}</InputAdornment>
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {fee &&
          <LoadingButton variant='outlined' startIcon={<DeleteIcon />} disabled={deleting} loading={deleting} onClick={remove} >
            {t('DELETE')}
          </LoadingButton>
        }
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton disabled={loading} loading={loading} onClick={upsert} color='primary'>
          {fee ? t('UPDATE') : t('ADD')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}

type ProductFormErrors = {
  tenant: boolean,
  fixedAmount: boolean,
  percentageAmount: boolean,
}

const UpsertProductTenantModal = (
  props: {
    open: boolean,
    onClose: () => void,
    product: ProductListItem,
    productTenants: ProductTenant[],
    productTenant?: ProductTenant
  }) => {
  const api = useRevolusendApi();
  const { open, onClose, product, productTenant, productTenants } = props;
  const { t } = useTranslation('revolusendProducts');

  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [tenant, setTenant] = React.useState<Tenant | null>();
  const [fixedAmount, setFixedAmount] = React.useState('0');
  const [percentageAmount, setPercentageAmount] = React.useState('0');
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [errors, setErros] = React.useState<ProductFormErrors>({
    tenant: false,
    fixedAmount: false,
    percentageAmount: false,
  });

  React.useEffect(() => {
    if (props.open) {
      utils.runAsync(async () => {
        setTenants(await api.listTenants());
      });
    }
    setFixedAmount(utils.getStringOrDefault(productTenant?.product.revenue_fixed_amount, '0'));
    setPercentageAmount(utils.getStringOrDefault(productTenant?.product.revenue_percentage, '0'));
    setDisabled(productTenant?.product.disabled ? true : false);
    let existingTenant: Tenant | null = null;
    if (productTenant?.tenant) {
      existingTenant = productTenant.tenant;
    }
    setTenant(existingTenant);
    setErros({
      tenant: false,
      fixedAmount: false,
      percentageAmount: false,
    });
  }, [props]);

  const validate = () => {
    const formErrors = {
      tenant: false,
      fixedAmount: false,
      percentageAmount: false,
    }
    if (tenant === null) {
      formErrors.tenant = true;
    }
    if (fixedAmount.length > 0 && isNaN(Number.parseFloat(fixedAmount))) {
      formErrors.fixedAmount = true;
    }
    if (
      percentageAmount.length > 0 && (
        isNaN(Number.parseFloat(percentageAmount)) ||
        Number.parseFloat(percentageAmount) > 100 ||
        Number.parseFloat(percentageAmount) < 0
      )
    ) {
      formErrors.percentageAmount = true;
    }
    setErros(formErrors);
    return Object.values(formErrors).find(v => v === true) ? false : true
  }

  const update = () => {
    if (validate()) {
      utils.runAsync(async () => {
        setLoading(true);
        await api.revolusendUpdateProduct({
          id: product.product.id,
          tenant_id: tenant!.id,
          revenue_fixed_amount: fixedAmount.length > 0 ? parseFloat(fixedAmount) : undefined,
          revenue_percentage: percentageAmount.length > 0 ? parseFloat(percentageAmount) : undefined,
          disabled
        });
        onClose();
      }, (e) => {
        setLoading(false);
      });
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('UPSERT_PRODUCT_TENANT')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('UPSERT_PRODUCT_TENANT_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setTenant(value);
              }}
              value={tenant}
              disabled={productTenant !== undefined}
              options={productTenant === undefined ? tenants.filter(t => ((productTenants.find(pt => pt.tenant.id === t.id) === undefined))) : tenants}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('TENANT')}
                  variant='standard'
                  fullWidth={true}
                  error={errors.tenant}
                  helperText={errors.tenant ? t('REQUIRED_ERROR') : ''}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>
              {t('REVENUE')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('FIXED_AMOUNT')}
              error={errors.fixedAmount}
              value={fixedAmount}
              helperText={errors.fixedAmount ? t('FIXED_AMOUNT_ERROR') : ''}
              onChange={(e) => setFixedAmount(e.target.value)}
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('PERCENTAGE_AMOUNT')}
              value={percentageAmount}
              error={errors.percentageAmount}
              helperText={errors.percentageAmount ? t('PERCENTAGE_AMOUNT_ERROR') : ''}
              onChange={(e) => setPercentageAmount(e.target.value)}
              type='number'
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'%'}</InputAdornment>
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={!disabled} onChange={() => setDisabled(!disabled)} />}
              label={t('ENABLED')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton disabled={loading} loading={loading} onClick={update} color='primary'>
          {t('UPDATE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}

const Row = (props: { product: ProductListItem, open: boolean, toggleDetails: (p: ProductListItem) => void, updateProducts: () => void }) => {
  const { product: p, open, toggleDetails, updateProducts } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation(['revolusendProducts', 'revolusendDeliveryMethod']);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [productFees, setProductFees] = React.useState<DecoratedFee[]>([]);
  const [productTenants, setProductTenants] = React.useState<ProductTenant[]>([]);

  const [editFee, setEditFee] = React.useState<DecoratedFee | undefined>(undefined);
  const [editProductTenant, setEditProductTenant] = React.useState<ProductTenant | undefined>(undefined);
  const [showUpsertFeeModal, setShowUpsertFeeModal] = React.useState(false);
  const [showEditProductTenantModal, setShowUpsertProductTenantModal] = React.useState(false);


  const loadProductDetails = () => utils.runAsync(async () => {
    setLoading(true);
    const productTenantsFromApi = await api.getProductTenants(p.product.id);
    setProductFees(
      await api.listRevolusendProductFees(
        p.product.transaction_type_id,
        p.product.destination_currency_id,
        p.product.destination_country_id,
        productTenantsFromApi.map(p => (p.tenant.id))
      ));
    setProductTenants(productTenantsFromApi);
  }, () => {
    setLoading(false);
  })

  React.useEffect(() => {
    if (open) {
      if (productFees.length === 0) {
        loadProductDetails();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <UpsertFeeModal
        open={showUpsertFeeModal}
        onClose={() => {
          loadProductDetails();
          setShowUpsertFeeModal(false);
          setEditFee(undefined);
        }}
        product={p}
        fee={editFee}
      />
      <UpsertProductTenantModal
        open={showEditProductTenantModal}
        onClose={() => {
          loadProductDetails();
          setShowUpsertProductTenantModal(false);
          setEditProductTenant(undefined);
        }}
        product={p}
        productTenant={editProductTenant}
        productTenants={productTenants}
      />
      <TableRow className={classes.noBottomBorder}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => toggleDetails(p)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{p.product.id}</TableCell>
        <TableCell>{p.provider.name}</TableCell>
        <TableCell>{p.destination_country.name}</TableCell>
        <TableCell>{p.destination_currency.iso}</TableCell>
        <TableCell>{t('revolusendDeliveryMethod:' + p.transaction_type.code)}</TableCell>
        <TableCell>{p.agent.name}</TableCell>
        <TableCell><Chip color={p.product.available ? 'primary' : 'default'} label={t(p.product.available ? 'YES' : 'NO')} /></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginBottom={2} marginTop={1}>
              <Typography variant='body1' align='left'>{t('AMOUNT_RESTRICTIONS')}</Typography>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow className={classes.noBottomBorder}>
                      <TableCell>{t('MIN_AMOUNT')}</TableCell>
                      <TableCell>{t('MAX_AMOUNT')}</TableCell>
                      <TableCell>{t('MULTIPLE_OF')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.noBottomBorder}>
                    <TableRow className={classes.noBottomBorder}>
                      <TableCell>{p.product.min_amount ? `${utils.currencyFormat(p.product.min_amount)} ${p.destination_currency.iso}` : '-'}</TableCell>
                      <TableCell>{p.product.max_amount ? `${utils.currencyFormat(p.product.max_amount)} ${p.destination_currency.iso}` : '-'}</TableCell>
                      <TableCell>{p.product.multiple_of ? p.product.multiple_of : '-'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant='body1' align='left'>
                {t('REVENUE')}
                <IconButton size='small' onClick={() => setShowUpsertProductTenantModal(true)}><AddIcon /></IconButton>
              </Typography>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow className={classes.noBottomBorder}>
                      <TableCell>{t('TENANT')}</TableCell>
                      <TableCell>{t('FIXED_AMOUNT')}</TableCell>
                      <TableCell>{t('PERCENTAGE_AMOUNT')}</TableCell>
                      <TableCell>{t('ENABLED')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.noBottomBorder}>
                    {productTenants.map(pT => (
                      <TableRow className={classes.noBottomBorder} key={`${p.product.id}-${pT.tenant.id}`}>
                        <TableCell>{pT.tenant.name}</TableCell>
                        <TableCell>{`${utils.currencyFormat(pT.product.revenue_fixed_amount)}`}</TableCell>
                        <TableCell>{pT.product.revenue_percentage} %</TableCell>
                        <TableCell>
                          <Chip color={!pT.product.disabled ? 'primary' : 'default'} label={t(!pT.product.disabled ? 'YES' : 'NO')} />
                        </TableCell>
                        <TableCell>
                          <IconButton size='small' onClick={() => {
                            setEditProductTenant(pT);
                            setShowUpsertProductTenantModal(true);
                          }}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant='body1' align='left'>
                {t('FEES')}
                <IconButton size='small' onClick={() => setShowUpsertFeeModal(true)}><AddIcon /></IconButton>
              </Typography>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow className={classes.noBottomBorder}>
                      <TableCell>{t('TENANT')}</TableCell>
                      <TableCell>{t('SOURCE_CURRENCY')}</TableCell>
                      <TableCell>{t('MIN_AMOUNT')}</TableCell>
                      <TableCell>{t('MAX_AMOUNT')}</TableCell>
                      <TableCell>{t('FIXED_AMOUNT')}</TableCell>
                      <TableCell>{t('PERCENTAGE_AMOUNT')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.noBottomBorder}>
                    {productFees.map(f => (
                      <TableRow className={classes.noBottomBorder} key={f.fee.id}>
                        <TableCell>{f.tenant.name}</TableCell>
                        <TableCell>{`${f.source_currency.iso}`}</TableCell>
                        <TableCell>{f.fee.min_amount ? `${utils.currencyFormat(f.fee.min_amount)}` : '-'}</TableCell>
                        <TableCell>{f.fee.max_amount ? `${utils.currencyFormat(f.fee.max_amount)}` : '-'}</TableCell>
                        <TableCell>{`${utils.currencyFormat(f.fee.cost_fixed_amount)}`}</TableCell>
                        <TableCell>{`${f.fee.cost_percentage??0} %`}</TableCell>
                        <TableCell>
                          <IconButton size='small' onClick={() => {
                            setEditFee(f);
                            setShowUpsertFeeModal(true);
                          }}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {loading &&
                <Box display='flex' justifyContent='center'>
                  <CircularProgress />
                </Box>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Products = () => {
  const { t } = useTranslation(['revolusendProducts', 'revolusendDeliveryMethod']);
  const api = useRevolusendApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState<ProductListItem[]>([]);

  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [selectedTenant, selectTenant] = React.useState<Tenant | null>(null);

  const [providers, setProviders] = React.useState<Provider[]>([]);
  const [selectedProvider, selectProvider] = React.useState<Provider | null>(null);

  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<Currency | null>(null);

  const [transactionTypes, setTransactionTypes] = React.useState<TransactionType[]>([]);
  const [selectedTransactionType, selectTransactionType] = React.useState<TransactionType | null>(null);

  const [agents, setAgents] = React.useState<Agent[]>([]);
  const [selectedAgent, selectAgent] = React.useState<Agent | null>(null);

  const [available, setAavailable] = React.useState<boolean>(true);

  const [page, setPage] = React.useState(0);

  const [detailedProduct, setDetailProduct] = React.useState<ProductListItem | null>(null);

  const limit = 15;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { products, count } = await api.listRevolusendProducts({
        providerId: selectedProvider?.id,
        countryId: selectedCountry?.id,
        currencyId: selectedCurrency?.id,
        transactionTypeCode: selectedTransactionType?.code,
        agentId: selectedAgent?.id,
        tenantId: selectedTenant?.id,
        available
      }, limit, page * limit);
      setProducts(products);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  const toggleProductDetail = (p: ProductListItem) => {
    if (detailedProduct?.product.id === p.product.id) {
      setDetailProduct(null);
    } else {
      setDetailProduct(p);
    }
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.listRevolusendCountries());
    });
    utils.runAsync(async () => {
      setProviders(await api.listRevolusendProviders());
    });
    utils.runAsync(async () => {
      setCurrencies(await api.listRevolusendCurrencies());
    });
    utils.runAsync(async () => {
      setTransactionTypes(await api.listRevolusendTransactionTypes());
    });
    utils.runAsync(async () => {
      setAgents(await api.listRevolusendAgents());
    });
    utils.runAsync(async () => {
      setTenants(await api.listTenants());
    });
  }, []);

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    setPage(0);
    loadData();
  }, [
    selectedCountry,
    selectedProvider,
    selectedCurrency,
    selectedTransactionType,
    selectedAgent,
    selectedTenant,
    available
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectProvider(value);
              }}
              disabled={loading}
              value={selectedProvider}
              options={providers}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_PROVIDER')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCountry(value);
              }}
              disabled={loading}
              value={selectedCountry}
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_COUNTRY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCurrency(value);
              }}
              disabled={loading}
              value={selectedCurrency}
              options={currencies}
              autoHighlight
              getOptionLabel={(option) => option.iso}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_CURRENCY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectTransactionType(value);
              }}
              value={selectedTransactionType}
              options={transactionTypes}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => t('revolusendDeliveryMethod:' + option.code)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => t('revolusendDeliveryMethod:' + option.code)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_TRANSACTION_TYPE')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              onChange={(event, value) => {
                selectAgent(value);
              }}
              value={selectedAgent}
              options={agents}
              disabled={loading}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_AGENT')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              onChange={(event, value) => {
                selectTenant(value);
              }}
              disabled={loading}
              value={selectedTenant}
              options={tenants}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_TENANT')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid container justify='center' item xs={6} sm={2}>
            <FormControlLabel
              disabled={loading}
              control={<Switch checked={available} onChange={() => setAavailable(!available)} />}
              label={t('AVAILABLE')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('ID')}</TableCell>
                      <TableCell>{t('PROVIDER')}</TableCell>
                      <TableCell>{t('COUNTRY')}</TableCell>
                      <TableCell>{t('CURRENCY')}</TableCell>
                      <TableCell>{t('DELIVERY')}</TableCell>
                      <TableCell>{t('AGENT')}</TableCell>
                      <TableCell>{t('AVAILABLE')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map(p =>
                      <Row
                        product={p}
                        key={p.product.id}
                        toggleDetails={toggleProductDetail}
                        open={detailedProduct?.product.id === p.product.id}
                        updateProducts={() => { loadData() }}
                      />)}
                    {loading && products.length === 0 &&
                      <TableCell colSpan={8}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && products.length === 0 &&
                      <TableCell colSpan={8}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
