import queryString from 'query-string'

export const LoginWindow = {
  show: ({ url, width, height }: { url: string, width: number, height: number }): Promise<{ accessToken: string; idToken: string }> => new Promise((resolve, reject) => {
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const loginWindow = window.open(
      url,
      '',
      `location=0,status=0,width=${width},height=${height},left=${left},top=${top}`
    )
    const pollForToken = setInterval(() => {
      try {
        if (!loginWindow) return;
        if (loginWindow.closed) {
          clearInterval(pollForToken)
          reject(new Error('Login was not completed'))
        }
        const params = queryString.parse(loginWindow.location.hash)
        if (params.access_token && params.id_token) {
          clearInterval(pollForToken)
          resolve({
            accessToken: Array.isArray(params.access_token)?params.access_token[0]:params.access_token,
            idToken: Array.isArray(params.id_token)?params.id_token[0]:params.id_token
          })
          loginWindow.close()
        }
      } catch (e) {
      }
    }, 100)
  })
}