import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useRevoluexApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

import { LoadingButton } from '../../components/loadingButton';
import { Country } from '../../services/types/revolusend/country.type';
import { PromotionType } from '../../services/types/revolusend/promotion.type';
import moment, { max, min, Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { EnabledChain } from '../../services/types/revoluex/enabledChain.type';
import { Chain } from '../../services/types/revoluex/chain.type';
import { AvailableChain } from '../../services/types/revoluex/availableChain.type';
import { time } from 'console';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  })
);



type FormErros = {
  chain: boolean;
  notification_timeout: boolean;
  timeout: boolean;
  margin_error: boolean;
  fee: boolean;
  min_amount: boolean;
  max_amount: boolean;
};

const UpsertModal = (
  props: {
    open: boolean,
    onClose: () => void,
    chain?: EnabledChain
  }) => {
  const api = useRevoluexApi();
  const { open, onClose, chain } = props;
  const { t } = useTranslation('revoluexChains');

  const [chains, setChains] = React.useState<AvailableChain[]>([]);
  const [availableChains, setAvailableChains] = React.useState<AvailableChain[]>([]);
  const [selectedChain, selectChain] = React.useState<AvailableChain | null>(null);
  const [enabled, setEnabled] = React.useState<boolean>(true);
  const [timeout, setTimeout] = React.useState('');
  const [notificationTimeout, setNotificationTimeout] = React.useState('');
  const [fee, setFee] = React.useState('');
  const [marginError, setMarginError] = React.useState('');
  const [minAmount, setMinAmount] = React.useState('');
  const [maxAmount, setMaxAmount] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const [errors, setErros] = React.useState<FormErros>({
    chain: false,
    notification_timeout: false,
    timeout: false,
    fee: false,
    margin_error: false,
    min_amount: false,
    max_amount: false
  });

  React.useEffect(() => {
    utils.runAsync(async () => {
      const availableChains = await api.listAvailableChains();
      const enabledChains = await api.listEnabledChains();
      setAvailableChains(availableChains.filter(ac => {
        if (enabledChains.find(ec => ec.chain.code === ac.chain.code && ec.currency.code === ac.currency.code)) {
          return false;
        }
        return true;
      }));
    });
  }, []);

  React.useEffect(() => {
    if (chain) {
      selectChain({
        chain: chain.chain,
        currency: chain.currency
      })
      setChains([
        ...availableChains,
        {
          chain: chain.chain,
          currency: chain.currency
        }
      ]);
    } else {
      selectChain(null);
      setChains(availableChains);
    }
    setMinAmount(chain?.config.min_amount ? chain.config.min_amount.toString(10) : '');
    setMaxAmount(chain?.config.max_amount ? chain.config.max_amount.toString(10) : '');
    setMarginError(chain?.config.margin_error ? chain.config.margin_error.toString(10) : '');
    setFee(chain?.config.fee ? chain.config.fee.toString(10) : '');
    setTimeout(chain?.config.timeout ? chain.config.timeout.toString(10) : '');
    setNotificationTimeout(chain?.config.notification_timeout ? chain.config.notification_timeout.toString(10) : '');
    setEnabled(chain?.config.enabled ? true : false);
    setErros({
      chain: false,
      notification_timeout: false,
      timeout: false,
      fee: false,
      margin_error: false,
      min_amount: false,
      max_amount: false
    });
  }, [props]);

  const validate = () => {
    const formErrors = {
      chain: false,
      notification_timeout: false,
      timeout: false,
      fee: false,
      margin_error: false,
      min_amount: false,
      max_amount: false
    }
    if (chain === null) {
      formErrors.chain = true;
    }
    if (notificationTimeout.length === 0 || isNaN(Number.parseFloat(notificationTimeout))) {
      formErrors.notification_timeout = true;
    }
    if (timeout.length === 0 || isNaN(Number.parseFloat(timeout))) {
      formErrors.timeout = true;
    }
    if (minAmount.length === 0 || isNaN(Number.parseFloat(minAmount))) {
      formErrors.min_amount = true;
    }
    if (maxAmount.length === 0 || isNaN(Number.parseFloat(maxAmount))) {
      formErrors.max_amount = true;
    }
    if (marginError.length === 0 || isNaN(Number.parseFloat(marginError))) {
      formErrors.margin_error = true;
    }
    if (fee.length === 0 || isNaN(Number.parseFloat(fee))) {
      formErrors.fee = true;
    }
    setErros(formErrors);
    return Object.values(formErrors).find(v => v === true) ? false : true
  }

  const upsert = () => {
    if (validate()) {
      utils.runAsync(async () => {
        setLoading(true);
        await api.upsertChain(chain?.config.id, {
          cryptocurrency_code: selectedChain!.currency.code,
          chain_code: selectedChain!.chain.code,
          min_amount: parseFloat(minAmount),
          max_amount: parseFloat(maxAmount),
          fee: parseFloat(fee),
          margin_error: parseFloat(marginError),
          timeout: parseInt(timeout),
          notification_timeout: parseInt(notificationTimeout),
          enabled: enabled
        });
        onClose();
      }, (e) => {
        setLoading(false);
      });
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{chain ? t('EDIT_MODAL_TITLE') : t('ADD_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('MODAL_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectChain(value);
              }}
              value={selectedChain}
              disabled={chain !== undefined}
              options={chains}
              autoHighlight
              getOptionLabel={(option) => `${option.currency.code.toUpperCase()} - ${option.chain.code.toUpperCase()}`}
              getOptionSelected={(option, value) => option.chain.code === value.chain.code && option.currency.code === value.currency.code}
              renderOption={(option) => `${option.currency.code.toUpperCase()} - ${option.chain.code.toUpperCase()}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('CHAIN')}
                  variant='standard'
                  fullWidth={true}
                  error={errors.chain}
                  helperText={errors.chain ? t('REQUIRED') : ''}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('MIN_AMOUNT')}
              error={errors.min_amount}
              value={minAmount}
              helperText={errors.min_amount ? t('REQUIRED') : ''}
              onChange={(e) => setMinAmount(e.target.value)}
              type='number'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'€UR'}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('MAX_AMOUNT')}
              error={errors.max_amount}
              value={maxAmount}
              helperText={errors.max_amount ? t('REQUIRED') : ''}
              onChange={(e) => setMaxAmount(e.target.value)}
              type='number'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'€UR'}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('FEE')}
              error={errors.fee}
              value={fee}
              helperText={errors.fee ? t('REQUIRED') : ''}
              onChange={(e) => setFee(e.target.value)}
              type='number'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'%'}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('NOTIFICATION_TIMEOUT')}
              error={errors.notification_timeout}
              value={notificationTimeout}
              helperText={errors.notification_timeout ? t('REQUIRED') : ''}
              onChange={(e) => setNotificationTimeout(e.target.value)}
              type='number'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'>{t('SECONDS')}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('TIMEOUT')}
              error={errors.timeout}
              value={timeout}
              helperText={errors.timeout ? t('REQUIRED') : ''}
              onChange={(e) => setTimeout(e.target.value)}
              type='number'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'>{t('SECONDS')}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('MARGIN_ERROR')}
              error={errors.margin_error}
              disabled={selectedChain === null}
              value={marginError}
              helperText={errors.margin_error ? t('REQUIRED') : ''}
              onChange={(e) => setMarginError(e.target.value)}
              type='number'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'>{selectedChain ? selectedChain.currency.code.toUpperCase() : ''}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enabled}
                  onChange={(e) => setEnabled(e.target.checked)}
                  name={'ENABLED'}
                />
              }
              label={t('ENABLED')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton disabled={loading} loading={loading} onClick={upsert} color='primary'>
          {chain ? t('UPDATE') : t('ADD')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}


export const Chains = () => {

  const { t, i18n } = useTranslation('revoluexChains')
  moment.locale(i18n.language)
  const api = useRevoluexApi();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [chains, setChains] = React.useState<EnabledChain[]>([]);
  const [showUpsertModal, setShowUpsertModal] = React.useState(false);
  const [chainForUpsert, setChainForUpsert] = React.useState<EnabledChain | undefined>(undefined);

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setChains(await api.listEnabledChains());
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(loadData, []);

  return (
    <>
      <UpsertModal
        open={showUpsertModal}
        chain={chainForUpsert}
        onClose={() => {
          loadData();
          setShowUpsertModal(false);
          setChainForUpsert(undefined);
        }}
      />
      <Grid container spacing={2}>
        <Grid item container justify='flex-end' xs={12}>
          <Button variant='outlined' color='secondary' startIcon={<AddIcon />} onClick={() => setShowUpsertModal(true)}>
            {t('ADD')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{t('CURRENCY')}</TableCell>
                        <TableCell>{t('CHAIN')}</TableCell>
                        <TableCell>{t('TIMEOUT')}</TableCell>
                        <TableCell>{t('FEE')}</TableCell>
                        <TableCell>{t('MIN_AMOUNT')}</TableCell>
                        <TableCell>{t('MAX_AMOUNT')}</TableCell>
                        <TableCell>{t('ERROR_MARGIN')}</TableCell>
                        <TableCell>{t('PRECISION')}</TableCell>
                        <TableCell>{t('ENABLED')}</TableCell>
                        <TableCell>{t('CONFIRMATIONS')}</TableCell>
                        <TableCell>{t('MIN_DEPOSIT_AMOUNT')}</TableCell>
                        <TableCell>{t('MIN_SELL_AMOUNT')}</TableCell>
                        <TableCell>{t('MAX_SELL_AMOUNT')}</TableCell>
                        <TableCell>{t('TRADING_ENABLED')}</TableCell>
                        <TableCell>{t('API_TRADING_ENABLED')}</TableCell>
                        <TableCell>{t('DELISTED')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chains.map(c =>
                        <TableRow key={c.chain.code + c.currency.code}>
                          <TableCell>
                            <IconButton
                              size='small'
                              onClick={() => {
                                setChainForUpsert(c);
                                setShowUpsertModal(true);
                              }}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{c.currency.code.toUpperCase()}</TableCell>
                          <TableCell>{c.chain.code.toUpperCase()}</TableCell>
                          <TableCell>{`${c.config.notification_timeout} / ${c.config.timeout} s`}</TableCell>
                          <TableCell>{`${c.config.fee} %`}</TableCell>
                          <TableCell>{c.config.min_amount ? utils.currencyFormat(c.config.min_amount!) + ' €UR' : '-'}</TableCell>
                          <TableCell>{c.config.max_amount ? utils.currencyFormat(c.config.max_amount!) + ' €UR' : '-'}</TableCell>
                          <TableCell>{c.config.margin_error}</TableCell>
                          <TableCell>{c.config.precision}</TableCell>
                          <TableCell><Chip label={c.config.enabled ? t('YES') : t('NO')} color={c.config.enabled ? 'primary' : 'default'} /></TableCell>
                          <TableCell>{c.chain.confirmations}</TableCell>
                          <TableCell>{c.chain.min_deposit_amount}</TableCell>
                          <TableCell>{c.currency.min_sell_amount}</TableCell>
                          <TableCell>{c.currency.max_sell_amount}</TableCell>
                          <TableCell><Chip label={c.currency.trading_enabled ? t('YES') : t('NO')} color={c.currency.trading_enabled ? 'primary' : 'default'} /></TableCell>
                          <TableCell><Chip label={c.currency.api_trading_enabled ? t('YES') : t('NO')} color={c.currency.api_trading_enabled ? 'primary' : 'default'} /></TableCell>
                          <TableCell><Chip label={c.currency.delisted ? t('YES') : t('NO')} color={c.currency.delisted ? 'primary' : 'default'} /></TableCell>
                        </TableRow>
                      )}
                      {loading && chains.length === 0 &&
                        <TableCell colSpan={17}>
                          <Box padding={2}>
                            <Grid container justify='center' item xs={12}>
                              <CircularProgress />
                            </Grid>
                          </Box>
                        </TableCell>
                      }
                      {!loading && chains.length === 0 &&
                        <TableCell colSpan={17}>
                          <Box padding={2}>
                            <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                            <img className={classes.img} src={notFoundImg} />
                          </Box>
                        </TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </>
  )
}
