import React, { BaseSyntheticEvent } from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useDirectBankingMiddlewareApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Consumer } from '../../services/types/directBanking/consumer.type';
import { OrderListItem } from '../../services/types/directBanking/orderListItem.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

const Row = (props: { orderListItem: OrderListItem }) => {
  const { orderListItem: orderListItem } = props;
  const history = useHistory();
  const { t } = useTranslation(['directBankingOrders']);

  const getTransactionStatusLabel = () => {
    return orderListItem.order.status;
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <a href={'/directbanking/orders/' + orderListItem.order.id} target='_blank'>
            <IconButton size='small'>
              <DetailsIcon />
            </IconButton>
          </a>
        </TableCell>
        <TableCell>{orderListItem.order.id}</TableCell>
        <TableCell>
          {orderListItem.consumer.name}
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(orderListItem.order.amount)} ${orderListItem.order.currency_iso_code}`}
        </TableCell>
        <TableCell>
          <Chip label={orderListItem.order.paid ? t('YES') : t('NO')} color={orderListItem.order.paid ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          <Chip label={t(getTransactionStatusLabel())} color={['COMPLETD', 'REFUSED'].includes(orderListItem.order.status) ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          <Chip label={orderListItem.order.has_refunds ? t('YES') : t('NO')} color={orderListItem.order.has_refunds ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          {orderListItem.order.external_reference}
        </TableCell>
        <TableCell>
          {moment(orderListItem.order.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {orderListItem.order.modified?moment(orderListItem.order.modified).format('DD-MM-YYYY HH:mm:ss'):'--'}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Orders = () => {
  const { t } = useTranslation(['directBankingOrders']);
  const api = useDirectBankingMiddlewareApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState<OrderListItem[]>([]);
  const [consumers, setConsumers] = React.useState<Consumer[]>([]);
  const [selectedConsumer, selectConsumer] = React.useState<Consumer | null>(null);
  const [reference, setReference] = React.useState('');
  const states = [
    'PENDING',
    'REFUSED',
    'COMPLETED',
  ];
  const [selectedStatus, selectStatus] = React.useState<string | null>(null);
  const [paid, setPaid] = React.useState(false);
  const [refunds, setRefunds] = React.useState(false);

  const [page, setPage] = React.useState(0);
  
  const limit = 15;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { orders, count } = await api.listDirectBankingOrders({
        consumerId: selectedConsumer?.id,
        externalReference: reference,
        status: selectedStatus ? selectedStatus : undefined,
        paid,
        hasRefunds: refunds
      }, limit, page * limit, 'desc');
      setOrders(orders);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      setConsumers(await api.listDirectBankingConsumers());
    });
  }, []);

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(0);
      loadData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedStatus,
    reference,
    selectedConsumer,
    paid,
    refunds
  ]);
  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectConsumer(value);
              }}
              value={selectedConsumer}
              options={consumers}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_TENANT')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectStatus(value);
              }}
              value={selectedStatus}
              options={states}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => t(option)}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_STATUS')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label={t('SEARCH_REFERENCE')}
              value={reference}
              disabled={loading}
              onChange={(e) => setReference(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
          <Grid container justify='center' item xs={12} sm={2}>
            <FormControlLabel
              disabled={loading}
              control={<Switch checked={paid} onChange={() => setPaid(!paid)} />}
              label={t('PAID_CHECK')}
            />
          </Grid>
          <Grid container justify='center' item xs={12} sm={2}>
            <FormControlLabel
              disabled={loading}
              control={<Switch checked={refunds} onChange={() => setRefunds(!refunds)} />}
              label={t('REFUNDS_CHECK')}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('ID')}</TableCell>
                      <TableCell>{t('CONSUMER')}</TableCell>
                      <TableCell>{t('AMOUNT')}</TableCell>
                      <TableCell>{t('PAID')}</TableCell>
                      <TableCell>{t('STATUS')}</TableCell>
                      <TableCell>{t('REFUNDED')}</TableCell>
                      <TableCell>{t('EXTERNAL_REFERENCE')}</TableCell>
                      <TableCell>{t('CREATED')}</TableCell>
                      <TableCell>{t('UPDATED')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map(o =>
                      <Row
                        orderListItem={o}
                        key={o.order.id}
                      />)}
                    {loading && orders.length === 0 &&
                      <TableCell colSpan={16}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && orders.length === 0 &&
                      <TableCell colSpan={16}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
