import { Config } from '../config';
import { Auth } from './auth.service';
import { AvailableChain } from './types/revoluex/availableChain.type';
import { Deposit } from './types/revoluex/deposit.type';
import { EnabledChain } from './types/revoluex/enabledChain.type';
import { Topup, TopupStatus } from './types/revoluex/topup.type';
import { TopupDetails } from './types/revoluex/topupDetails.type';
import { TopupListItem } from './types/revoluex/topupListItem.type';
import { UserRestriction } from './types/revoluex/userRestriction.type';

export class RevoluexApi {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async listAvailableChains(): Promise<AvailableChain[]> {
    const response = await fetch(
      `${this.config.REVOLUEX_API_URL}/admin/chains/available`,
      {
        headers: {
          'Authorization': `Bearer ${await this.auth.getAccessToken()}`
        }
      }
    );
    if (response.ok) {
      return (await response.json()).chains
    } else {
      throw new Error('Got wrong response while fetching available chains');
    }
  }

  async listEnabledChains(): Promise<EnabledChain[]> {
    const response = await fetch(
      `${this.config.REVOLUEX_API_URL}/admin/chains/enabled`,
      {
        headers: {
          'Authorization': `Bearer ${await this.auth.getAccessToken()}`
        }
      }
    );
    if (response.ok) {
      return (await response.json()).chains
    } else {
      throw new Error('Got wrong response while fetching available chains');
    }
  }

  async upsertChain(id: number | undefined, chain: {
    chain_code: string;
    cryptocurrency_code: string;
    enabled: boolean;
    notification_timeout: number;
    timeout: number;
    margin_error: number;
    fee: number;
    min_amount: number;
    max_amount: number;
  }) {
    let method = 'POST';
    if (id) {
      method = 'PUT';
    }
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/chains' + (id ? `/${id}` : ''),
      {
        method,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(chain)
      }
    )
    if (!response.ok) {
      throw new Error('Could not upsert chain');
    }
  }

  async listTopups(
    params: {
      limit: number,
      offset: number,
      order: 'asc' | 'desc',
      email?: string,
      cryptocurrency_code?: string,
      status?: TopupStatus
    }): Promise<{ topups: TopupListItem[], count: number }> {
    const query = new URLSearchParams();
    query.append('limit', params.limit.toString(10));
    query.append('offset', params.offset.toString(10));
    query.append('order', params.order);
    if (params.email) {
      query.append('revolupay_user_email', params.email);
    }
    if (params.cryptocurrency_code) {
      query.append('cryptocurrency_code', params.cryptocurrency_code);
    }
    if (params.status) {
      query.append('status', params.status);
    }
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/topups?' + query.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Could not fetch topups');
    }
  }

  async getTopupDetails(topupId: number): Promise<TopupDetails> {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/topups/' + topupId.toString(10),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Could not fetch topup details ' + topupId);
    }
  }

  async listDeposits(
    params: {
      limit: number,
      offset: number,
      order: 'asc' | 'desc',
      cryptocurrency_code?: string,
      refunded?: boolean
    }): Promise<{ deposits: Deposit[], count: number }> {
    const query = new URLSearchParams();
    query.append('limit', params.limit.toString(10));
    query.append('offset', params.offset.toString(10));
    query.append('order', params.order);
    if (params.cryptocurrency_code) {
      query.append('cryptocurrency_code', params.cryptocurrency_code);
    }
    if (params.refunded !== undefined) {
      query.append('refunded', params.refunded ? 'true' : 'false');
    }
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/deposits?' + query.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Could not fetch deposits');
    }
  }

  async getDepositDetails(depositId: number): Promise<Deposit> {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/deposits/' + depositId.toString(10),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (response.ok) {
      return (await response.json()).deposit;
    } else {
      throw new Error('Could not fetch deposit details ' + depositId);
    }
  }

  async createManualTopup(depositId: number, phone: string): Promise<Topup> {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/topups',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ revolupay_user_phone: phone, deposit_id: depositId })
      }
    )
    if (response.ok) {
      return (await response.json()).topup;
    } else {
      throw new Error('Could not create topup');
    }

  }

  async notifyDepositRefund(depositId: number, email: string) {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + `/admin/deposits/${depositId}/refund`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ notification_email: email })
      }
    )
    if (!response.ok) {
      throw new Error('Could not notify deposit refund');
    }
  }

  async approveTopup(topupId: number) {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/topups/' + topupId.toString(10) + '/approve',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not approve topup');
    }
  }

  async getUserRestrictions(): Promise<UserRestriction[]> {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/restrictions/users',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (response.ok) {
      return (await response.json()).user_restrictions;
    } else {
      throw new Error('Could not fetch user restrictions');
    }
  }

  async updateUserRestriction(restriction: UserRestriction) {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/restrictions/users',
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(restriction)
      }
    )
    if (!response.ok) {
      throw new Error('Could not update user restriction');
    }
  }

  async getTopupStatus(): Promise<{ status: boolean }> {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/restrictions',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (response.ok) {
      return { status: (await response.json()).topups_enabled };
    } else {
      throw new Error('Could not fetch global restrictions');
    }
  }

  async setTopupStatus(status: boolean) {
    const response = await fetch(
      this.config.REVOLUEX_API_URL + '/admin/restrictions',
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ topups_enabled: status })
      }
    );
    if (!response.ok) {
      throw new Error('Could not update global restrictions');
    }
  }



}