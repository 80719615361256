import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useDirectBankingMiddlewareApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add'
import { BatchListItem } from '../../services/types/directBanking/batchListItem.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  })
);

const Row = (props: { batch: BatchListItem }) => {
  const { batch } = props;
  const history = useHistory();
  const { t } = useTranslation(['directBankingBatches']);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/directbanking/batches/' + batch.batch.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {'ISO20022 Bank Transfer'}
        </TableCell>
        <TableCell>
          {batch.batch.count}
        </TableCell>
        <TableCell>
          {moment(batch.batch.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const DirectBankingBatches = () => {
  const { t } = useTranslation(['directBankingBatches']);
  const api = useDirectBankingMiddlewareApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [batches, setBatches] = React.useState<BatchListItem[]>([]);

  const [page, setPage] = React.useState(0);

  const limit = 15;

  const classes = useStyles();

  const history = useHistory();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { batches, count } = await api.listDirectBankingBatches({}, limit, page * limit, 'desc');
      setBatches(batches);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(loadData, [page]);

  return (
    <Grid container spacing={2} style={{ marginTop: 15 }}>
      <Grid item container justify='flex-end' xs={12}>
        <Button variant='outlined' color='secondary' startIcon={<AddIcon />} onClick={() => { history.push('/directbanking/newbatch') }}>
          {t('ADD')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('DELIVERY_METHOD')}</TableCell>
                      <TableCell>{t('TOTAL_TRANSACTIONS')}</TableCell>
                      <TableCell>{t('CREATED')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {batches.map(b =>
                      <Row
                        batch={b}
                        key={b.batch.id}
                      />)}
                    {loading && batches.length === 0 &&
                      <TableCell colSpan={7}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && batches.length === 0 &&
                      <TableCell colSpan={7}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
