import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDirectBankingMiddlewareApi } from '../../context';
import utils from '../../services/utils.service';
import moment from 'moment';
import { DirectBankingOrderDetails } from '../../services/types/directBanking/OrderDetails.type';
import { LoadingButton } from '../../components/loadingButton';
import { xml2json } from 'xml-js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
    link: {
      color: 'grey',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(0.9),
      fontSize: '1.1rem',
    },
  })
);


const RefundModal = (props: { orderId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, orderId: orderId } = props;
  const api = useDirectBankingMiddlewareApi();
  const { t } = useTranslation('directBankingOrders');
  const [loading, setLoading] = React.useState(false);
  const [amountToRefund, setAmountToRefund] = React.useState(0);

  const [errors, setErrors] = React.useState({
    inputAmount: false,
    insufficientAmount: false
  });
  const resetErrors = () => {
    setErrors({
      inputAmount: false,
      insufficientAmount: false
    });
  }

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const issueRefund = () => {
    if (!orderId) {
      return;
    }
    utils.runAsync(async () => {
      setErrors({ inputAmount: false, insufficientAmount: false });
      if (amountToRefund <= 0 || !amountToRefund) {
        setErrors({ inputAmount: true, insufficientAmount: false });
        return;
      }
      setLoading(true);
      try {
        await api.issueDirectBankingRefund(orderId, amountToRefund);
        onClose();
      } catch (e) {
        setErrors({ inputAmount: false, insufficientAmount: true })
      }
    }, (e) => {
      setLoading(false);
    })
  }


  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('REFUND_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('REFUND_MODAL_DESCRIPTION')}
        </DialogContentText>
        <TextField
          label={t('AMOUNT')}
          value={amountToRefund}
          type="number"
          inputProps={{
            step: "0.01"
          }}
          error={errors.inputAmount || errors.insufficientAmount}
          helperText={errors.inputAmount ? t('REQUIRED') : (errors.insufficientAmount ? t('INSUFFICIENT_AMOUNT_TO_REFUND') : '')}
          onChange={(e) => { setAmountToRefund(parseFloat(e.target.value)); resetErrors(); }}
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={issueRefund}>
          {t('ISSUE_REFUND_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}
const ResponseDetailsModal = (props: { response?: string, open: boolean, onClose: () => void }) => {
  const { response, open, onClose } = props;
  const { t } = useTranslation('revolusendTransfers');
  let responseString = response ? response : '';

  if (response) {
    try {
      responseString = JSON.stringify(JSON.parse(response), undefined, 2);
    } catch (e) {
      console.log('Could not parse JSON response, trying as XML.');
      try {
        responseString = JSON.stringify(JSON.parse(xml2json(response)), undefined, 2);
      } catch (e) {
        console.log('Could not decode xml response.')
      }
    }
  }
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('RESPONSE_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <pre>{responseString}</pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}


export const OrdersDetails = (props: RouteComponentProps<{ id: string }>) => {
  const orderId = props.match.params.id
  const { t } = useTranslation(['directBankingOrders']);
  const classes = useStyles();
  const api = useDirectBankingMiddlewareApi();
  const history = useHistory();

  const [orderDetails, setOrderDetails] = React.useState<DirectBankingOrderDetails | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const [showResponseModal, setShowResponseModal] = React.useState(false);


  const loadTransactionDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setOrderDetails(await api.getDirectBankingOrderDetails(orderId));
    }, (e) => {
      setLoading(false);
      if (e) {
        console.log(e);
      }
    });
  }


  React.useEffect(() => {
    loadTransactionDetails();
  }, []);

  if (loading && orderDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  const getOrderStatusLabel = () => {
    if (orderDetails === null) return '';
    return orderDetails.order.status;
  }

  const getPaymentStatusLabel = () => {
    if (orderDetails === null || orderDetails.payment === null) return '';
    return orderDetails.payment.status;
  }

  const canRefund = () => {
    if (orderDetails?.order.paid === false) {
      return false;
    }
    const amountRefunded = orderDetails?.refunds.reduce((acc, r) => acc + r.amount, 0) ?? 0;
    return amountRefunded < orderDetails?.order.amount!;
  }

  return (
    <>
      <RefundModal
        orderId={orderDetails?.order.id}
        open={showRefundModal}
        onClose={() => {
          setShowRefundModal(false);
          loadTransactionDetails();
        }} />
      <ResponseDetailsModal open={showResponseModal} response={orderDetails?.payment.response} onClose={() => { setShowResponseModal(false) }} />

      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('ORDER')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ID')} secondary={orderDetails?.order.id} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={t('CONSUMER_EXTERNAL_REFERENCE')}
                          secondary={
                            orderDetails?.order.external_reference ? (
                              <Link href={'/revolusend/transfers/' + orderDetails.order.external_reference} target="_blank" rel="noopener" className={classes.link}>
                                <InfoIcon className={classes.icon} />
                                {orderDetails.order.external_reference}
                              </Link>
                            ) : (
                              '-'
                            )
                          }
                        />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CREATED')} secondary={orderDetails?.order.created ? moment(orderDetails.order.created).format('DD-MM-YYYY HH:mm:ss') : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('MODIFIED')} secondary={orderDetails?.order.modified ? moment(orderDetails.order.modified).format('DD-MM-YYYY HH:mm:ss') : '-'} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('AMOUNT')} secondary={`${utils.currencyFormat(orderDetails?.order.amount)} ${orderDetails?.order.currency_iso_code}`} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('STATUS')} secondary={t(getOrderStatusLabel())} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('PAID')} secondary={orderDetails?.order.paid ? t('YES') : t('NO')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('HAS_REFUNDS')} secondary={orderDetails?.order.has_refunds ? t('YES') : t('NO')} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('PAYMENT')} />
              <CardContent>
                <List dense disablePadding>
                  <ListItem disableGutters>
                    <ListItemText primary={t('CREATED')} secondary={orderDetails?.payment?.created ? moment(orderDetails.payment.created).format('DD-MM-YYYY HH:mm:ss') : '-'} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('STATUS')} secondary={t(getPaymentStatusLabel())} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('EXTERNAL_PAYMENT_REFERENCE')} secondary={orderDetails?.payment?.external_payment_reference} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('EXTERNAL_BANK_REFERENCE')} secondary={orderDetails?.payment?.external_bank_reference} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('ISSUER_NAME')} secondary={orderDetails?.payment?.issuer_name} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('ISSUER_BANK_ACCOUNT')} secondary={orderDetails?.payment?.issuer_bank_account} />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <LoadingButton
                  onClick={() => setShowRefundModal(true)}
                  disabled={!canRefund()}
                  size='small'>
                  {t('ISSUE_REFUND_BUTTON_TITLE')}
                </LoadingButton>
                <Button
                  onClick={() => {
                    setShowResponseModal(true);
                  }}
                  disabled={orderDetails?.payment.response === null}>
                  {t('VIEW_RESPONSE_BUTTON_TITLE')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h5'>{t('REFUNDS')}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('AMOUNT')}</TableCell>
                          <TableCell>{t('STATUS')}</TableCell>
                          <TableCell>{t('DATE')}</TableCell>
                          <TableCell>{t('MODIFIED')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(orderDetails?.refunds ?? [])
                          .sort((a, b) => moment(b.created).diff(moment(a.created)))
                          .map(r => (
                            <TableRow key={r.id}>
                              <TableCell>
                                {`${utils.currencyFormat(r.amount)} ${orderDetails?.order.currency_iso_code}`}
                              </TableCell>
                              <TableCell>
                                <Chip label={r.status} color={['PROCESSED'].includes(r.status) ? 'primary' : 'default'} />
                              </TableCell>
                              <TableCell>
                                {moment(r.created).format('DD-MM-YYYY HH:mm:ss')}
                              </TableCell>
                              <TableCell>
                                {r.modified ? moment(r.modified).format('DD-MM-YYYY HH:mm:ss') : '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}