import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import moment from 'moment';
import DetailsIcon from '@material-ui/icons/Info';
import { LoadingButton } from '../../components/loadingButton';
import { AlertDialog } from '../../components/alertDialog';
import { useDirectBankingMiddlewareApi } from '../../context';
import { RefundListItem } from '../../services/types/directBanking/refundListItem.type';


const Row = (props: { refund: RefundListItem, selected: boolean, toggle: (item: RefundListItem) => void }) => {
  const { refund: refund, selected, toggle } = props;
  const history = useHistory();
  const { t } = useTranslation(['directBankingOrders']);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={selected}
            onChange={() => toggle(refund)}
            color='secondary'
          />
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/directbanking/orders/' + refund.order.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(refund.amount)} ${refund.order.currency_iso_code}`}
        </TableCell>
        <TableCell>
          <Chip label={t(refund.status)} color={'default'} />
        </TableCell>
        <TableCell>
          {moment(refund.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const DirectBankingNewBatch = () => {
  const { t, i18n } = useTranslation(['directBankingBatches', 'directBankingOrders']);
  const api = useDirectBankingMiddlewareApi();
  const history = useHistory();

  moment.locale(i18n.language);

  const [loading, setLoading] = React.useState(true);
  const [loadingRefunds, setLoadingRefunds] = React.useState(false);
  const [refunds, setRefunds] = React.useState<RefundListItem[]>([]);
  const [refundStates, setRefundStates] = React.useState<{ [key: number]: boolean }>({});

  const [companyName, setCompanyName] = React.useState('Revolupay EP S.L.U.');
  const [companyId, setCompanyId] = React.useState('B67233817000');
  const [bic, setBic] = React.useState('BKBKESMMXXX');
  const [iban, setIban] = React.useState('ES7301289446910100017361');

  const [errors, setErrors] = React.useState({
    companyName: false,
    companyId: false,
    bic: false,
    iban: false
  });

  const [submitError, setSubmitError] = React.useState(false);

  const newBatch = () => {
    const validationErrors = {
      companyName: false,
      companyId: false,
      bic: false,
      iban: false
    };
    let hasErrors = false;
    if (companyName.length === 0) {
      validationErrors.companyName = true;
      hasErrors = true;
    }
    if (companyId.length === 0) {
      validationErrors.companyId = true;
      hasErrors = true;
    }
    if (bic.length === 0) {
      validationErrors.bic = true;
      hasErrors = true;
    }
    if (iban.length === 0) {
      validationErrors.iban = true;
      hasErrors = true;
    }
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }

    const selectRefundsIds: number[] = [];
    for (const refundId in refundStates) {
      if (refundStates[refundId]) {
        selectRefundsIds.push(Number(refundId));
      }
    }

    utils.runAsync(async () => {
      setLoading(true);
      const batchId = await api.newBatch(
        companyName,
        companyId,
        bic,
        iban,
        selectRefundsIds
      );
      history.push('/directbanking/batches/' + batchId);
    }, (e) => {
      if (e) {
        setLoading(false);
        setSubmitError(true);
      }
    });
  }

  const resetErrors = () => {
    setErrors({
      companyName: false,
      companyId: false,
      bic: false,
      iban: false
    });
  }

  const loadRefunds = () => {
    utils.runAsync(async () => {
      setLoadingRefunds(true);
      const refunds: RefundListItem[] = await api.listDirectBankingRefunds({
        status: 'CREATED',
      });
      setRefunds(refunds);
      setRefundStates(refunds.reduce((p, c) => {
        p[c.id] = true;
        return p;
      }, {} as any))
    }, () => {
      setLoading(false)
      setLoadingRefunds(false);
    })
  }

  React.useEffect(() => {
    loadRefunds();
  }, []);

  return (
    <>
      <AlertDialog
        title={t('ERROR_CREATING_BATCH_TITLE')}
        message={t('ERROR_CREATING_BATCH_MESSAGE')}
        open={submitError}
        onClose={() => setSubmitError(false)}
      />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>{t('CREATE_NEW_BATCH')}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        <Typography gutterBottom>
                          {t('CREATE_NEW_BATCH_HELPTEXT')}
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2} style={{ marginTop: 4 }}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('COMPANY_NAME')}
                            value={companyName}
                            error={errors.companyName}
                            helperText={errors.companyName ? t('REQUIRED') : ''}
                            onChange={(e) => { setCompanyName(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('COMPANY_ID')}
                            value={companyId}
                            error={errors.companyId}
                            helperText={errors.companyId ? t('REQUIRED') : ''}
                            onChange={(e) => { setCompanyId(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('SOURCE_BIC')}
                            value={bic}
                            error={errors.bic}
                            helperText={errors.bic ? t('REQUIRED') : ''}
                            onChange={(e) => { setBic(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('SOURCE_IBAN')}
                            value={iban}
                            error={errors.iban}
                            helperText={errors.iban ? t('REQUIRED') : ''}
                            onChange={(e) => { setIban(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {!loadingRefunds &&
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Box paddingY={3} paddingX={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant='h6'>{t('TRANSFERS')}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {refunds.length > 0 &&
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>{t('directBankingOrders:AMOUNT')}</TableCell>
                              <TableCell>{t('directBankingOrders:STATUS')}</TableCell>
                              <TableCell>{t('directBankingOrders:CREATED')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {refunds.map(t =>
                              <Row
                                toggle={t => setRefundStates({ ...refundStates, [t.id]: !refundStates[t.id] })}
                                selected={refundStates[t.id]}
                                refund={t}
                                key={t.id}
                              />)}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    }
                    {refunds.length === 0 &&
                      <Grid item xs={12} style={{ padding: 20 }}>
                        <Grid container justify='center' item xs={12}>
                          <Typography variant='h5'>
                            {t('NO_PENDING_BATCH_TRANSACTIONS')}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                }
                {loadingRefunds &&
                  <Grid item xs={12} style={{ padding: 20 }}>
                    <Grid container justify='center' item xs={12}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                }

                <Grid item container justify='flex-end' xs={12}>
                  <Box marginTop={6} paddingY={1} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <LoadingButton loading={loading} disabled={loadingRefunds || loading} variant='contained' color='primary' onClick={() => { newBatch(); }}>
                          {t('CREATE')}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}