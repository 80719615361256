import React from 'react';
import { useRevoluexApi } from '../../context';
import {
  Box,
  CircularProgress,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Currency } from '../../services/types/revoluex/curerncy.type';
import { Deposit } from '../../services/types/revoluex/deposit.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

const Row = (props: { deposit: Deposit }) => {
  const { deposit } = props;
  const history = useHistory();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/revoluex/deposits/' + deposit.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {deposit.tx_hash}
        </TableCell>
        <TableCell>
          {deposit.sub_user_id}
        </TableCell>
        <TableCell>
          {`${deposit.amount} ${deposit.cryptocurrency_code.toUpperCase()}`}
        </TableCell>
        <TableCell>
          {moment(deposit.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {utils.getStringOrDefault(deposit.refunded_to,'-')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Deposits = () => {
  const { t } = useTranslation('revoluexDeposits');
  const api = useRevoluexApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [deposits, setDeposits] = React.useState<Deposit[]>([]);

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<Currency | null>(null);
  const [refunded, setRefunded] = React.useState(false);

  const [page, setPage] = React.useState(0);

  const limit = 15;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { deposits, count } = await api.listDeposits({
        limit,
        offset: page * limit,
        order: 'desc',
        cryptocurrency_code: selectedCurrency ? selectedCurrency.code : undefined,
        refunded
      });
      setDeposits(deposits);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      const chains = await api.listEnabledChains();
      const currencies = chains.map(c => c.currency).reduce((p, c) => {
        p[c.code] = c;
        return p;
      }, {} as any)
      setCurrencies(Object.values(currencies));
    });
  }, []);

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    setPage(0);
    loadData();
  }, [
    selectedCurrency,
    refunded
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCurrency(value);
              }}
              value={selectedCurrency}
              options={currencies}
              autoHighlight
              getOptionLabel={(option) => option.code.toUpperCase()}
              getOptionSelected={(option, value) => option.code === value.code}
              renderOption={(option) => option.code.toUpperCase()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_CURRENCY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid container justify='center' item xs={6} sm={2}>
            <FormControlLabel
              control={<Switch checked={refunded} onChange={() => setRefunded(!refunded)} />}
              label={t('REFUNDED')}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('TX_HASH')}</TableCell>
                      <TableCell>{t('SUB_USER_ID')}</TableCell>
                      <TableCell>{t('AMOUNT')}</TableCell>
                      <TableCell>{t('CREATED')}</TableCell>
                      <TableCell>{t('REFUNDED_TO')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deposits.map(t =>
                      <Row
                        deposit={t}
                        key={t.id}
                      />)}
                    {loading && deposits.length === 0 &&
                      <TableCell colSpan={6}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && deposits.length === 0 &&
                      <TableCell colSpan={6}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
