import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemText, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useRevolusendApi } from '../../context';
import { TransactionDetails } from '../../services/types/revolusend/transactionDetails.type';
import utils from '../../services/utils.service';
import moment from 'moment';
import { Profile } from '../../services/types/revolusend/profile.type';
import { LoadingButton } from '../../components/loadingButton';
import { PromotionType } from '../../services/types/revolusend/promotion.type';
import { Attachments } from '../../components/attachments';
import { PaylandsOrder } from '../../services/types/revolusend/paylandsOrder.type';
import { TransactionStatus } from '../../services/types/revolusend/transactionStatus.type';
import { TransactionTypeCode } from '../../services/types/revolusend/transactionType';
import { xml2json } from 'xml-js';
import { CecaCallback } from '../../services/types/revolusend/cecaCallback.type';
import { WorldpayOrder } from '../../services/types/revolusend/worldpayOrder.type';
import { DirectBankingOrder } from '../../services/types/revolusend/directBankingOrder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);

const ResponseDetailsModal = (props: { response?: string, open: boolean, onClose: () => void }) => {
  const { response, open, onClose } = props;
  const { t } = useTranslation('revolusendTransfers');
  let responseString = response ? response : '';

  if (response) {
    try {
      responseString = JSON.stringify(JSON.parse(response), undefined, 2);
    } catch (e) {
      console.log('Could not parse JSON response, trying as XML.');
      try {
        responseString = JSON.stringify(JSON.parse(xml2json(response)), undefined, 2);
      } catch (e) {
        console.log('Could not decode xml response.')
      }
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('RESPONSE_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <pre>{responseString}</pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

const ResendNotificationModal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setEmail('');
    setError(false);
    setLoading(false);
  }, [open]);

  const send = () => {
    if (!utils.isEmailValid(email)) {
      setError(true);
      return;
    }
    if (transactionId === undefined) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.sendRevolusendNotifications(transactionId, email);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('NOTIFICATION_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('NOTIFICATION_MODAL_DESCRIPTION')}
        </DialogContentText>
        <TextField
          label={t('EMAIL')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant='standard'
          fullWidth={true}
          error={error}
          helperText={error ? t('EMAIL_ERROR') : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={send}>
          {t('SEND')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}



const MarkTransactionAsFailedModal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [reason, setReason] = React.useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setReason('');
    setError(false);
    setLoading(false);
  }, [open]);

  const submit = () => {
    if (transactionId === undefined) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.markRevolusendTransactionAsFailed(transactionId, reason);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('MARK_TRANSACTION_AS_FAILED_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('MARK_TRANSACTION_AS_FAILED_MODAL_DESCRIPTION')}
        </DialogContentText>
        <TextField
          label={t('REASON')}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          variant='standard'
          fullWidth={true}
          error={error}
          helperText={error ? t('REQUIRED') : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={submit}>
          {t('SUBMIT')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

const RefundModal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const issueRefund = () => {
    if (!transactionId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.issueRevolusendRefund(transactionId);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }


  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('REFUND_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('REFUND_MODAL_DESCRIPTION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={issueRefund}>
          {t('ISSUE_REFUND_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

const CancelModal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const cancelTransaction = () => {
    if (!transactionId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      const response = await api.cancelRevolusendTransaction(transactionId);
      if (response.success) {
        onClose();
      } else {
        setError(response.error);
      }
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('CANCEL_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            {t('CANCEL_MODAL_DESCRIPTION')}
          </Typography>
          {error &&
            <Typography color={'error'} variant='subtitle1'>
              {error}
            </Typography>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={cancelTransaction}>
          {t('CANCEL_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}


const ApproveModal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const approveTransaction = () => {
    if (!transactionId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.approveTransactions([transactionId]);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('APPROVE_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            {t('APPROVE_MODAL_DESCRIPTION')}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={approveTransaction}>
          {t('APPROVE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}



const ClearingModal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const proxyOnClose = () => {
    setError(undefined);
    onClose();
  }

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const completeClearing = () => {
    setError(undefined);
    if (!transactionId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.completeClearing(transactionId);
      proxyOnClose();
    }, (e) => {
      setError('ERROR_IN_CLEARING');
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={proxyOnClose}>
      <DialogTitle>{t('CLEARING_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            {t('CLEARING_MODAL_DESCRIPTION')}
          </Typography>
          {error &&
            <Typography color={'error'} variant='subtitle1'>
              {t(error)}
            </Typography>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={proxyOnClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={completeClearing}>
          {t('CONFIRM')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

const CardPaymentDetailsModal = (props: {
  transactionDetails?: TransactionDetails | null,
  open: boolean;
  onClose: () => void
}) => {
  const { t } = useTranslation('revolusendTransfers');
  const { transactionDetails, onClose, open } = props;
  const sortedPaylandsOrders = transactionDetails?.paylands_orders?.sort((a, b) => b.id - a.id);
  let paylandsOrder: PaylandsOrder | undefined = undefined;
  if (!sortedPaylandsOrders || sortedPaylandsOrders?.length === 0) {
    paylandsOrder = undefined;
  } else {
    paylandsOrder = sortedPaylandsOrders[0];
  }
  const sortedWorldpayOrders = transactionDetails?.worldpay_orders?.sort((a, b) => b.id - a.id);
  let worldpayOrder: WorldpayOrder | undefined = undefined;
  if (!sortedWorldpayOrders || sortedWorldpayOrders?.length === 0) {
    worldpayOrder = undefined;
  } else {
    worldpayOrder = sortedWorldpayOrders[0];
  }
  let lastCecaAftCallback = transactionDetails?.last_ceca_aft_callback;
  let directBankingOrder = transactionDetails?.direct_banking_order;

  if (transactionDetails?.payment?.worldpay_order_id) {
    paylandsOrder = undefined;
    lastCecaAftCallback = undefined;
    directBankingOrder = undefined;
  }
  if (transactionDetails?.payment?.paylands_order_id) {
    worldpayOrder = undefined;
    lastCecaAftCallback = undefined;
    directBankingOrder = undefined;
  }
  if (transactionDetails?.payment?.ceca_transaction_id) {
    worldpayOrder = undefined;
    paylandsOrder = undefined;
    directBankingOrder = undefined;
  }
  if (transactionDetails?.payment?.direct_banking_order_id) {
    worldpayOrder = undefined;
    paylandsOrder = undefined;
    lastCecaAftCallback = undefined;
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('CARD_PAYMENT_DETAILS')}</DialogTitle>
      <DialogContent>
        {(!paylandsOrder && !worldpayOrder && !lastCecaAftCallback) && !directBankingOrder &&
          <DialogContentText>
            {t('NO_PAYMENT_DETAILS_AVAILABLE')}
          </DialogContentText>
        }
        {paylandsOrder &&
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText primary={t('PROVIDER')} secondary={`PAYLANDS`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('PAYLANDS_ORDER_ID')} secondary={`${paylandsOrder.external_id}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('PAYLANDS_STATUS')} secondary={paylandsOrder.status} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('PAYLANDS_ERROR')} secondary={utils.getStringOrDefault(paylandsOrder.error, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_COUNTRY')} secondary={utils.getStringOrDefault(paylandsOrder.card_country, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_BRAND')} secondary={utils.getStringOrDefault(paylandsOrder.card_brand, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_BANK')} secondary={utils.getStringOrDefault(paylandsOrder.card_bank, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_TYPE')} secondary={utils.getStringOrDefault(paylandsOrder.card_type, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_HOLDER')} secondary={utils.getStringOrDefault(paylandsOrder.card_holder, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_BIN')} secondary={utils.getStringOrDefault(paylandsOrder.card_bin, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_LAST4')} secondary={utils.getStringOrDefault(paylandsOrder.card_last4, '-')} />
            </ListItem>
          </List>
        }
        {lastCecaAftCallback &&
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText primary={t('PROVIDER')} secondary={`CECA (Visa Direct, AFT)`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CECA_TRANSACTION_ID')} secondary={`${lastCecaAftCallback.ceca_transaction_id}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('ERROR_CODE')} secondary={`${lastCecaAftCallback.error_code ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CECA_REFERENCE')} secondary={`${lastCecaAftCallback.reference ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('AUTH_CODE')} secondary={`${lastCecaAftCallback.auth ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_BIN')} secondary={`${lastCecaAftCallback.card_bin ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_LAST4')} secondary={`${lastCecaAftCallback.card_last_four_digits ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_TYPE')} secondary={`${lastCecaAftCallback.card_type ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_COUNTRY')} secondary={`${lastCecaAftCallback.card_country ?? '-'}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('VALID_SIGNATURE')} secondary={`${lastCecaAftCallback.valid_signature ? t('YES') : t('NO')}`} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CREATED')} secondary={`${lastCecaAftCallback.created ? moment(lastCecaAftCallback.created).format('DD-MM-YYYY HH:mm:ss') : '-'}`} />
            </ListItem>
          </List>
        }
        {worldpayOrder &&
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText primary={t('EXT_ORDER_ID')} secondary={worldpayOrder.ext_order_id} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('PAYMENT_STATUS')} secondary={worldpayOrder.status} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('PAYMENT_ERROR')} secondary={utils.getStringOrDefault(worldpayOrder.error, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_COUNTRY')} secondary={utils.getStringOrDefault(worldpayOrder.issuer_country, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_BANK')} secondary={utils.getStringOrDefault(worldpayOrder.issuer_name, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_TYPE')} secondary={utils.getStringOrDefault(worldpayOrder.card_type, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('CARD_BIN')} secondary={utils.getStringOrDefault(worldpayOrder.card_number, '-')} />
            </ListItem>
          </List>
        }
        {directBankingOrder &&
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText primary={t('EXT_ORDER_ID')} secondary={directBankingOrder.external_id} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('PAYMENT_STATUS')} secondary={directBankingOrder.status} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('ISSUER')} secondary={utils.getStringOrDefault(directBankingOrder.issuer_name, '-')} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={t('BANK_ACCOUNT')} secondary={utils.getStringOrDefault(directBankingOrder.issuer_bank_account, '-')} />
            </ListItem>
          </List>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}



const ConvertToIso20022Modal = (props: { transactionId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, transactionId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendTransfers');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const convertTransaction = () => {
    if (!transactionId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.convertToISO20022(transactionId);
      onClose();
    }, (e) => {
      if (error) {
        setError('ERROR')
      }
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('CONVERT_TO_ISO20022_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            {t('CONVERT_TO_ISO20022_MODAL_DESCRIPTION')}
          </Typography>
          {error &&
            <Typography color={'error'} variant='subtitle1'>
              {error}
            </Typography>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={convertTransaction}>
          {t('CONVERT_TO_ISO20022_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

const ProfileList = (props: { profile: Profile }) => {
  const { t } = useTranslation(['revolusendTransfers', 'resolusendDocumentTypes']);
  const { profile } = props;
  return (
    <Box>
      <Typography variant='subtitle2'>{t('IDENTIFICATION')}</Typography>
      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText primary={t('NAME')} secondary={`${profile.first_name} ${profile.last_name}`} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('BIRTH_DATE')} secondary={utils.getStringOrDefault(profile.birth_date, '-')} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('GENDER')} secondary={profile.gender ? t(profile.gender) : '-'} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('DOCUMENT_TYPE')} secondary={profile.id_card_type ? t(`revolusendDocumentTypes:${profile.id_card_type}`) : '-'} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('DOCUMENT_ISSUE_COUNTRY')} secondary={profile.id_card_country?.name ?? '-'} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('DOCUMENT_NUMBER')} secondary={profile.id_card_number ?? '-'} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('DOCUMENT_ISSUE_DATE')} secondary={profile.id_card_issue_date ? moment(profile.id_card_issue_date).format('DD-MM-YYYY') : '-'} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('DOCUMENT_EXPIRY_DATE')} secondary={profile.id_card_expiry_date ? moment(profile.id_card_expiry_date).format('DD-MM-YYYY') : '-'} />
        </ListItem>
      </List>
      <Typography variant='subtitle2'>{t('ADDRESS')}</Typography>
      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText primary={t('STREET')} secondary={utils.getStringOrDefault(profile.address, '-')} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('CITY')} secondary={utils.getStringOrDefault(profile.city, '-')} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('POSTAL_CODE')} secondary={utils.getStringOrDefault(profile.postal_code, '-')} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('REGION')} secondary={utils.getStringOrDefault(profile.region, '-')} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('COUNTRY')} secondary={utils.getStringOrDefault(profile.residence_country?.name, '-')} />
        </ListItem>
      </List>
      <Typography variant='subtitle2'>{t('CONTACT_DETAILS')}</Typography>
      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText primary={t('PHONE')} secondary={profile.phone ?? '-'} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={t('EMAIL')} secondary={profile.email ?? '-'} />
        </ListItem>
      </List>
    </Box>
  )
}

export const TransferDetails = (props: RouteComponentProps<{ id: string }>) => {
  const transactionId = props.match.params.id
  const { t } = useTranslation(['revolusendTransfers', 'revolusendDeliveryMethod']);
  const classes = useStyles();
  const api = useRevolusendApi();

  const [transactionDetails, setTransactionDetails] = React.useState<TransactionDetails | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [showNotificationModal, setShowNotificationModal] = React.useState(false);
  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [showMarkAsFailedModal, setShowMarkAsFailedModal] = React.useState(false);
  const [showCompleteClearingModal, setShowCompleteClearingModal] = React.useState(false);
  const [showCardPaymentDetails, setShowCardPaymentDetails] = React.useState(false);
  const [updatingSenderProfile, setUpdatingSenderProfile] = React.useState(false);
  const [showConvertToIso20022Modal, setShowConvertToIso20022Modal] = React.useState(false);


  const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const handleUpdateSender = () => {
    setUpdatingSenderProfile(true);
    utils.runAsync(async () => {
      await api.updateSenderProfile(transactionDetails!!.transaction.id);
    }, () => {
      setUpdatingSenderProfile(false);
      loadTransactionDetails();
    })
  }

  const loadTransactionDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setTransactionDetails(await api.getRevolusendTransactionDetails(transactionId));
    }, (e) => {
      setLoading(false);
      if (e) {
        console.log(e);
      }
    });
  }


  React.useEffect(() => {
    loadTransactionDetails();
  }, []);

  if (loading && transactionDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  const isClearingValid = () => {
    return Boolean(transactionDetails?.clearing?.is_valid);
  }

  const getTransactionStatusLabel = () => {
    if (transactionDetails === null) return '';
    return transactionDetails.transaction.status;
  }

  const canCancel = () => {
    if (transactionDetails === null) return false;
    if (!isClearingValid()) return false;
    if (['PENDING'].includes(transactionDetails.transaction.status)) {
      return true;
    } else if (transactionDetails.product.provider.code === 'BTS') {
      return ['PROCESSING', 'ACCEPTED', 'UNDER_REVIEW'].includes(transactionDetails.transaction.status);
    }
    return false;
  }

  const canApprove = () => {
    if (transactionDetails === null) return false;
    if (transactionDetails.payment === null) return false;
    if (transactionDetails.approval !== null) return false;
    if (!isClearingValid()) return false;
    if (
      [TransactionTypeCode.ISO20022, TransactionTypeCode.MANUAL_TRANSFER]
        .includes(transactionDetails.product.transaction_type.code)
    ) {
      return false;
    } else {
      return ['PENDING'].includes(transactionDetails.transaction.status);
    }
  }

  const refund = transactionDetails?.refunds?.find(t => t.type === 'MANUAL' || t.type === 'AUTOMATIC');

  const canRefund = () => {
    if (!isClearingValid()) {
      return false;
    }
    if (transactionDetails?.payment?.worldpay_order_id) {
      return false;
    }
    if ([
      'CANCELLED',
      'FAILED'
    ].includes(utils.getStringOrDefault(transactionDetails?.transaction.status))) {
      return !(refund !== undefined || !transactionDetails?.payment)
    }
    return false;
  }

  const canMarkAsFailed = () => {
    if (!transactionDetails) return false;
    if (!isClearingValid()) return false;
    if (
      ['ISSUED'].includes(transactionDetails.transaction.status) &&
      [TransactionTypeCode.MANUAL_TRANSFER, TransactionTypeCode.ISO20022].includes(transactionDetails.product.transaction_type.code)
    ) {
      return true;
    }
    return false;
  }

  const canSwitchToIso20022 = () => {
    if (transactionDetails?.transaction.status !== TransactionStatus.PENDING) {
      return false;
    }
    if (transactionDetails?.product.provider.code !== 'WORLDPAY') {
      return false;
    }
    if (transactionDetails?.product.currency.iso !== 'EUR') {
      return false;
    }
    if (!transactionDetails?.receiver.account_fields?.find(f => f.name.toLocaleUpperCase() === 'IBAN')) {
      return false;
    }
    return true;
  }


  const getPromotionLabel = () => {
    if (transactionDetails?.promotion) {
      if (transactionDetails.promotion.type === PromotionType.VOUCHER) {
        return `${utils.currencyFormat(transactionDetails.transaction.promotion_amount)} ${transactionDetails.source_currency.iso} - ${t(transactionDetails.promotion.type)} (${transactionDetails.promotion.voucher})`
      } else {
        return `${utils.currencyFormat(transactionDetails.transaction.promotion_amount)} ${transactionDetails.source_currency.iso} - ${t(transactionDetails.promotion.type)}`
      }
    } else {
      return '-'
    }
  }

  const getPaymentMethod = () => {
    if (transactionDetails?.payment) {
      if (transactionDetails.payment.revolupay_order_id) {
        return t('WALLET')
      } else if (transactionDetails.payment.paylands_order_id) {
        return t('PAYLANDS');
      } else if (transactionDetails.payment.ceca_transaction_id) {
        return t('AFT');
      } else if (transactionDetails.payment.direct_banking_order_id) {
        return t('DIRECT_BANKING');
      }
      return '-';
    }
    return '-';
  }

  return (
    <>
      <ConvertToIso20022Modal open={showConvertToIso20022Modal} transactionId={transactionDetails?.transaction.id} onClose={() => {
        setShowConvertToIso20022Modal(false);
        loadTransactionDetails();
      }} />
      <ResponseDetailsModal open={showResponseModal} response={transactionDetails?.transaction.response} onClose={() => { setShowResponseModal(false) }} />
      <ResendNotificationModal transactionId={transactionDetails?.transaction.id} open={showNotificationModal} onClose={() => setShowNotificationModal(false)} />
      <CardPaymentDetailsModal transactionDetails={transactionDetails} open={showCardPaymentDetails} onClose={() => setShowCardPaymentDetails(false)} />
      <RefundModal
        transactionId={transactionDetails?.transaction.id}
        open={showRefundModal}
        onClose={() => {
          setShowRefundModal(false);
          loadTransactionDetails();
          
        }} />
      <CancelModal
        transactionId={transactionDetails?.transaction.id}
        open={showCancelModal}
        onClose={() => {
          setShowCancelModal(false);
          setTimeout(loadTransactionDetails, 1500);
        }} />
      <ApproveModal
        transactionId={transactionDetails?.transaction.id}
        open={showApproveModal}
        onClose={() => {
          setShowApproveModal(false);
          loadTransactionDetails();
        }} />
      <MarkTransactionAsFailedModal
        transactionId={transactionDetails?.transaction.id}
        open={showMarkAsFailedModal}
        onClose={() => {
          setShowMarkAsFailedModal(false);
          setTimeout(loadTransactionDetails, 1500);
        }} />
      <ClearingModal
        transactionId={transactionDetails?.transaction.id}
        open={showCompleteClearingModal}
        onClose={() => {
          setShowCompleteClearingModal(false);
          loadTransactionDetails();
        }} />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('DELIVERY')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ID')} secondary={transactionDetails?.transaction.id} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TENANT_EXTERNAL_ID')} secondary={transactionDetails?.transaction.tenant_external_id ?? '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ORIGIN')} secondary={transactionDetails?.transaction.origin} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('STATUS')} secondary={t(getTransactionStatusLabel())} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('PROVIDER')} secondary={transactionDetails?.product.provider.name} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('REFERENCE')} secondary={transactionDetails?.transaction.reference} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('EXTERNAL_REFERENCE')} secondary={utils.getStringOrDefault(transactionDetails?.transaction.external_reference, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TYPE')} secondary={t(`revolusendDeliveryMethod:${transactionDetails?.product.transaction_type.code}`)} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('COUNTRY')} secondary={transactionDetails?.product.country.name} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('AGENT')} secondary={transactionDetails?.product.agent.name} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('LOCATION')} secondary={utils.getStringOrDefault(transactionDetails?.location?.name, '-')} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CLEARING_STATUS')} secondary={isClearingValid() ? t('VALID') : t('INVALID')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('AMOUNT')} secondary={`${utils.currencyFormat(transactionDetails?.transaction.receive_amount)} ${transactionDetails?.product.currency.iso}`} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('FAILURE_REASON')} secondary={utils.getStringOrDefault(transactionDetails?.transaction.failure_reason, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CONCEPT')} secondary={transactionDetails?.transaction.concept ?? '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ACCOUNT')} secondary={transactionDetails?.receiver.account_fields?.map(a => a.value).join(' - ')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ACCOUNT_TYPE')} secondary={transactionDetails?.receiver.account_type ? t(`revolusendAccountType:${transactionDetails?.receiver.account_type?.name}`) : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CREATED')} secondary={transactionDetails?.transaction.created ? moment(transactionDetails.transaction.created).format('DD-MM-YYYY HH:mm:ss') : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('MODIFIED')} secondary={transactionDetails?.transaction.modified ? moment(transactionDetails.transaction.modified).format('DD-MM-YYYY HH:mm:ss') : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('APPROVAL')} secondary={transactionDetails?.approval ? `${transactionDetails.approval.username} - ${moment(transactionDetails.approval.created).format('DD-MM-YYYY HH:mm:ss')}` : '-'} />
                      </ListItem>
                    </List>
                  </Grid>

                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button variant='contained' color='primary' aria-controls='actions-menu' aria-haspopup='true' onClick={handleActionsClick}>
                  {t('ACTIONS')}
                </Button>
                <Menu id='actions-menu' anchorEl={actionsAnchorEl} keepMounted open={Boolean(actionsAnchorEl)} onClose={handleActionsClose}>
                  <MenuItem
                    onClick={() => {
                      setShowCompleteClearingModal(true);
                      handleActionsClose();
                    }}
                    disabled={isClearingValid()}>
                    {t('COMPLETE_CLEARING')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowConvertToIso20022Modal(true);
                      handleActionsClose();
                    }}
                    disabled={!canSwitchToIso20022()}>
                    {t('SWITCH_TO_ISO20022')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowApproveModal(true);
                      handleActionsClose();
                    }}
                    disabled={!canApprove()}>
                    {t('APPROVE')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowCancelModal(true);
                      handleActionsClose();
                    }}
                    disabled={!canCancel()}>
                    {t('CANCEL')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowMarkAsFailedModal(true);
                      handleActionsClose();
                    }}
                    disabled={!canMarkAsFailed()}>
                    {t('MARK_AS_FAILED')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowNotificationModal(true);
                      handleActionsClose();
                    }}
                    disabled={transactionDetails?.payment === null}>
                    {t('RESEND_NOTIFICATION_BUTTON_TITLE')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowResponseModal(true);
                      handleActionsClose();
                    }}
                    disabled={transactionDetails?.transaction.response === null}>
                    {t('VIEW_RESPONSE_BUTTON_TITLE')}
                  </MenuItem>
                </Menu>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('PAYMENT')} />
              <CardContent>
                <List dense disablePadding>
                  <ListItem disableGutters>
                    <ListItemText primary={t('REVOLUPAY_USER_ID')} secondary={transactionDetails?.transaction.user_id} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('TENANT')} secondary={transactionDetails?.tenant.name} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('PAID')} secondary={transactionDetails?.payment ? t('YES') : t('NO')} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('PAYMENT_METHOD')} secondary={getPaymentMethod()} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('PAYMENT_AMOUNT')} secondary={`${utils.currencyFormat(transactionDetails?.transaction.amount)} ${transactionDetails?.source_currency.iso}`} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('SEND_AMOUNT')} secondary={`${utils.currencyFormat(transactionDetails?.transaction.send_amount)} ${transactionDetails?.source_currency.iso}`} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('EXCHANGE_RATE')} secondary={`${transactionDetails?.transaction.exchange_rate}`} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('DEBIT_AMOUNT')} secondary={`${utils.currencyFormat(transactionDetails?.transaction.debit_amount)} ${transactionDetails?.debit_currency.iso}`} />
                  </ListItem>
                  
                  <ListItem disableGutters>
                    <ListItemText primary={t('FEE')} secondary={`${utils.currencyFormat(transactionDetails?.transaction.fee)} ${transactionDetails?.source_currency.iso}`} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('REVENUE')} secondary={`${utils.currencyFormat(transactionDetails?.transaction.revenue)} ${transactionDetails?.source_currency.iso}`} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('PROMOTION')} secondary={getPromotionLabel()} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('REFUNDED_AT')} secondary={refund?.created ? moment(refund.created).format('DD-MM-YYYY HH:mm:ss') : '-'} />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <LoadingButton
                  onClick={() => setShowRefundModal(true)}
                  disabled={!canRefund()}
                  size='small'>
                  {t('ISSUE_REFUND_BUTTON_TITLE')}
                </LoadingButton>
                <Button
                  onClick={() => setShowCardPaymentDetails(true)}
                  disabled={
                    transactionDetails === null || (
                      transactionDetails?.paylands_orders?.length === 0 &&
                      transactionDetails?.worldpay_orders?.length === 0 &&
                      !transactionDetails?.last_ceca_aft_callback &&
                      !transactionDetails?.payment?.direct_banking_order_id
                    )}
                  size='small'>
                  {t('CARD_PAYMENT_DETAILS')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h5'>{t('APPROVAL_ATTEMPTS')}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('DATE')}</TableCell>
                          <TableCell>{t('ID')}</TableCell>
                          <TableCell>{t('RESULT')}</TableCell>
                          <TableCell>{t('USERNAME')}</TableCell>
                          <TableCell>{t('REJECTION_REASONS')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(transactionDetails?.approval_attempts ?? [])
                          .sort((a, b) => moment(b.created).diff(moment(a.created)))
                          .map(t => (
                            <TableRow key={t.id}>
                              <TableCell>
                                {moment(t.created).format('DD-MM-YYYY HH:mm:ss')}
                              </TableCell>
                              <TableCell>
                                {t.id}
                              </TableCell>
                              <TableCell>
                                <Chip label={t.result} color={['APPROVED', 'APPROVAL-DISABLED'].includes(t.result) ? 'primary' : 'default'} />
                              </TableCell>
                              <TableCell>
                                {t.username}
                              </TableCell>
                              <TableCell>
                                {t.rejection_reasons?.join(', ') ? t.rejection_reasons?.join(', ') : '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={t('CLEARING')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DEPOSIT_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.deposit_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('USER_TO_TENANT_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.user_to_tenant_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TENANT_TO_MASTER_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.tenant_to_master_send_amount_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('FEE_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.fee_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('MASTER_WITHDRAW_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.master_withdraw_transaction_id, '-')} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('MASTER_DEPOSIT_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.master_deposit_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('FEE_REFUND_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.fee_refund_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('MASTER_TO_TENANT_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.master_to_tenant_send_amount_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TENANT_TO_USER_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.tenant_to_user_transaction_id, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('WITHDRAW_TRANSACTION_ID')} secondary={utils.getStringOrDefault(transactionDetails?.clearing?.withdraw_transaction_id, '-')} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Attachments entity='transaction' id={transactionId} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('ISSUER')} />
              <CardContent>
                {transactionDetails?.sender &&
                  <ProfileList profile={transactionDetails.sender} />
                }
              </CardContent>
              <CardActions className={classes.cardActions}>
                <LoadingButton
                  disabled={transactionDetails?.transaction.status !== TransactionStatus.PENDING}
                  loading={updatingSenderProfile}
                  variant='contained'
                  color='primary'
                  aria-controls='actions-menu'
                  aria-haspopup='true'
                  onClick={handleUpdateSender}>
                  {t('UPDATE_SENDER')}
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('BENEFICIARY')} />
              <CardContent>
                {transactionDetails?.receiver &&
                  <ProfileList profile={transactionDetails.receiver} />
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}