export enum KycStatus {
  KYC_PENDING = 'KYC_PENDING',
  KYC_SUBMITTED = 'KYC_SUBMITTED',
  KYC_OK = 'KYC_OK',
  KYC_KO = 'KYC_KO'
}

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CLOSED = 'CLOSED'
}

export type B2BUser = {
  id: number;
  kyc_status: KycStatus;
  account_status: AccountStatus;
  created: string;
  updated?: string;
}