import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useRevolusendApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

import { LoadingButton } from '../../components/loadingButton';
import { Country } from '../../services/types/revolusend/country.type';
import { PromotionListItem } from '../../services/types/revolusend/promotionListItem.type';
import { PromotionType } from '../../services/types/revolusend/promotion.type';
import moment, { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { Tenant } from '../../services/types/revolusend/tenant.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  })
);

type FormErros = {
  type: boolean;
  tenant: boolean;
  start: boolean;
  end: boolean;
  voucher: boolean;
  amount: boolean;
  country: boolean;
  usages: boolean;
};

const UpsertModal = (
  props: {
    open: boolean,
    onClose: () => void,
    promotion?: PromotionListItem
  }) => {
  const api = useRevolusendApi();
  const { open, onClose, promotion } = props;
  const { t } = useTranslation('revolusendPromos');

  const [countries, setCountries] = React.useState<Country[]>([]);
  const [type, setType] = React.useState<PromotionType | null>(null);
  const [start, setStart] = React.useState<Moment | null>(null);
  const [end, setEnd] = React.useState<Moment | null>(null);
  const [voucher, setVoucher] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [country, setCountry] = React.useState<Country | null>();
  const [usages, setUsages] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [selectedTenant, selectTenant] = React.useState<Tenant | null>(null);

  const [loading, setLoading] = React.useState(false);

  const [errors, setErros] = React.useState<FormErros>({
    type: false,
    tenant: false,
    start: false,
    end: false,
    country: false,
    usages: false,
    voucher: false,
    amount: false
  });

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.listRevolusendCountries());
    });
    utils.runAsync(async () => {
      setTenants(await api.listTenants());
    });
  }, []);

  React.useEffect(() => {
    setStart(promotion?.promotion.start ? moment(promotion.promotion.start) : null);
    setEnd(promotion?.promotion.end ? moment(promotion.promotion.end) : null);
    let promoType = promotion?.promotion.type ? promotion.promotion.type : PromotionType.REGULAR;
    setType(promoType);
    if (promotion?.country) {
      setCountry(promotion.country);
    }
    selectTenant(promotion?.tenant ? promotion.tenant : null);
    setAmount(promotion?.promotion.amount ? promotion.promotion.amount.toString(10) : '');
    setUsages(promotion?.promotion.usages ? promotion.promotion.usages.toString(10) : '');
    setVoucher((promoType === PromotionType.VOUCHER || promoType === PromotionType.WELCOME_VOUCHER) ? utils.getStringOrDefault(promotion?.promotion.voucher, utils.generateVoucher()) : '');
    setDisabled(promotion?.promotion.disabled ? promotion.promotion.disabled : false);
    setErros({
      tenant: false,
      type: false,
      start: false,
      end: false,
      country: false,
      usages: false,
      voucher: false,
      amount: false
    });
  }, [props]);

  React.useEffect(() => {
    if (promotion) return;
    if (type === PromotionType.VOUCHER || type === PromotionType.WELCOME_VOUCHER) {
      if(voucher.length === 0){
        setVoucher(utils.generateVoucher())
      }
    } else {
      setVoucher('');
    }
  }, [type]);

  const validate = () => {
    const formErrors = {
      type: false,
      tenant: false,
      start: false,
      end: false,
      country: false,
      usages: false,
      voucher: false,
      amount: false
    }
    if (selectedTenant === null) {
      formErrors.tenant = true;
    }
    if (type === null) {
      formErrors.type = true;
    }
    if (start === null) {
      formErrors.start = true;
    }
    if (end === null) {
      formErrors.end = true;
    }
    if (usages.length === 0 || isNaN(Number.parseFloat(usages))) {
      if(type === PromotionType.VOUCHER || type === PromotionType.REGULAR){
        formErrors.usages = true;
      }
    }
    if (amount.length === 0 || isNaN(Number.parseFloat(amount))) {
      formErrors.amount = true;
    }
    if ((type === PromotionType.VOUCHER || type === PromotionType.WELCOME_VOUCHER) && voucher.length === 0) {
      formErrors.voucher = true;
    }
    setErros(formErrors);
    return Object.values(formErrors).find(v => v === true) ? false : true
  }

  const upsert = () => {
    if (validate()) {
      utils.runAsync(async () => {
        setLoading(true);
        await api.upsertRevolusendPromotion(
          promotion?.promotion.id,
          {
            type: type!,
            tenant_id: selectedTenant!.id,
            start: start!.toISOString(),
            end: end!.toISOString(),
            voucher: (type === PromotionType.VOUCHER || type === PromotionType.WELCOME_VOUCHER) ? voucher : null,
            amount: amount.length > 0 ? parseFloat(amount) : 0,
            usages: (type === PromotionType.WELCOME || PromotionType.WELCOME_VOUCHER) ? (1) : (usages.length > 0 ? parseFloat(usages) : 0),
            country_id: country?.id ? country.id : null,
            disabled: disabled,
          }
        );
        onClose();
      }, (e) => {
        setLoading(false);
      });
    }
  }



  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{promotion ? t('EDIT_MODAL_TITLE') : t('ADD_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('MODAL_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectTenant(value);
              }}
              value={selectedTenant}
              disabled={promotion !== undefined}
              options={tenants}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('TENANT')}
                  error={errors.tenant}
                  helperText={errors.tenant ? t('REQUIRED') : ''}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setType(value);
              }}
              value={type}
              disabled={promotion !== undefined}
              options={[PromotionType.VOUCHER, PromotionType.WELCOME_VOUCHER, PromotionType.WELCOME, PromotionType.REGULAR]}
              autoHighlight
              getOptionLabel={(option) => t(option)}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('TYPE')}
                  variant='standard'
                  fullWidth={true}
                  error={errors.type}
                  helperText={errors.type ? t('REQUIRED') : ''}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          {(type === PromotionType.VOUCHER || type === PromotionType.WELCOME_VOUCHER) &&
            <Grid item xs={12}>
              <TextField
                disabled={promotion !== undefined}
                label={t('VOUCHER')}
                error={errors.voucher}
                value={voucher}
                helperText={errors.voucher ? t('REQUIRED') : ''}
                onChange={(e) => setVoucher(e.target.value)}
                type='text'
                fullWidth
              />
            </Grid>
          }
          <Grid item xs={12}>
            <DatePicker
              clearable
              okLabel={t('OK')}
              cancelLabel={t('CANCEL')}
              clearLabel={t('CLEAR')}
              fullWidth={true}
              label={t('START')}
              format='DD/MM/YYYY'
              maxDate={end ? end : undefined}
              value={start}
              error={errors.start}
              helperText={errors.start ? t('REQUIRED') : ''}
              onChange={(value) => {
                setStart(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              clearable
              okLabel={t('OK')}
              cancelLabel={t('CANCEL')}
              clearLabel={t('CLEAR')}
              fullWidth={true}
              label={t('END')}
              format='DD/MM/YYYY'
              disablePast
              minDate={start ? start : undefined}
              value={end}
              error={errors.end}
              helperText={errors.end ? t('REQUIRED') : ''}
              onChange={(value) => {
                setEnd(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('AMOUNT')}
              error={errors.amount}
              value={amount}
              helperText={errors.amount ? t('REQUIRED') : ''}
              onChange={(e) => setAmount(e.target.value)}
              type='number'
              fullWidth
            />
          </Grid>
          {(type === PromotionType.VOUCHER || type === PromotionType.REGULAR) &&
            <Grid item xs={12}>
              <TextField
                label={t('USAGES')}
                error={errors.usages}
                value={usages}
                helperText={errors.amount ? t('REQUIRED') : ''}
                onChange={(e) => setUsages(e.target.value)}
                type='number'
                fullWidth
              />
            </Grid>
          }
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setCountry(value);
              }}
              value={country}
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('COUNTRY')}
                  variant='standard'
                  fullWidth={true}
                  helperText={t('COUNTRY_HELP')}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={disabled}
                  onChange={(e) => setDisabled(e.target.checked)}
                  name={'DISABLED'}
                />
              }
              label={t('DISABLED')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton disabled={loading} loading={loading} onClick={upsert} color='primary'>
          {promotion ? t('UPDATE') : t('ADD')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}


export const Promos = () => {
  const { t, i18n } = useTranslation(['revolusendPromos']);
  moment.locale(i18n.language)
  const api = useRevolusendApi();
  const [loading, setLoading] = React.useState(true);
  const [unfilteredPromos, setUnfilteredPromos] = React.useState<PromotionListItem[]>([]);
  const [promos, setPromos] = React.useState<PromotionListItem[]>([]);

  const [showUpsertModal, setShowUpsertModal] = React.useState(false);
  const [promoForUpsert, setPromoForUpsert] = React.useState<PromotionListItem | undefined>(undefined);

  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [showDisabled, setShowDisabled] = React.useState(false);
  const [selectedTenant, selectTenant] = React.useState<Tenant | null>(null);

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const prmos = await api.listRevolusendPromotions();
      setUnfilteredPromos(prmos);
      filterPromos(prmos);
    }, () => {
      setLoading(false);
    });
  }

  const filterPromos = (promosForFiltering: PromotionListItem[]) => {
    let filteredPromos = [...promosForFiltering];
    if (!showDisabled) {
      filteredPromos = filteredPromos.filter(p => {
        if (p.promotion.disabled) {
          return false;
        }
        if (!moment().isBetween(moment(p.promotion.start), moment(p.promotion.end))) {
          return false;
        }
        return true;
      })
    }
    if (selectedTenant) {
      filteredPromos = filteredPromos.filter(p => {
        return p.tenant.id === selectedTenant.id
      })
    }
    setPromos(filteredPromos.sort((a,b)=>b.promotion.id - a.promotion.id));
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      setTenants(await api.listTenants());
    });
  }, []);

  React.useEffect(loadData, []);

  React.useEffect(() => {
    filterPromos(unfilteredPromos);
  }, [selectedTenant, showDisabled]);

  return (
    <>
      <UpsertModal
        open={showUpsertModal}
        promotion={promoForUpsert}
        onClose={() => {
          loadData();
          setShowUpsertModal(false);
          setPromoForUpsert(undefined);
        }}
      />
      <Grid container spacing={2} alignItems='flex-end'>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            onChange={(event, value) => {
              selectTenant(value);
            }}
            value={selectedTenant}
            options={tenants}
            autoHighlight
            disabled={loading}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('SEARCH_TENANT')}
                variant='standard'
                fullWidth={true}
                InputProps={{
                  ...params.InputProps,
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )} />

        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={<Switch checked={showDisabled} onChange={() => setShowDisabled(!showDisabled)} />}
            label={t('SHOW_DISABLED')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container item justify='flex-end'>
            <Button variant='outlined' color='secondary' startIcon={<AddIcon />} onClick={() => setShowUpsertModal(true)}>
              {t('ADD')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{t('TENANT')}</TableCell>
                        <TableCell>{t('TYPE')}</TableCell>
                        <TableCell>{t('START')}</TableCell>
                        <TableCell>{t('END')}</TableCell>
                        <TableCell>{t('COUNTRY')}</TableCell>
                        <TableCell>{t('AMOUNT')}</TableCell>
                        <TableCell>{t('USAGES')}</TableCell>
                        <TableCell>{t('ENABLED')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {promos.map(c =>
                        <TableRow key={c.promotion.id}>
                          <TableCell>
                            <IconButton
                              size='small'
                              onClick={() => {
                                setPromoForUpsert(c);
                                setShowUpsertModal(true);
                              }}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{c.tenant.name}</TableCell>
                          <TableCell>{t(c.promotion.type)}</TableCell>
                          <TableCell>{moment(c.promotion.start).format('lll')}</TableCell>
                          <TableCell>{moment(c.promotion.end).format('lll')}</TableCell>
                          <TableCell>{c.country ? c.country.name : '-'}</TableCell>
                          <TableCell>{utils.currencyFormat(c.promotion.amount)}</TableCell>
                          <TableCell>{c.promotion.usages}</TableCell>
                          <TableCell><Chip label={c.promotion.disabled ? t('NO') : t('YES')} color={c.promotion.disabled ? 'default' : 'primary'} /></TableCell>
                        </TableRow>
                      )}
                      {loading && promos.length === 0 &&
                        <TableCell colSpan={9}>
                          <Box padding={2}>
                            <Grid container justify='center' item xs={12}>
                              <CircularProgress />
                            </Grid>
                          </Box>
                        </TableCell>
                      }
                      {!loading && promos.length === 0 &&
                        <TableCell colSpan={9}>
                          <Box padding={2}>
                            <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                            <img className={classes.img} src={notFoundImg} />
                          </Box>
                        </TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </>
  )
}
