import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import { useRevoluexApi } from '../../context';
import utils from '../../services/utils.service';
import moment from 'moment';
import { LoadingButton } from '../../components/loadingButton';
import { Deposit } from '../../services/types/revoluex/deposit.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);


const CreateTopupModal = (props: { depositId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, depositId } = props;
  const api = useRevoluexApi();
  const { t } = useTranslation('revoluexDeposits');
  const [phone, setPhone] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setPhone('');
    setError('');
    setLoading(false);
  }, [open]);

  const createTopup = () => {
    if (phone.length === 0) {
      return;
    }
    if (depositId === undefined) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.createManualTopup(depositId, phone);
      onClose();
    }, (e) => {
      if (e) {
        setError(t('REVOLUPAY_USER_CANNOT_BE_FOUND'));
      }
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('CREATE_TOPUP_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('CREATE_TOPUP_MODAL_DESCRIPTION')}
        </DialogContentText>
        <TextField
          label={t('PHONE')}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          variant='standard'
          fullWidth={true}
          helperText={t('PHONE_HELP')}
        />
        {error.length > 0 &&
          <Typography color='error' variant={'subtitle1'}>
            {error}
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={createTopup}>
          {t('CREATE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}
const NotifyRefundModal = (props: { depositId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, depositId } = props;
  const api = useRevoluexApi();
  const { t } = useTranslation('revoluexDeposits');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setEmail('');
    setError(false);
    setLoading(false);
  }, [open]);

  const send = () => {
    if (!utils.isEmailValid(email)) {
      setError(true);
      return;
    }
    if (depositId === undefined) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.notifyDepositRefund(depositId, email);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('REFUND_NOTIFICATION_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('REFUND_NOTIFICATION_MODAL_DESCRIPTION')}
        </DialogContentText>
        <TextField
          label={t('EMAIL')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant='standard'
          fullWidth={true}
          error={error}
          helperText={error ? t('EMAIL_ERROR') : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={send}>
          {t('SEND')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}


export const DepositDetails = (props: RouteComponentProps<{ id: string }>) => {
  const depositId = parseInt(props.match.params.id);
  const { t } = useTranslation('revoluexDeposits');
  const classes = useStyles();
  const api = useRevoluexApi();
  const history = useHistory();

  const [depositDetails, setDepositDetails] = React.useState<Deposit | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [showRefundNotificationModal, setShowRefundNotificationModal] = React.useState(false);
  const [showCreateTopupModal, setShowCreateTopupModal] = React.useState(false);

  const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const loadDepositDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setDepositDetails(await api.getDepositDetails(depositId));
      setLoading(false);
    }, (e) => {
      if (e) {
        history.push('/revoluex/deposits');
      }
    });
  }

  React.useEffect(() => {
    loadDepositDetails();
  }, []);

  React.useEffect(() => {
    loadDepositDetails();
  }, [showCreateTopupModal, showRefundNotificationModal]);

  if (loading && depositDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  if (depositDetails?.topup_id) {
    return (
      <Redirect to={'/revoluex/topups/' + depositDetails.topup_id} />
    );
  }

  return (
    <>
      <NotifyRefundModal depositId={depositId} open={showRefundNotificationModal} onClose={() => setShowRefundNotificationModal(false)} />
      <CreateTopupModal depositId={depositId} open={showCreateTopupModal} onClose={() => setShowCreateTopupModal(false)} />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={t('DEPOSIT')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ID')} secondary={depositDetails?.id} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('SUB_USER_ID')} secondary={depositDetails?.sub_user_id} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CRYPTOCURRENCY')} secondary={depositDetails?.cryptocurrency_code.toUpperCase()} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CRYPTOCHAIN')} secondary={depositDetails?.chain_code.toUpperCase()} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DEPOSIT_AMOUNT')} secondary={`${depositDetails?.amount} ${depositDetails?.cryptocurrency_code.toUpperCase()}`} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CREATED')} secondary={moment(depositDetails!.created).format('lll')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TXHASH')} secondary={depositDetails?.tx_hash} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('WALLET')} secondary={depositDetails?.wallet} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TAG')} secondary={utils.getStringOrDefault(depositDetails?.tag, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('REFUNDED_TO')} secondary={utils.getStringOrDefault(depositDetails?.refunded_to, '-')} />
                      </ListItem>
                    </List>
                  </Grid>

                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button variant='contained' color='primary' aria-controls='actions-menu' aria-haspopup='true' onClick={handleActionsClick}>
                  {t('ACTIONS')}
                </Button>
                <Menu id='actions-menu' anchorEl={actionsAnchorEl} keepMounted open={Boolean(actionsAnchorEl)} onClose={handleActionsClose}>
                  <MenuItem
                    onClick={() => {
                      setShowRefundNotificationModal(true);
                      handleActionsClose();
                    }}
                    disabled={depositDetails?.refunded_to !== null}>
                    {t('NOTIFY_REFUND_BUTTON_TITLE')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowCreateTopupModal(true);
                      handleActionsClose();
                    }}
                    disabled={depositDetails?.refunded_to !== null}>
                    {t('CREATE_TOPUP_BUTTON_TITLE')}
                  </MenuItem>
                </Menu>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}