import React from 'react';
import { useRevoluexApi } from '../../context';
import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TopupListItem } from '../../services/types/revoluex/topupListItem.type';
import { TopupStatus } from '../../services/types/revoluex/topup.type';
import { Currency } from '../../services/types/revoluex/curerncy.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);



const Row = (props: { topup: TopupListItem }) => {
  const { topup } = props;
  const history = useHistory();
  const { t } = useTranslation('revoluexTopups');

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/revoluex/topups/' + topup.topup.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {`${topup.revolupay_user.revolupay_user_name} (${topup.revolupay_user.revolupay_user_email})`}
        </TableCell>
        <TableCell>
          {`${topup.topup.crypto_amount} ${topup.topup.crypto_currency_code.toUpperCase()}`}
        </TableCell>
        <TableCell>
          {topup.topup.transfer_amount ? `${utils.currencyFormat(topup.topup.transfer_amount)} €UR` : '-'}
        </TableCell>
        <TableCell>
          {topup.topup.fee ? `${utils.currencyFormat(topup.topup.fee)} €UR` : '-'}
        </TableCell>
        <TableCell>
          <Chip label={t(topup.topup.status)} color={[TopupStatus.PENDING, TopupStatus.TIME_OUT].includes(topup.topup.status) ? 'default' : 'primary'} />
        </TableCell>
        <TableCell>
          {moment(topup.topup.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {topup.topup.updated? moment(topup.topup.updated).format('DD-MM-YYYY HH:mm:ss'): '-'}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Topups = () => {
  const { t } = useTranslation('revoluexTopups');
  const api = useRevoluexApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [topups, setTopups] = React.useState<TopupListItem[]>([]);
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<Currency | null>(null);
  const [selectedTopupStatus, selectTopupStatus] = React.useState<TopupStatus | null>(null);
  const [revolupayUserEmail, setRevolupayUserEmail] = React.useState('');

  const [page, setPage] = React.useState(0);


  const limit = 15;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { topups, count } = await api.listTopups({
        limit,
        offset: page * limit,
        order: 'desc',
        email: revolupayUserEmail.length > 0 ? revolupayUserEmail : undefined,
        cryptocurrency_code: selectedCurrency ? selectedCurrency.code : undefined,
        status: selectedTopupStatus ? selectedTopupStatus : undefined,
      });
      setTopups(topups);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      const chains = await api.listEnabledChains();
      const currencies = chains.map(c => c.currency).reduce((p, c) => {
        p[c.code] = c;
        return p;
      }, {} as any)
      setCurrencies(Object.values(currencies));
    });
  }, []);

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    setPage(0);
    loadData();
  }, [
    selectedCurrency,
    selectedTopupStatus,
    revolupayUserEmail
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCurrency(value);
              }}
              value={selectedCurrency}
              options={currencies}
              autoHighlight
              getOptionLabel={(option) => option.code.toUpperCase()}
              getOptionSelected={(option, value) => option.code === value.code}
              renderOption={(option) => option.code.toUpperCase()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_CURRENCY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectTopupStatus(value);
              }}
              value={selectedTopupStatus}
              options={[
                TopupStatus.TIME_OUT,
                TopupStatus.CONFIRMED,
                TopupStatus.PENDING,
                TopupStatus.APPROVED,
                TopupStatus.TRADED,
                TopupStatus.TRANSFERED
              ]}
              autoHighlight
              getOptionLabel={(option) => t(option)}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_STATUS')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('SEARCH_USER')}
              value={revolupayUserEmail}
              onChange={(e) => setRevolupayUserEmail(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('USER')}</TableCell>
                      <TableCell>{t('CRYPTO_AMOUNT')}</TableCell>
                      <TableCell>{t('TRANSFER_AMOUNT')}</TableCell>
                      <TableCell>{t('FEE')}</TableCell>
                      <TableCell>{t('STATUS')}</TableCell>
                      <TableCell>{t('CREATED')}</TableCell>
                      <TableCell>{t('UPDATED')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topups.map(t =>
                      <Row
                        topup={t}
                        key={t.topup.id}
                      />)}
                    {loading && topups.length === 0 &&
                      <TableCell colSpan={8}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && topups.length === 0 &&
                      <TableCell colSpan={8}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
