import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Typography
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import { LoadingButton } from '../../components/loadingButton';
import { useVisaInstantApi } from '../../context';
import { User } from '../../services/types/visaInstant/user.type';
import { Country } from '../../services/types/visaInstant/country.type';
import { Card as CardType } from '../../services/types/visaInstant/card.type';
import { DetailedCard } from '../../services/types/visaInstant/detailedCard.type';
import { CardStatus } from '../../services/types/visaInstant/cardStatus.enum';
import { CardBalance } from '../../services/types/visaInstant/cardBalance.type';
import { Topups } from '../topups/topups';
import { Transactions } from '../transactions/transactions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardActions: {
            justifyContent: 'flex-end'
        },
    })
);


const CancelModal = (props: { cardId?: number, open: boolean, onClose: () => void }) => {
    const { open, onClose, cardId } = props;
    const api = useVisaInstantApi();
    const { t } = useTranslation('visaInstantCards');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(false);
    }, [open]);

    const cancelCard = () => {
        if (!cardId) {
            return;
        }
        utils.runAsync(async () => {
            setLoading(true);
            await api.cancelCard(cardId.toString());
            onClose();
        }, (e) => {
            setLoading(false);
        })
    }

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>{t('CANCEL_MODAL_TITLE')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography gutterBottom>
                        {t('CANCEL_MODAL_DESCRIPTION')}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('CANCEL')}
                </Button>
                <LoadingButton color='primary' loading={loading} disabled={loading} onClick={cancelCard}>
                    {t('CANCEL_BUTTON_TITLE')}
                </LoadingButton>
            </DialogActions>
        </Dialog >
    )
}


const User = (props: { countries: Country[]; user: User }) => {
    const { t } = useTranslation('visaInstantCards');
    const { user, countries } = props;
    const country = countries.find(c => c.id === user.residence_country_id);
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Typography variant='subtitle2'>{t('IDENTIFICATION')}</Typography>
                <List dense disablePadding>
                    <ListItem disableGutters>
                        <ListItemText primary={t('USER_ID')} secondary={user.id} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('NAME')} secondary={`${user.first_name} ${user.last_name}`} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('PHONE')} secondary={user.phone} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('EMAIL')} secondary={user.email} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant='subtitle2' style={{ marginTop: 10 }}>{t('ADDRESS')}</Typography>
                <List dense disablePadding>
                    <ListItem disableGutters>
                        <ListItemText primary={t('CITY')} secondary={utils.getStringOrDefault(user.city)} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('REGION')} secondary={utils.getStringOrDefault(user.region)} />
                    </ListItem>
                    {country &&
                        <ListItem disableGutters>
                            <ListItemText primary={t('COUNTRY')} secondary={country.name} />
                        </ListItem>
                    }
                </List>
            </Grid>
        </Grid>
    )
}


const VisaCard = (props: {
    card: CardType;
    balance?: CardBalance;
}) => {
    const { t } = useTranslation('visaInstantCards');
    const { card, balance } = props;
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <List dense disablePadding>
                    <ListItem disableGutters>
                        <ListItemText primary={t('CARD_ID')} secondary={card.id} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('CARD_MP')} secondary={card.mp} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('CARD_LAST_4')} secondary={card.last4} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('EXPIRY_DATE')} secondary={card.expiry ? card.expiry : '-'} />
                    </ListItem>

                </List>
            </Grid>
            <Grid item xs={12} md={6}>
                <List dense disablePadding>
                    <ListItem disableGutters>
                        <ListItemText primary={t('USER_ID')} secondary={card.user_id?card.user_id:'-'} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={t('STATUS')} secondary={t(card.status)} />
                    </ListItem>
                    {balance &&
                        <ListItem disableGutters>
                            <ListItemText primary={t('BALANCE')} secondary={`${utils.currencyFormat(balance.accountable)} EUR`} />
                        </ListItem>
                    }
                </List>
            </Grid>
        </Grid>
    )
}


export const CardDetails = (props: RouteComponentProps<{ id: string }>) => {
    const cardId = props.match.params.id
    const { t } = useTranslation('visaInstantCards');
    const classes = useStyles();
    const api = useVisaInstantApi();
    const history = useHistory();

    const [cardDetails, setCardDetails] = React.useState<DetailedCard | null>(null);
    const [countries, setCountries] = React.useState<Country[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [showCancelModal, setShowCancelModal] = React.useState(false);

    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionsAnchorEl(event.currentTarget);
    };

    const handleActionsClose = () => {
        setActionsAnchorEl(null);
    };


    const loadCardDetails = () => {
        utils.runAsync(async () => {
            setLoading(true);
            setCountries(await api.getCountries());
            setCardDetails(await api.getCard(cardId));
        }, (e) => {
            setLoading(false);
            if (e) {
                console.log(e);
            }
        });
    }


    React.useEffect(() => {
        loadCardDetails();
    }, []);

    if (loading && cardDetails === null) {
        return (
            <Grid container justify='center'>
                <Box marginTop={4}>
                    <CircularProgress />
                </Box>
            </Grid>
        )
    }


    const canCancel = () => {
        return cardDetails?.card.status !== CardStatus.CANCELLED;
    }

    return (
        <>
            <CancelModal
                open={showCancelModal}
                cardId={cardDetails!.card.id}
                onClose={() => {
                    setShowCancelModal(false);
                    loadCardDetails();
                }} />
            <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={t('CARD')} />
                            <CardContent>
                                <VisaCard card={cardDetails!.card} balance={cardDetails?.balance} />
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button variant='contained' color='primary' aria-controls='actions-menu' aria-haspopup='true' onClick={handleActionsClick}>
                                    {t('ACTIONS')}
                                </Button>
                                <Menu id='actions-menu' anchorEl={actionsAnchorEl} keepMounted open={Boolean(actionsAnchorEl)} onClose={handleActionsClose}>
                                    <MenuItem
                                        onClick={() => {
                                            setShowCancelModal(true);
                                            handleActionsClose();
                                        }}
                                        disabled={!canCancel()}>
                                        {t('CANCEL_CARD')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            history.push('/visa-instant/card-requests/' + cardDetails?.card_request?.id)
                                        }}
                                        disabled={!cardDetails?.card_request}>
                                        {t('VIEW_ASSOCIATED_CARD_ORDER')}
                                    </MenuItem>
                                </Menu>
                            </CardActions>
                        </Card>
                    </Grid>
                    {cardDetails?.user &&
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader title={t('CARD_OWNER')} />
                                <CardContent>
                                    <User countries={countries} user={cardDetails!.user} />
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Topups embedded={true} cardId={parseInt(cardId,10)}/>
                </Grid>
                <Grid item xs={12}>
                    <Transactions embedded={true} cardId={parseInt(cardId,10)}/>
                </Grid>
            </Grid>
        </>
    )
}