export enum PromotionType {
  WELCOME = 'WELCOME', REGULAR = 'REGULAR', VOUCHER = 'VOUCHER', WELCOME_VOUCHER = 'WELCOME_VOUCHER'
}

export type Promotion = {
  id: number;
  type: PromotionType;
  start: string;
  end: string;
  voucher?: string;
  amount: number;
  country_id?: number;
  usages: number;
  disabled: boolean;
}