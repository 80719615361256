import { Config } from '../config';
import { Auth } from './auth.service';
import { Agent } from './types/revolusend/agent.type';
import { Attachment } from './types/revolusend/attachment.type';
import { BatchDetails } from './types/revolusend/batchDetails.type';
import { BatchListItem } from './types/revolusend/batchListItem.type';
import { Country } from './types/revolusend/country.type';
import { Currency } from './types/revolusend/currency.type';
import { CurrencyRestrictionListItem } from './types/revolusend/currencyRestriction.type';
import { DecoratedFee } from './types/revolusend/decoratedFee';
import { DecoratedRevolupayUser } from './types/revolusend/decoratedRevolupayUser.type';
import { ProductListItem } from './types/revolusend/product.type';
import { ProductTenant } from './types/revolusend/productTenant.type';
import { PromotionType } from './types/revolusend/promotion.type';
import { PromotionListItem } from './types/revolusend/promotionListItem.type';
import { Provider } from './types/revolusend/provider.type';
import { KycStatus } from './types/revolusend/revolupayUser.type';
import { Reward } from './types/revolusend/reward.type';
import { Tenant } from './types/revolusend/tenant.type';
import { TransactionDetails } from './types/revolusend/transactionDetails.type';
import { TransactionListItem } from './types/revolusend/transactionListItem.type';
import { TransactionType } from './types/revolusend/transactionType';
import { UserProfile } from './types/revolusend/userProfile.type';
import { KycStatus as B2BKycStatus } from './types/revolusend/b2bUser.type';
import { DecoratedB2BUser } from './types/revolusend/decoratedB2BUser.type';

export class RevolusendApi {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async listRevolusendProductFees(
    transactionTypeId: number,
    receiveCurrenciesId: number,
    countryId: number,
    tenantsIds: number[],
    sourceCurrenciesId?: number,
  ): Promise<DecoratedFee[]> {

    let resultFees: any[] = []

    for (const tenantId of tenantsIds) {
      const params = new URLSearchParams();
      params.append('country_id', countryId.toString(10));
      params.append('tenant_id', tenantId.toString(10));
      params.append('receive_currency_id', receiveCurrenciesId.toString(10));
      params.append('transaction_type_id', transactionTypeId.toString(10));
      if (sourceCurrenciesId) {
        params.append('source_currency_id', sourceCurrenciesId.toString(10));
      }

      const response = await fetch(
        this.config.REVOLUSEND_API_URL + '/admin/fees?' + params.toString(),
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + await this.auth.getAccessToken()
          }
        }
      );
      if (!response.ok) {
        throw new Error('Could not fetch revolusend product fees')
      };
      resultFees = resultFees.concat((await response.json()).fees)
    }
    return resultFees
  }

  async listRevolusendCurrencies(): Promise<Currency[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/currencies',
      {
        method: 'GET',
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch revolusend currencies')
    };
    return (await response.json()).currencies;
  }

  async listRevolusendCurrenciesSource(): Promise<Currency[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/currencies/source',
      {
        method: 'GET',
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch revolusend currencies')
    };
    return (await response.json()).currencies;
  }


  async listRevolusendProviders(): Promise<Provider[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/providers',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch revolusend providers')
    };
    return (await response.json()).providers;
  }

  async listRevolusendAgents(): Promise<Agent[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/agents',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch revolusend agents')
    };
    return (await response.json()).agents;
  }

  async listRevolusendTransactionTypes(): Promise<TransactionType[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/transaction-types',
      {
        method: 'GET'
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch revolusend transaction-types')
    };
    return (await response.json()).transaction_types;
  }

  async listRevolusendCountries(): Promise<Country[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/countries',
      {
        method: 'GET'
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch revolusend countries')
    };
    return (await response.json()).countries;
  }

  async listRevolusendProducts(
    filters: {
      providerId?: number;
      currencyId?: number;
      countryId?: number;
      agentId?: number;
      tenantId?: number;
      transactionTypeCode?: string;
      available?: boolean;
      disabled?: boolean;
    },
    limit: number,
    offset: number
  ): Promise<{ count: number; products: ProductListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    if (filters.providerId) {
      params.append('provider_id', filters.providerId.toString(10));
    }
    if (filters.currencyId) {
      params.append('currency_id', filters.currencyId.toString(10));
    }
    if (filters.countryId) {
      params.append('country_id', filters.countryId.toString(10));
    }
    if (filters.agentId) {
      params.append('agent_id', filters.agentId.toString(10));
    }
    if (filters.transactionTypeCode) {
      params.append('transaction_type_code', filters.transactionTypeCode);
    }
    if (filters.tenantId !== undefined) {
      params.append('tenant_id', filters.tenantId.toString());
    }
    if (filters.available !== undefined) {
      params.append('available', filters.available.toString());
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/products?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend products');
    }
    return response.json();
  }

  async revolusendUpsertFee(
    transactionTypeId: number,
    countryId: number,
    receiverCurrencyId: number,
    sourceCurrencyId: number,
    tenantId: number,
    id: number | undefined,
    fee: {
      cost_percentage?: number | null;
      cost_fixed_amount?: number | null;
      min_amount?: number | null;
      max_amount?: number | null;
    }
  ) {

    let payload: any = {
      transaction_type_id: transactionTypeId,
      country_id: countryId,
      receive_currency_id: receiverCurrencyId,
      source_currency_id: sourceCurrencyId,
      tenant_id: tenantId,
      ...fee
    }
    const method = id ? 'PUT' : 'POST';
    if (id) {
      payload.id = id
    }
    const response = await fetch(
      `${this.config.REVOLUSEND_API_URL}/admin/fees`+(id?'/'+id:''),
      {
        method,
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(payload),
      }
    )
    if (!response.ok) {
      throw new Error('Could not upsert product fee');
    }
  }

  async revolusendDeleteFee(id: number) {
    const response = await fetch(
      `${this.config.REVOLUSEND_API_URL}/admin/fees/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not delete product fee');
    }
  }

  async revolusendUpdateProduct(product: {
    id: number;
    tenant_id: number;
    revenue_fixed_amount?: number | null;
    revenue_percentage?: number | null;
    disabled?: boolean;
  }) {
    const response = await fetch(
      `${this.config.REVOLUSEND_API_URL}/admin/products`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(product),
      }
    )
    if (!response.ok) {
      throw new Error('Could not upsert tenant product');
    }
  }

  async listRevolusendTransactions(
    filters: {
      providerId?: number;
      batchId?: number;
      currencyId?: number;
      countryId?: number;
      transactionTypeId?: number;
      status?: string;
      receiverEmail?: string;
      tenantExternalId?: string;
      senderEmail?: string;
      reference?: string;
      externalReference?: string;
      tenantId?: number,
      excludeBatchTransactions?: boolean,
      paid: boolean,
      onlyPendingRefund: boolean,
    },
    limit: number,
    offset: number,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<{ count: number; transactions: TransactionListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    if (filters.providerId) {
      params.append('provider_id', filters.providerId.toString(10));
    }
    if (filters.batchId) {
      params.append('batch_id', filters.batchId.toString(10));
    }
    if (filters.currencyId) {
      params.append('currency_id', filters.currencyId.toString(10));
    }
    if (filters.countryId) {
      params.append('country_id', filters.countryId.toString(10));
    }
    if (filters.transactionTypeId) {
      params.append('transaction_type_id', filters.transactionTypeId.toString(10));
    }
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.receiverEmail) {
      params.append('email_receiver', filters.receiverEmail);
    }
    if (filters.senderEmail) {
      params.append('email_sender', filters.senderEmail);
    }
    if (filters.reference) {
      params.append('reference', filters.reference);
    }
    if (filters.externalReference) {
      params.append('external_reference', filters.externalReference);
    }
    if (filters.paid === true) {
      params.append('paid', filters.paid.toString());
    }
    if (filters.onlyPendingRefund === true) {
      params.append('only_pending_refund', filters.onlyPendingRefund.toString());
    }
    if (filters.tenantId) {
      params.append('tenant_id', filters.tenantId.toString(10));
    }
    if (filters.excludeBatchTransactions) {
      params.append('exclude_batch_transactions', 'true');
    }

    if (filters.tenantExternalId) {
      params.append('tenant_external_id', filters.tenantExternalId);
    }

    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend transactions');
    }
    return response.json();
  }

  async getRevolusendTransactionDetails(id: string | number): Promise<TransactionDetails> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend transaction details');
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async sendRevolusendNotifications(transactionId: number, email: string) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + transactionId + '/notify',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ email })
      }
    )
    if (!response.ok) {
      throw new Error('Could not send revolusend transaction notifications');
    }
  }


  async cancelRevolusendTransaction(transactionId: number, reason?: string): Promise<{ success: boolean, error?: string }> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + transactionId + '/cancel',
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ reason })
      }
    )
    if (!response.ok) {
      throw new Error('Could not cancel revolusend transaction');
    }
    else return response.json();
  }

  async markRevolusendTransactionAsFailed(transactionId: number, reason?: string) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + transactionId + '/fail',
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ failure_reason: reason })
      }
    )
    if (!response.ok) {
      throw new Error('Could not mark revolusend transaction as failed');
    }
  }

  async issueRevolusendRefund(transactionId: number) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + transactionId + '/refund',
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not issue revolusend transaction refund');
    }
  }

  async listRevolusendRewards(): Promise<Reward[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/revoluvip/rewards',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not list revolusend rewards');
    }
    return (await response.json()).rewards;
  }

  async updateRevolusendReward(reward: number, id: number) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/revoluvip/rewards',
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify({ id, reward })
      }
    )
    if (!response.ok) {
      throw new Error('Could not update revolusend rewards');
    }
  }

  async listRevolusendCurrencyRestrictions(): Promise<CurrencyRestrictionListItem[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/currencies/restrictions',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not list revolusend currency restrictions');
    }
    return (await response.json()).currency_restrictions;
  }

  async upsertRevolusendCurrencyRestriction(id: number | undefined, restriction: {
    min_amount?: number | null;
    max_amount?: number | null;
    multiple_of?: number | null;
    currency_id?: number;
    transaction_type_id?: number
  }) {
    let method = 'POST';
    const payload: any = {
      ...restriction
    }
    if (id) {
      method = 'PUT';
      payload.id = id;
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/currencies/restrictions',
      {
        method,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(payload)
      }
    )
    if (!response.ok) {
      throw new Error('Could not upsert revolusend promotion');
    }
  }

  async listRevolusendPromotions(): Promise<PromotionListItem[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/promotions',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not list revolusend promotions');
    }
    return (await response.json()).promotions;
  }

  async upsertRevolusendPromotion(id: number | undefined, promotion: {
    tenant_id: number;
    start: string;
    end: string;
    type: PromotionType;
    amount: number;
    usages: number;
    disabled: boolean;
    voucher: string | null;
    country_id: number | null;
  }) {
    let method = 'POST';
    const payload: any = {
      ...promotion
    }
    if (id) {
      method = 'PUT';
      payload.id = id;
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/promotions',
      {
        method,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(payload)
      }
    )
    if (!response.ok) {
      throw new Error('Could not upsert revolusend promotion');
    }
  }

  async upsertRevolusendCurrency(id: number | undefined, currency: {
    promo_rate: number | null;
  }) {
    let method = 'PUT';
    const payload: any = { ...currency }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/currencies/' + id,
      {
        method,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(payload)
      }
    )
    if (!response.ok) {
      throw new Error('Could not upsert revolusend currency');
    }
  }

  async deleteRevolusendCurrencyRestriction(id: number) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/currencies/restrictions/' + id,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not delete revolusend currency restriction');
    }
  }

  async listRevolusendBatches(
    filters: {
      providerId?: number;
      processed?: boolean;
      start?: Date;
      end?: Date;
    },
    limit: number,
    offset: number,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<{ count: number; batches: BatchListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    if (filters.providerId) {
      params.append('provider_id', filters.providerId.toString(10));
    }
    if (filters.processed) {
      params.append('processed', filters.processed.toString());
    }
    if (filters.start) {
      params.append('start', filters.start.toISOString());
    }
    if (filters.end) {
      params.append('end', filters.end.toISOString());
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/batches?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend batches');
    }
    return response.json();
  }

  async getRevolusendBatchDetails(id: string | number): Promise<BatchDetails> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/batches/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend batch details');
    }
    return response.json();
  }

  async getRevolusendUploadUrlForBatchAttament(id: string | number, name: string, type: string): Promise<string> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/batches/' + id + '/attachments',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name, content_type: type
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend batch attachment upload url');
    }
    return (await response.json()).url;
  }

  async getRevolusendBatchAttachments(id: string | number): Promise<Attachment[]> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/batches/' + id + '/attachments',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend batch attachments');
    }
    return (await response.json()).attachments;
  }

  async getRevolusendUploadUrlForTransactionAttament(id: string | number, name: string, type: string): Promise<string> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + id + '/attachments',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name, content_type: type
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend batch attachment upload url');
    }
    return (await response.json()).url;
  }

  async getRevolusendTransactionAttachments(id: string | number): Promise<Attachment[]> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + id + '/attachments',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend batch attachments');
    }
    return (await response.json()).attachments;
  }

  async proccessRevolusendBatchTransactions(batchId: number, transactionIds: number[], status: 'SUCCESS' | 'ERROR') {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/batches/' + batchId + '/transactions',
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transaction_ids: transactionIds.map(tId => ({ transaction_id: tId, status }))
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not process batch transactions');
    }
  }

  async listTenants(): Promise<Tenant[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/tenants', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + await this.auth.getAccessToken(),
      }
    }
    );
    if (!response.ok) {
      throw new Error('Could not list tenants');
    }
    return (await response.json()).tenants;
  }

  async getProductTenants(productId: number): Promise<ProductTenant[]> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + `/admin/products/${productId}/tenants`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + await this.auth.getAccessToken(),
      }
    }
    );
    if (!response.ok) {
      throw new Error('Could not list product tenants');
    }
    return (await response.json()).product_tenants;
  }

  async updateTenant(payload: { id: number; name: string; exchange_markup: number }) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + `/admin/tenants`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        'content-type': 'application/json'
      },
      body: JSON.stringify(payload)
    }
    );
    if (!response.ok) {
      throw new Error('Could not update tenant');
    }
  }

  async getTransactionReport(start: string, end: string): Promise<{ file_url: string }> {
    const queryParams = new URLSearchParams();
    queryParams.append('start', start);
    queryParams.append('end', end);
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + `/admin/reports/transactions?` + queryParams.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not build report');
    }
    return response.json();
  }

  async getUserReport(start: string, end: string): Promise<{ file_url: string }> {
    const queryParams = new URLSearchParams();
    queryParams.append('start', start);
    queryParams.append('end', end);
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + `/admin/reports/users?` + queryParams.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
      }
    )
    if (!response.ok) {
      throw new Error('Could not build report');
    }
    return response.json();
  }

  async listRevolupayUsers(
    filters: {
      status?: KycStatus;
      tenantId?: number,
    },
    limit: number,
    offset: number,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<{ count: number; users: DecoratedRevolupayUser[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.tenantId) {
      params.append('tenant_id', filters.tenantId.toString(10));
    }

    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/users?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend users');
    }
    return response.json();
  }

  async listB2bUsers(
    filters: {
      kyc_status?: B2BKycStatus;
      tenantId?: number,
    },
    limit: number,
    offset: number,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<{ count: number; users: DecoratedB2BUser[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    if (filters.kyc_status) {
      params.append('status', filters.kyc_status);
    }
    if (filters.tenantId) {
      params.append('tenant_id', filters.tenantId.toString(10));
    }

    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/b2busers?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch b2b users');
    }
    return response.json();
  }

  async getUser(
    id: number
  ): Promise<UserProfile> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/users/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get revolusend user');
    }
    return response.json();
  }

  async completeClearing(
    transactionId: number
  ) {
    const response = await fetch(
      `${this.config.REVOLUSEND_API_URL}/admin/transactions/${transactionId}/clearing/complete`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not complete clearing');
    }
  }

  async newBatch(
    companyName: string,
    companyId: string,
    bic: string,
    account: string,
    transactionTypeCode: string,
    transactionIds: number[]
  ): Promise<number> {
    const response = await fetch(
      `${this.config.REVOLUSEND_API_URL}/admin/batches`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transaction_type_code: transactionTypeCode,
          transaction_ids: transactionIds,
          company_name: companyName,
          company_id: companyId,
          bic,
          account
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not create batch');
    } else {
      return (await response.json()).id;
    }
  }

  async approveTransactions(transactionIds: number[]) {
    const response = await fetch(
      `${this.config.REVOLUSEND_API_URL}/admin/transactions/approve`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ transaction_ids: transactionIds })
      }
    )
    if (!response.ok) {
      throw new Error('Could not approve transactions');
    }
  }


  async updateSenderProfile(transactionId: number) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + transactionId + '/update-sender-profile',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not update sender profile.');
    }
  }

  async convertToISO20022(transactionId: number) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/transactions/' + transactionId + '/iso20022',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not convert transaction to ISO20022.');
    }
  }

  async setSettings(settings: { submitAfterAutoApproval: boolean }) {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/settings',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings)
      }
    )
    if (!response.ok) {
      throw new Error('Could not update settings');
    }
  }
  async getSettings(): Promise<{ submitAfterAutoApproval: boolean }> {
    const response = await fetch(
      this.config.REVOLUSEND_API_URL + '/admin/settings',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get settings.');
    }
    return response.json();
  }

}