export class Config {

  readonly REVOLUCHARGE_API_URL?: string;
  readonly REVOLUTILITY_API_URL?: string;
  readonly REVOLUSEND_API_URL?: string;
  readonly DIRECTBANKING_MIDDLEWARE_API_URL?: string;
  readonly REVOLUEX_API_URL?: string;
  readonly VISA_INSTANT_API_URL?: string;
  readonly JWT_KEY_ENDPOINT?: string;
  readonly OAUTH_LOGIN?: string;
  readonly OAUTH_LOGOUT?: string;
  constructor() {
    this.REVOLUCHARGE_API_URL = process.env.REVOLUCHARGE_API_URL ? process.env.REVOLUCHARGE_API_URL : '';
    this.REVOLUTILITY_API_URL = process.env.REVOLUTILITY_API_URL ? process.env.REVOLUTILITY_API_URL : '';
    this.REVOLUSEND_API_URL = process.env.REVOLUSEND_API_URL ? process.env.REVOLUSEND_API_URL : '';
    this.DIRECTBANKING_MIDDLEWARE_API_URL = process.env.DIRECTBANKING_MIDDLEWARE_API_URL ? process.env.DIRECTBANKING_MIDDLEWARE_API_URL : '';
    this.REVOLUEX_API_URL = process.env.REVOLUEX_API_URL ? process.env.REVOLUEX_API_URL : '';
    this.VISA_INSTANT_API_URL = process.env.VISA_INSTANT_API_URL?process.env.VISA_INSTANT_API_URL : '';
    this.JWT_KEY_ENDPOINT = process.env.JWT_KEY_ENDPOINT ? process.env.JWT_KEY_ENDPOINT : '';
    this.OAUTH_LOGIN = process.env.OAUTH_LOGIN ? process.env.OAUTH_LOGIN : '';
    this.OAUTH_LOGOUT = process.env.OAUTH_LOGOUT ? process.env.OAUTH_LOGOUT : '';
  }
}