import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import moment from 'moment';
import { LoadingButton } from '../../components/loadingButton';
import { useVisaInstantApi } from '../../context';
import { DetailedCardRequest } from '../../services/types/visaInstant/detailedCardRequest.type';
import { User } from '../../services/types/visaInstant/user.type';
import { Country } from '../../services/types/visaInstant/country.type';
import { CardRequest } from '../../services/types/visaInstant/cardRequest.type';
import { RevolupayOrder } from '../../services/types/visaInstant/revolupayOrder.type';
import { Refund } from '../../services/types/visaInstant/refund.type';
import { Currency } from '../../services/types/visaInstant/currency.type';
import { DeliveryMethod } from '../../services/types/visaInstant/deliveryMethod.type';
import { CardRequestStatus } from '../../services/types/visaInstant/cardRequestStatus.enum';
import { Card as CardType } from '../../services/types/visaInstant/card.type';
import DetailsIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);

const CardRow = (props: { card: CardType; }) => {
  const { card } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation('visaInstantCards');
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/visa-instant/cards/' + card.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {card.id}
        </TableCell>
        <TableCell>
          {card.last4}
        </TableCell>
        <TableCell>
          {card.mp}
        </TableCell>
        <TableCell>
          {card.expiry ? card.expiry : '-'}
        </TableCell>
        <TableCell>
          <Chip label={t(card.status)} color={['ACTIVE'].includes(card.status) ? 'primary' : 'default'} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RefundModal = (props: { revolupayOrderId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, revolupayOrderId } = props;
  const api = useVisaInstantApi();
  const { t } = useTranslation('visaInstantCardRequests');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const issueRefund = () => {
    if (!revolupayOrderId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.refundRevolupayOrder(revolupayOrderId.toString(10));
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }


  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('REFUND_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('REFUND_MODAL_DESCRIPTION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={issueRefund}>
          {t('ISSUE_REFUND_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

const CancelModal = (props: { cardRequestId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, cardRequestId } = props;
  const api = useVisaInstantApi();
  const { t } = useTranslation('visaInstantCardRequests');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const cancelTransaction = () => {
    if (!cardRequestId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.cancelCardRequest(cardRequestId.toString());
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('CANCEL_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            {t('CANCEL_MODAL_DESCRIPTION')}
          </Typography>
          {error &&
            <Typography color={'error'} variant='subtitle1'>
              {error}
            </Typography>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={cancelTransaction}>
          {t('CANCEL_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

const AssignCardModal = (props: { cardRequestId?: number, numCards: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, cardRequestId, numCards } = props;
  const api = useVisaInstantApi();
  const { t } = useTranslation('visaInstantCardRequests');
  const [loading, setLoading] = React.useState(false);
  const [cardMp, setCardMp] = React.useState('');
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const assignCard = () => {
    if (!cardRequestId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.assignCards(cardRequestId.toString(), cardMp.split(',').map(c => c.trim()));
      onClose();
    }, (e) => {
      if (e) {
        setError('CARD_DOES_NOT_EXIST');
      }
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('ASSIGN_CARD_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom>
              {t('ASSIGN_CARD_MODAL_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('CARD_MP')}
              value={cardMp}
              disabled={loading}
              helperText={t('CARD_MP_HELPTEXT', { cardCount: numCards })}
              onChange={(e) => setCardMp(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
          {error &&
            <Grid item xs={12}>
              <Typography color={'error'} variant='subtitle1'>
                {t(error)}
              </Typography>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={assignCard}>
          {t('ASSIGN_CARD_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}


const ShipCardModal = (props: { cardRequestId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, cardRequestId } = props;
  const api = useVisaInstantApi();
  const { t } = useTranslation('visaInstantCardRequests');
  const [loading, setLoading] = React.useState(false);
  const [trackingDetails, setTrackingDetails] = React.useState('');

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const shipCard = () => {
    if (!cardRequestId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.shipCard(cardRequestId.toString(), trackingDetails.length > 0 ? trackingDetails : null);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('SHIP_CARD_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom>
              {t('SHIP_CARD_MODAL_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('TRACKING_DETAILS')}
              value={trackingDetails}
              disabled={loading}
              onChange={(e) => setTrackingDetails(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={shipCard}>
          {t('SHIP_CARD')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}


const User = (props: { countries: Country[]; user: User }) => {
  const { t } = useTranslation('visaInstantCardRequests');
  const { user, countries } = props;
  const country = countries.find(c => c.id === user.residence_country_id);
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant='subtitle2'>{t('IDENTIFICATION')}</Typography>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary={t('USER_ID')} secondary={user.id} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('NAME')} secondary={`${user.first_name} ${user.last_name}`} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('PHONE')} secondary={user.phone} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('EMAIL')} secondary={user.email} />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='subtitle2' style={{ marginTop: 10 }}>{t('ADDRESS')}</Typography>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary={t('STREET')} secondary={utils.getStringOrDefault(user.address)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('CITY')} secondary={utils.getStringOrDefault(user.city)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('POSTAL_CODE')} secondary={utils.getStringOrDefault(user.postal_code)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('REGION')} secondary={utils.getStringOrDefault(user.region)} />
          </ListItem>
          {country &&
            <ListItem disableGutters>
              <ListItemText primary={t('COUNTRY')} secondary={country.name} />
            </ListItem>
          }
        </List>
      </Grid>
    </Grid>
  )
}


const Payment = (props: {
  cardRequest: CardRequest;
  currency: Currency;
  revolupayOrder: RevolupayOrder;
  refunds: Refund[]
}) => {
  const { t } = useTranslation('visaInstantCardRequests');
  const { cardRequest, currency, revolupayOrder, refunds } = props;
  let refund;
  if (refunds.length > 0) {
    refund = refunds[0];
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary={t('PAID')} secondary={revolupayOrder.completed ? t('YES') : t('NO')} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('PAYMENT_AMOUNT')} secondary={`${utils.currencyFormat(cardRequest.amount)} ${currency.iso}`} />
          </ListItem>
          {currency.iso !== 'EUR' &&
            <ListItem disableGutters>
              <ListItemText primary={t('PAYMENT_AMOUNT_EURO')} secondary={`${utils.currencyFormat(cardRequest.amount * cardRequest.exchange_rate)} EUR`} />
            </ListItem>
          }
          <ListItem disableGutters>
            <ListItemText primary={t('REFUNDED_AT')} secondary={refund?.created ? moment(refund.created).format('DD-MM-YYYY HH:mm:ss') : '-'} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

const CardRequest = (props: { cardRequest: CardRequest; country: Country; deliveryMethod: DeliveryMethod }) => {
  const { t, i18n } = useTranslation('visaInstantCardRequests');
  const { cardRequest, country, deliveryMethod } = props;
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant='subtitle2'>{t('DELIVERY_ADDRESS')}</Typography>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary={t('NAME')} secondary={cardRequest.name} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('PHONE')} secondary={cardRequest.phone} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('STREET')} secondary={utils.getStringOrDefault(cardRequest.address)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('CITY')} secondary={utils.getStringOrDefault(cardRequest.city)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('POSTAL_CODE')} secondary={utils.getStringOrDefault(cardRequest.postal_code)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('REGION')} secondary={utils.getStringOrDefault(cardRequest.state)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('COUNTRY')} secondary={country.name} />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='subtitle2'>{t('DETAILS')}</Typography>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary={t('CARD_COUNT')} secondary={t(cardRequest.num_cards.toString(10))} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('STATUS')} secondary={t(cardRequest.status)} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('DELIVERY_METHOD')} secondary={deliveryMethod.name[i18n.language]} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('TRACKING_DETAILS')} secondary={cardRequest.tracking_details ? cardRequest.tracking_details : '-'} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('CREATED_AT')} secondary={moment(cardRequest.created_at).format('DD-MM-YYYY HH:mm:ss')} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary={t('UPDATED_AT')} secondary={cardRequest.updated_at ? moment(cardRequest.updated_at).format('DD-MM-YYYY HH:mm:ss') : '-'} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export const CardRequestDetails = (props: RouteComponentProps<{ id: string }>) => {
  const cardRequestId = props.match.params.id
  const { t } = useTranslation('visaInstantCardRequests');
  const classes = useStyles();
  const api = useVisaInstantApi();
  const history = useHistory();

  const [cardRequestDetails, setCardRequestDetails] = React.useState<DetailedCardRequest | null>(null);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [loadingLetter, setLoadingLetter] = React.useState(false);
  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showAssignCardModal, setShowAssignCardModal] = React.useState(false);
  const [showShipCardModal, setShowShipCardModal] = React.useState(false);

  const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const getLetter = () => {
    utils.runAsync(async () => {
      setLoadingLetter(true);
      const url = await api.getCardLetter(cardRequestDetails!.card_request.id.toString(10));
      window.location.href = url;
    }, (e) => {
      if (e) {
        console.log(e);
      }
      setLoadingLetter(false);
    });
  }

  const loadCardRequestDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setCountries(await api.getCountries());
      setCardRequestDetails(await api.getCardRequest(cardRequestId));
    }, (e) => {
      setLoading(false);
      if (e) {
        console.log(e);
      }
    });
  }


  React.useEffect(() => {
    loadCardRequestDetails();
  }, []);

  if (loading && cardRequestDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }


  const canCancel = () => {
    return cardRequestDetails?.card_request.status === CardRequestStatus.NEW;
  }

  const canAssignCard = () => {
    return cardRequestDetails?.card_request.status === CardRequestStatus.NEW;
  }

  const canShipCard = () => {
    return cardRequestDetails?.card_request.status === CardRequestStatus.PROCESSING;
  }


  const canRefund = () => {
    if (!cardRequestDetails) {
      return false;
    }
    return cardRequestDetails.refunds.length === 0 && cardRequestDetails.revolupay_order.completed;
  }

  return (
    <>
      <RefundModal
        open={showRefundModal}
        revolupayOrderId={cardRequestDetails!.revolupay_order.id}
        onClose={() => {
          setShowRefundModal(false);
          loadCardRequestDetails();
        }} />
      <CancelModal
        open={showCancelModal}
        cardRequestId={cardRequestDetails!.card_request.id}
        onClose={() => {
          setShowCancelModal(false);
          loadCardRequestDetails();
        }} />
      <AssignCardModal
        open={showAssignCardModal}
        numCards={cardRequestDetails!.card_request.num_cards}
        cardRequestId={cardRequestDetails!.card_request.id}
        onClose={() => {
          setShowAssignCardModal(false);
          loadCardRequestDetails();
        }} />
      <ShipCardModal
        open={showShipCardModal}
        cardRequestId={cardRequestDetails!.card_request.id}
        onClose={() => {
          setShowShipCardModal(false);
          loadCardRequestDetails();
        }} />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('CARD_ORDER')} />
              <CardContent>
                <CardRequest cardRequest={cardRequestDetails!.card_request} country={cardRequestDetails!.country} deliveryMethod={cardRequestDetails!.delivery_method} />
              </CardContent>
              <CardActions className={classes.cardActions}>
                {cardRequestDetails!.cards.length > 0 &&
                  <LoadingButton loading={loadingLetter} onClick={getLetter} size='small'>{t('DOWNLOAD_SHIPMENT_LETTER')}</LoadingButton>
                }
                <Button variant='contained' color='primary' aria-controls='actions-menu' aria-haspopup='true' onClick={handleActionsClick}>
                  {t('ACTIONS')}
                </Button>

                <Menu id='actions-menu' anchorEl={actionsAnchorEl} keepMounted open={Boolean(actionsAnchorEl)} onClose={handleActionsClose}>
                  <MenuItem
                    onClick={() => {
                      setShowCancelModal(true);
                      handleActionsClose();
                    }}
                    disabled={!canCancel()}>
                    {t('CANCEL')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowAssignCardModal(true);
                      handleActionsClose();
                    }}
                    disabled={!canAssignCard()}>
                    {t('ASSIGN_CARD')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setShowShipCardModal(true);
                      handleActionsClose();
                    }}
                    disabled={!canShipCard()}>
                    {t('SHIP_CARD')}
                  </MenuItem>
                </Menu>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('PAYMENT')} />
              <CardContent>
                <Payment
                  cardRequest={cardRequestDetails!.card_request}
                  revolupayOrder={cardRequestDetails!.revolupay_order}
                  currency={cardRequestDetails!.currency}
                  refunds={cardRequestDetails!.refunds}
                />
              </CardContent>
              <CardActions className={classes.cardActions}>
                <LoadingButton onClick={() => setShowRefundModal(true)} disabled={!canRefund()} size='small'>{t('ISSUE_REFUND_BUTTON_TITLE')}</LoadingButton>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('CARD_OWNER')} />
              <CardContent>
                <User countries={countries} user={cardRequestDetails!.user} />
              </CardContent>
            </Card>
          </Grid>
          {cardRequestDetails!.cards.length > 0 &&
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box padding={2}>
                          <Typography variant='h5'>
                            {t('CARDS')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('ID')}</TableCell>
                                <TableCell>{t('LAST4')}</TableCell>
                                <TableCell>{t('MP')}</TableCell>
                                <TableCell>{t('EXPIRY')}</TableCell>
                                <TableCell>{t('STATUS')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cardRequestDetails!.cards.map(c =>
                                <CardRow
                                  card={c}
                                  key={c.id}
                                />)}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          }

        </Grid>
      </Grid>
    </>
  )
}