import { createStyles, Theme } from '@material-ui/core';
import { Card, CardActions, CardContent, CardHeader, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../components/loadingButton';
import moment, { Moment } from 'moment';
import { AlertDialog } from '../../components/alertDialog';
import utils from '../../services/utils.service';
import { useRevolusendApi } from '../../context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);

export const Reports = () => {

  const { t } = useTranslation('revolusendReports');

  const api = useRevolusendApi();

  const [transactionStart, setTransactionStart] = React.useState<Moment | null>(moment().startOf('month'));
  const [transactionEnd, setTransactionEnd] = React.useState<Moment | null>(moment().endOf('month'));
  const [transactionLoading, setTransactionLoading] = React.useState(false);
  const [userStart, setUserStart] = React.useState<Moment | null>(moment().startOf('month'));
  const [userEnd, setUserEnd] = React.useState<Moment | null>(moment().endOf('month'));
  const [userLoading, setUserLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const classes = useStyles();

  const buildTransactionReport = () => {
    utils.runAsync(async () => {
      setTransactionLoading(true);
      const response = await api.getTransactionReport(transactionStart!.toISOString(), transactionEnd!.clone().endOf('day').toISOString());
      window.open(response.file_url);
    }, (e) => {
      setTransactionLoading(false);
      if (e) {
        setError(true);
      }
    });
  }

  const buildUserReport = () => {
    utils.runAsync(async () => {
      setUserLoading(true);
      const response = await api.getUserReport(userStart!.toISOString(), userEnd!.clone().endOf('day')!.toISOString());
      window.open(response.file_url);
    }, (e) => {
      setUserLoading(false);
      if (e) {
        setError(true);
      }
    });
  }

  return (
    <>
      <AlertDialog
        open={error}
        title={t('ERROR_TITLE')}
        message={t('ERROR_MESSAGE')}
        onClose={() => setError(false)}
      />
      <Grid container spacing={2}>


        <Grid item container xs={12} spacing={2}>


          <Grid item xs={12}>
            <Card>
              <CardHeader title={t('TRANSACTION_REPORTS')} />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      okLabel={t('OK')}
                      cancelLabel={t('CANCEL')}
                      clearLabel={t('CLEAR')}
                      fullWidth={true}
                      label={t('START')}
                      format='DD/MM/YYYY'
                      value={transactionStart}
                      onChange={(value) => {
                        if (value !== null) {
                          value = value.startOf('day');
                        }
                        setTransactionStart(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      okLabel={t('OK')}
                      cancelLabel={t('CANCEL')}
                      clearLabel={t('CLEAR')}
                      fullWidth={true}
                      label={t('END')}
                      format='DD/MM/YYYY'
                      value={transactionEnd}
                      onChange={(value) => {
                        if (value !== null) {
                          value = value.startOf('day');
                        }
                        setTransactionEnd(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <LoadingButton
                  loading={transactionLoading}
                  disabled={transactionLoading}
                  variant='contained'
                  color='primary'
                  onClick={buildTransactionReport}
                >
                  {t('BUILD_REPORT')}
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={t('USER_REPORTS')} />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      okLabel={t('OK')}
                      cancelLabel={t('CANCEL')}
                      clearLabel={t('CLEAR')}
                      fullWidth={true}
                      label={t('START')}
                      format='DD/MM/YYYY'
                      value={userStart}
                      onChange={(value) => {
                        if (value !== null) {
                          value = value.startOf('day');
                        }
                        setUserStart(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      okLabel={t('OK')}
                      cancelLabel={t('CANCEL')}
                      clearLabel={t('CLEAR')}
                      fullWidth={true}
                      label={t('END')}
                      format='DD/MM/YYYY'
                      value={userEnd}
                      onChange={(value) => {
                        if (value !== null) {
                          value = value.startOf('day');
                        }
                        setUserEnd(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <LoadingButton
                  loading={userLoading}
                  disabled={userLoading}
                  variant='contained'
                  color='primary'
                  onClick={buildUserReport}
                >
                  {t('BUILD_REPORT')}
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>


        </Grid>


      </Grid>
    </>
  );
}
