import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useRevolusendApi } from '../../context';
import utils from '../../services/utils.service';
import moment from 'moment';
import { UserProfile } from '../../services/types/revolusend/userProfile.type';
import { Country } from '../../services/types/revolusend/country.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);

export const UserDetails = (props: RouteComponentProps<{ id: string }>) => {
  const userId = props.match.params.id
  const { t } = useTranslation(['revolusendUsers']);
  const classes = useStyles();
  const api = useRevolusendApi();

  const [userDetails, setUserDetails] = React.useState<UserProfile | null>(null);
  const [countries, setCountries] = React.useState<Country[] | null>(null);


  const loadUserDetails = () => {
    utils.runAsync(async () => {
      setCountries(await api.listRevolusendCountries());
      setUserDetails(await api.getUser(parseInt(userId, 10)));
    }, (e) => {
      if (e) {
        console.log(e);
      }
    });
  }


  React.useEffect(() => {
    loadUserDetails();
  }, []);

  if (userDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  const profileData = userDetails!.profile.data;
  const kycData = userDetails!.kyc.data;
  const countriesMap = countries!.reduce((p, n) => {
    p[n.id] = n;
    return p;
  }, {} as { [key: number]: Country });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardHeader title={t('USER_DETAILS')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle2'>{t('IDENTIFICATION')}</Typography>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ID')} secondary={userId} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('STATUS')} secondary={userDetails.status} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('NAME')} secondary={`${profileData.first_name} ${profileData.last_name}`} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('PROFILE_VALID')} secondary={t((userDetails!.profile.complete) ? 'YES' : 'NO')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('KYC_VALID')} secondary={t((userDetails!.kyc.valid) ? 'YES' : 'NO')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('VALID_PROFILE_PICTURE')} secondary={t(userDetails!.profile.data.avatar  ? 'YES' : 'NO')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DOCUMENT_STATUS')} secondary={t(userDetails!.kyc.status)} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DOCUMENT_TYPE')} secondary={kycData.id_card_type ? t(`revolusendDocumentTypes:${kycData.id_card_type}`) : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DOCUMENT_ISSUE_COUNTRY')} secondary={kycData.id_card_country_id ? countriesMap[kycData.id_card_country_id].name : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DOCUMENT_NUMBER')} secondary={kycData?.id_card_number} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DOCUMENT_ISSUE_DATE')} secondary={kycData.id_card_issue_date ? moment(kycData.id_card_issue_date).format('DD-MM-YYYY') : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DOCUMENT_EXPIRY_DATE')} secondary={kycData.id_card_expiry_date ? moment(kycData.id_card_expiry_date).format('DD-MM-YYYY') : '-'} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle2'>{t('CONTACT_DETAILS')}</Typography>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('PHONE')} secondary={profileData.phone} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('EMAIL')} secondary={profileData.email} />
                      </ListItem>
                    </List>
                    <Typography variant='subtitle2' style={{ marginTop: 10 }}>{t('ADDRESS')}</Typography>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('STREET')} secondary={utils.getStringOrDefault(profileData.address)} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CITY')} secondary={utils.getStringOrDefault(profileData.city)} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('POSTAL_CODE')} secondary={utils.getStringOrDefault(profileData.postal_code)} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('REGION')} secondary={utils.getStringOrDefault(profileData.region)} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('COUNTRY')} secondary={profileData.residence_country_id ? countriesMap[profileData.residence_country_id].name : '-'} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}