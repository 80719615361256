import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRevolusendApi } from '../../context';
import utils from '../../services/utils.service';
import moment from 'moment';
import { TransactionListItem } from '../../services/types/revolusend/transactionListItem.type';
import DetailsIcon from '@material-ui/icons/Info';
import { LoadingButton } from '../../components/loadingButton';
import { Autocomplete } from '@material-ui/lab';
import { TransactionType } from '../../services/types/revolusend/transactionType';
import { AlertDialog } from '../../components/alertDialog';


const Row = (props: { transaction: TransactionListItem, selected: boolean, toggle: (item: TransactionListItem) => void }) => {
  const { transaction, selected, toggle } = props;
  const history = useHistory();
  const { t } = useTranslation(['revolusendTransfers', 'revolusendDeliveryMethod']);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={selected}
            onChange={() => toggle(transaction)}
            color='secondary'
          />
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/revolusend/transfers/' + transaction.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {`${transaction.sender.first_name} ${transaction.sender.last_name} (${transaction.sender.email})`}
        </TableCell>
        <TableCell>
          {`${transaction.receiver.first_name} ${transaction.receiver.last_name} (${transaction.receiver.email})`}
        </TableCell>
        <TableCell>
          {transaction.receiver.account_fields?.map(a => a.value).join(' - ')}
        </TableCell>
        <TableCell>
          {transaction.product.country.name}
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(transaction.receive_amount)} ${transaction.product.currency.iso}`}
        </TableCell>
        <TableCell>
          <Chip label={t(transaction.status)} color={['ACCEPTED', 'DELIVERED', 'FAILED'].includes(transaction.status) ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          <Chip label={transaction.refunded_on ? t('YES') : t('NO')} color={transaction.refunded_on ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          {moment(transaction.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const NewBatch = () => {
  const { t, i18n } = useTranslation(['revolusendBatches', 'revolusendTransfers', 'revolusendDeliveryMethod']);
  const api = useRevolusendApi();
  const history = useHistory();

  moment.locale(i18n.language);

  const [loading, setLoading] = React.useState(true);
  const [loadingTransactions, setLoadingTransactions] = React.useState(false);
  const [transactionStates, setTransactionStates] = React.useState<{ [key: number]: boolean }>({});
  const [transactions, setTransactions] = React.useState<TransactionListItem[]>([]);

  const [transactionTypes, setTransactionTypes] = React.useState<TransactionType[]>([]);

  const [selectedTransactionType, setSelectedTransactionType] = React.useState<TransactionType | null>(null);
  const [companyName, setCompanyName] = React.useState('Revolupay EP S.L.U.');
  const [companyId, setCompanyId] = React.useState('B67233817000');
  const [bic, setBic] = React.useState('BKBKESMMXXX');
  const [iban, setIban] = React.useState('ES7301289446910100017361');

  const [errors, setErrors] = React.useState({
    companyName: false,
    companyId: false,
    bic: false,
    iban: false
  });

  const [submitError, setSubmitError] = React.useState(false);

  const newBatch = () => {
    const validationErrors = {
      companyName: false,
      companyId: false,
      bic: false,
      iban: false
    };
    let hasErrors = false;
    if (companyName.length === 0) {
      validationErrors.companyName = true;
      hasErrors = true;
    }
    if (companyId.length === 0) {
      validationErrors.companyId = true;
      hasErrors = true;
    }
    if (bic.length === 0) {
      validationErrors.bic = true;
      hasErrors = true;
    }
    if (iban.length === 0) {
      validationErrors.iban = true;
      hasErrors = true;
    }
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }

    const selectedTransactionIds: number[] = [];
    for (const transactionId in transactionStates) {
      if (transactionStates[transactionId]) {
        selectedTransactionIds.push(Number(transactionId));
      }
    }

    utils.runAsync(async () => {
      setLoading(true);
      const batchId = await api.newBatch(
        companyName,
        companyId,
        bic,
        iban,
        selectedTransactionType!.code,
        selectedTransactionIds
      );
      history.push('/revolusend/batches/' + batchId);
    }, (e) => {
      if (e) {
        setLoading(false);
        setSubmitError(true);
      }
    });
  }

  const resetErrors = () => {
    setErrors({
      companyName: false,
      companyId: false,
      bic: false,
      iban: false
    });
  }


  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const allTransactionTypes = await api.listRevolusendTransactionTypes();
      setTransactionTypes(allTransactionTypes.filter(t => ['ISO20022', 'MANUAL_TRANSFER'].includes(t.code)));
    }, () => {
      setLoading(false);
    })
  }

  const loadTransactions = () => {
    utils.runAsync(async () => {
      setLoadingTransactions(true);
      let count = 0;
      let pTransactions: TransactionListItem[] = [];
      do {
        const { transactions: trx, count: cnt } = await api.listRevolusendTransactions({
          excludeBatchTransactions: true,
          transactionTypeId: selectedTransactionType?.id,
          status: 'PENDING',
          paid: true
        }, 50, pTransactions.length, 'desc');
        count = cnt;
        pTransactions.push(...trx);
      } while (pTransactions.length < count);
      setTransactions(pTransactions);
      setTransactionStates(pTransactions.reduce((p, c) => {
        p[c.id] = true;
        return p;
      }, {} as any))

    }, () => {
      setLoadingTransactions(false);
    })
  }

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (selectedTransactionType !== null) {
      loadTransactions();
    }

  }, [selectedTransactionType]);

  return (
    <>
      <AlertDialog
        title={t('ERROR_CREATING_BATCH_TITLE')}
        message={t('ERROR_CREATING_BATCH_MESSAGE')}
        open={submitError}
        onClose={() => setSubmitError(false)}
      />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>{t('CREATE_NEW_BATCH')}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        <Typography gutterBottom>
                          {t('CREATE_NEW_BATCH_HELPTEXT')}
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2} style={{ marginTop: 4 }}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('COMPANY_NAME')}
                            value={companyName}
                            error={errors.companyName}
                            helperText={errors.companyName ? t('REQUIRED') : ''}
                            onChange={(e) => { setCompanyName(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('COMPANY_ID')}
                            value={companyId}
                            error={errors.companyId}
                            helperText={errors.companyId ? t('REQUIRED') : ''}
                            onChange={(e) => { setCompanyId(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('SOURCE_BIC')}
                            value={bic}
                            error={errors.bic}
                            helperText={errors.bic ? t('REQUIRED') : ''}
                            onChange={(e) => { setBic(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={t('SOURCE_IBAN')}
                            value={iban}
                            error={errors.iban}
                            helperText={errors.iban ? t('REQUIRED') : ''}
                            onChange={(e) => { setIban(e.target.value); resetErrors(); }}
                            type='text'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            onChange={(event, value) => {
                              setSelectedTransactionType(value);
                              resetErrors();
                            }}
                            value={selectedTransactionType}
                            options={transactionTypes}
                            disabled={loading}
                            autoHighlight
                            getOptionLabel={(option) => t('revolusendDeliveryMethod:' + option.code)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option) => t('revolusendDeliveryMethod:' + option.code)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('SELECT_TRANSACTION_TYPE')}
                                variant='standard'
                                fullWidth={true}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                              />
                            )} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {!loadingTransactions && selectedTransactionType &&
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Box paddingY={3} paddingX={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant='h6'>{t('TRANSFERS')}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {transactions.length > 0 &&
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>{t('revolusendTransfers:ISSUER')}</TableCell>
                              <TableCell>{t('revolusendTransfers:RECEIVER')}</TableCell>
                              <TableCell>{t('revolusendTransfers:ACCOUNT')}</TableCell>
                              <TableCell>{t('revolusendTransfers:COUNTRY')}</TableCell>
                              <TableCell>{t('revolusendTransfers:AMOUNT')}</TableCell>
                              <TableCell>{t('revolusendTransfers:STATUS')}</TableCell>
                              <TableCell>{t('revolusendTransfers:REFUNDED')}</TableCell>
                              <TableCell>{t('revolusendTransfers:CREATED')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {transactions.map(t =>
                              <Row
                                toggle={t => setTransactionStates({ ...transactionStates, [t.id]: !transactionStates[t.id] })}
                                selected={transactionStates[t.id]}
                                transaction={t}
                                key={t.id}
                              />)}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    }
                    {transactions.length === 0 &&
                      <Grid item xs={12} style={{ padding: 20 }}>
                        <Grid container justify='center' item xs={12}>
                          <Typography variant='h5'>
                            {t('NO_PENDING_BATCH_TRANSACTIONS')}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                }
                {loadingTransactions &&
                  <Grid item xs={12} style={{ padding: 20 }}>
                    <Grid container justify='center' item xs={12}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                }

                <Grid item container justify='flex-end' xs={12}>
                  <Box marginTop={6} paddingY={1} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <LoadingButton loading={loading} disabled={loadingTransactions || !selectedTransactionType || loading} variant='contained' color='primary' onClick={() => { newBatch(); }}>
                          {t('CREATE')}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}