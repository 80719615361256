import React, { useEffect } from 'react';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRevolusendApi } from '../../context';
import utils from '../../services/utils.service';

export const Config = () => {
  const { t } = useTranslation('revolusendConfig');
  const api = useRevolusendApi();
  const [loading, setLoading] = React.useState(true);

  const [
    submitTransactionsAfterAutoApproval,
    setSubmitTransactionsAfterAutoApproval
  ] = React.useState(false);

  useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      const settings = await api.getSettings();
      if (settings.submitAfterAutoApproval) {
        setSubmitTransactionsAfterAutoApproval(true);
      }
    }, (e) => {
      if (e) {
        console.error(e);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const toggleAutoApprovalSubmission = () => {
    utils.runAsync(async () => {
      setLoading(true);
      console.log({submitTransactionsAfterAutoApproval:!submitTransactionsAfterAutoApproval})
      await api.setSettings({ submitAfterAutoApproval: !submitTransactionsAfterAutoApproval });
      setSubmitTransactionsAfterAutoApproval(!submitTransactionsAfterAutoApproval);
    }, (e) => {
      if (e) {
        console.error(e);
        setSubmitTransactionsAfterAutoApproval(!submitTransactionsAfterAutoApproval);
      }
      setLoading(false);
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={12} alignContent='flex-start'>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('GLOBAL_CONFIG')}
            </Typography>
          </Grid>
          <Grid container item xs={12} alignItems='center'>
            {loading &&
              <CircularProgress size='24px' style={{ marginRight: '20px' }} />
            }
            <FormControlLabel
              control={
                <Switch
                  checked={submitTransactionsAfterAutoApproval}
                  onChange={() => { toggleAutoApprovalSubmission() }}
                />
              }
              label={t('SUBMIT_TRANSACTION_AFTER_AUTO_APPROVAL')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
