import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRevoluexApi } from '../../context';
import utils from '../../services/utils.service';
import EditIcon from '@material-ui/icons/Edit'
import { LoadingButton } from '../../components/loadingButton';
import { UserRestriction } from '../../services/types/revoluex/userRestriction.type';

const EditModal = (props: { userRestriction: UserRestriction | null, open: boolean, onClose: () => void }) => {
  const { t } = useTranslation('revoluexConfig');
  const { userRestriction, open, onClose } = props;
  const api = useRevoluexApi();
  const [loading, setLoading] = React.useState(false);
  const [limit, setLimit] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (userRestriction) {
      setLimit(userRestriction?.max_monthly_amount.toString(10));
      setError(false);
    }
  }, [open]);

  const update = () => {
    if (!userRestriction) {
      return;
    }
    if (isNaN(Number.parseFloat(limit)) || Number.parseFloat(limit) < 0) {
      setError(true);
      return false;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.updateUserRestriction({ level: userRestriction.level,max_monthly_amount: parseFloat(limit) });
      onClose();
    }, (e) => {
      setLoading(false);
    });
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('EDIT_RESTRICTION_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('EDIT_RESTRICTION_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('MONTHLY_LIMIT')}
              onChange={(e) => setLimit(e.target.value)}
              value={limit}
              error={error}
              helperText={error ? t('FORMAT_ERROR') : ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'€UR'}</InputAdornment>
              }}
              type='number'
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton disabled={loading} loading={loading} onClick={update} color='primary'>
          {t('UPDATE')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

}

export const Config = () => {
  const { t } = useTranslation('revoluexConfig');
  const api = useRevoluexApi();
  const [restrictions, setRestrictions] = React.useState<UserRestriction[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [editing, setEditing] = React.useState(false);
  const [restrictionForEdit, setRestrictionForEdit] = React.useState<UserRestriction | null>(null);
  const [topupStatus, setTopupStatus] = React.useState(false);

  const loadRestrictions = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setTopupStatus((await api.getTopupStatus()).status);
      setRestrictions(await api.getUserRestrictions());
    }, (e) => {
      setLoading(false);
    });
  }

  React.useEffect(() => {
    loadRestrictions();
  }, []);

  const toggleTopupStatus = () => {
    utils.runAsync(async () => {
      setTopupStatus(!topupStatus);
      await api.setTopupStatus(!topupStatus);
    })
  }

  return (
    <>
      <EditModal
        open={editing}
        userRestriction={restrictionForEdit}
        onClose={() => {
          setEditing(false);
          setRestrictionForEdit(null);
          loadRestrictions();
        }}
      />
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={6} alignContent='flex-start'>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('GLOBAL_CONFIG')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!loading &&
              <FormControlLabel
                control={<Switch checked={topupStatus} onChange={() => { toggleTopupStatus() }} />}
                label={t('TOPUPS_ENABLED')}
              />
            }
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={6}>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('USER_LEVEL_RESTRICTIONS')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>{t('LEVEL')}</TableCell>
                          <TableCell>{t('MAX_MONTHLY_AMOUNT')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {restrictions.map(r => (
                          <TableRow key={r.level}>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  setRestrictionForEdit(r);
                                  setEditing(true);
                                }}
                                size='small'>
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>{r.level}</TableCell>
                            <TableCell>{`${utils.currencyFormat(r.max_monthly_amount)} €UR`}</TableCell>
                          </TableRow>
                        ))}
                        {loading && restrictions.length === 0 &&
                          <TableCell colSpan={3}>
                            <Box padding={2}>
                              <Grid container justify='center' item xs={12}>
                                <CircularProgress />
                              </Grid>
                            </Box>
                          </TableCell>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid >
      </Grid>
    </>
  )
}
