import React from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useRevolusendApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { DecoratedB2BUser } from '../../services/types/revolusend/decoratedB2BUser.type';
import { AccountStatus, KycStatus } from '../../services/types/revolusend/b2bUser.type';
import { Tenant } from '../../services/types/revolusend/tenant.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);



const Row = (props: { user: DecoratedB2BUser }) => {
  const { user } = props;
  const { t } = useTranslation(['revolusendUsers']);

  const getKycStatusColor = () => {
    if (user.user.kyc_status === KycStatus.KYC_OK) {
      return 'primary';
    } else if (user.user.kyc_status === KycStatus.KYC_KO) {
      return 'secondary';
    } else if (user.user.kyc_status === KycStatus.KYC_SUBMITTED) {
      return 'secondary';
    } else {
      return 'default';
    }
  }

  const getAccountStatusColor = () => {
    if (user.user.account_status === AccountStatus.ACTIVE) {
      return 'primary';
    } else {
      return 'default';
    }
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <a href={'/revolusend/users/' + user.user.id} target='_blank'>
            <IconButton size='small'>
              <DetailsIcon />
            </IconButton>
          </a>
        </TableCell>
        <TableCell>
          {user.tenant.name}
        </TableCell>
        <TableCell>
          {user.user.id}
        </TableCell>
        <TableCell>
          <Chip color={getKycStatusColor()} label={t(user.user.kyc_status)} />
        </TableCell>
        <TableCell>
          <Chip color={getAccountStatusColor()} label={t(user.user.account_status)} />
        </TableCell>
        <TableCell>
          {moment(user.user.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {user.user.updated ? moment(user.user.updated).format('DD-MM-YYYY HH:mm:ss') : '-'}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const B2BUsers = () => {
  const { t } = useTranslation(['revolusendUsers']);
  const api = useRevolusendApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<DecoratedB2BUser[]>([]);

  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [selectedTenant, selectTenant] = React.useState<Tenant | null>(null);

  const states = [
    KycStatus.KYC_PENDING,
    KycStatus.KYC_SUBMITTED,
    KycStatus.KYC_OK,
    KycStatus.KYC_KO
  ];

  const [selectedStatus, selectStatus] = React.useState<KycStatus | null>(null);

  const [page, setPage] = React.useState(0);


  const limit = 25;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { users, count } = await api.listB2bUsers({
        tenantId: selectedTenant?.id,
        kyc_status: selectedStatus ? selectedStatus : undefined,
      }, limit, page * limit, 'desc');
      setUsers(users);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setTenants(await api.listTenants());
    });
  }, []);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(0);
      loadData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedStatus,
    selectedTenant
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectTenant(value);
              }}
              value={selectedTenant}
              options={tenants}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_TENANT')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectStatus(value);
              }}
              value={selectedStatus}
              options={states}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => t(option)}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_STATUS')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('ID')}</TableCell>
                      <TableCell>{t('TENANT')}</TableCell>
                      <TableCell>{t('KYC_STATUS')}</TableCell>
                      <TableCell>{t('ACCOUNT_STATUS')}</TableCell>
                      <TableCell>{t('CREATED')}</TableCell>
                      <TableCell>{t('UPDATED')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map(u =>
                      <Row
                        user={u}
                        key={u.user.id}
                      />)}
                    {loading && users.length === 0 &&
                      <TableCell colSpan={7}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && users.length === 0 &&
                      <TableCell colSpan={7}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
