import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
  Drawer,
  Hidden,
  Container,
  Box
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import logo from '../assets/img/logo.png'
import { useAuth, useContext } from '../context';
import WidgetsIcon from '@material-ui/icons/Widgets'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import RedeemIcon from '@material-ui/icons/Redeem';
import MenuIcon from '@material-ui/icons/Menu';
import PromoIcon from '@material-ui/icons/LocalPlay';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import gravatar from 'gravatar';
import { useTranslation } from 'react-i18next';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ErrorIcon from '@material-ui/icons/Error';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ReceiptIcon from '@material-ui/icons/Receipt';

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    draweSectionTitle: {
      padding: '10px 0 5px 20px'

    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    toolbar: {
      display: 'flex'
    },
    grow: {
      flexGrow: 1
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    toolbarSpacer: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logo: {
      maxWidth: '95%',
      maxHeight: '100%'
    },
    expansionDetails: {
      padding: 0
    },
  })
);


const ListItemLink = ({ icon, primary, to, click }: { icon: any, primary: string, to: string, click: () => void }) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, any>((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );
  return (
    <ListItem button component={CustomLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText color='black' primary={primary} />
    </ListItem>
  )
}

export const Layout = ({ children, name }: { children: any, name: string }) => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const auth = useAuth();
  const context = useContext();

  const [anchorEl, setAnchorEl] = React.useState<EventTarget | null>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  }

  const handleProfileMenuOpen = (target: EventTarget) => {
    setAnchorEl(target);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  const isMenuOpen = Boolean(anchorEl);

  const drawer = ({ mobile }: { mobile: boolean }) => (
    <>
      <div className={classes.toolbarSpacer}>
        <img className={classes.logo} src={logo} />
      </div>
      <Divider />
      <Typography className={classes.draweSectionTitle} variant='h6'>Revolusend</Typography>
      <Divider />
      <List>
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/tenants'
          primary={t('TENANTS')}
          icon={<BusinessIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/transfers'
          primary={t('TRANSFERS')}
          icon={<ShoppingCartIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/users'
          primary={t('USERS')}
          icon={<PersonIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/b2b-users'
          primary={t('B2B_USERS')}
          icon={<PersonIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/products'
          primary={t('PRODUCTS')}
          icon={<WidgetsIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/promos'
          primary={t('PROMOS')}
          icon={<PromoIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/currencies'
          primary={t('CURRENCIES')}
          icon={<MonetizationOnIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/batches'
          primary={t('BATCHES')}
          icon={<AccountBalanceIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/reports'
          primary={t('REPORTS')}
          icon={<AssessmentIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revolusend/settings'
          primary={t('CONFIG')}
          icon={<SettingsIcon />}
        />
        <Divider />
        <Typography className={classes.draweSectionTitle} variant='h6'>Revoluex</Typography>
        <Divider />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revoluex/chains'
          primary={t('CHAINS')}
          icon={<AttachMoneyIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revoluex/topups'
          primary={t('TOPUPS')}
          icon={<AccountBalanceWalletIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revoluex/deposits'
          primary={t('UNMATCHED_DEPOSITS')}
          icon={<ErrorIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/revoluex/config'
          primary={t('CONFIG')}
          icon={<SettingsIcon />}
        />
        <Divider />
        <Typography className={classes.draweSectionTitle} variant='h6'>Visa Instant</Typography>
        <Divider />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/visa-instant/card-requests'
          primary={t('CARD_REQUESTS')}
          icon={<RedeemIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/visa-instant/cards'
          primary={t('CARDS')}
          icon={<CreditCardIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/visa-instant/topups'
          primary={t('CARD_TOPUPS')}
          icon={<AccountBalanceIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/visa-instant/transactions'
          primary={t('CARD_TRANSACTIONS')}
          icon={<ReceiptIcon />}
        />
        <Divider />
        <Typography className={classes.draweSectionTitle} variant='h6'>Direct Banking</Typography>
        <Divider />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/directbanking/orders'
          primary={t('ORDERS')}
          icon={<ReceiptIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/directbanking/batches'
          primary={t('REFUND_BATCHES')}
          icon={<AccountBalanceIcon />}
        />
      </List>
    </>
  )

  return (
    <div className={classes.main}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color='inherit'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' className={classes.grow}>
            {t(name)}
          </Typography>
          <IconButton
            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
            aria-haspopup='true'
            onClick={(event) => {
              handleProfileMenuOpen(event.target)
            }}
            color='inherit'
          >
            <Avatar alt={context.data.userEmail} src={gravatar.url(context.data.userEmail, { d: 'identicon' })} />
          </IconButton>
          <Menu
            anchorEl={anchorEl as Element}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={() => {
              window.location.href = auth.getLogoutUri();
            }}>{t('LOGOUT')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Drawer
          variant='temporary'
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer({ mobile: true })}
        </Drawer>
        <Hidden xsDown implementation='css'>
          <Drawer
            variant='permanent'
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
          >
            {drawer({ mobile: false })}
          </Drawer>
        </Hidden>
      </nav>
      <Box marginTop={10} width={'100%'}>
        <Container>
          {children}
        </Container>
      </Box>
    </div>
  )
};

