import * as voucherCode from 'voucher-code-generator';
const utils = {
  EUContriesIso2: ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'MC', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM', 'SK', 'SI', 'ES', 'SE', 'CH'],
  countryToFlag: (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  },
  roundToDecimals(input: number, decimals: number = 2): number {
    return Number(
      Math.round(Number(input + 'e' + decimals))
      + 'e-' + decimals,
    );
  },
  round: (aNumber: number) => Math.round((aNumber + Number.EPSILON) * 100) / 100,
  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },
  currencyFormat: (aNumber: number | undefined) => new Intl.NumberFormat(
    ['es-ES', 'en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }
  ).format(aNumber ? aNumber : 0),

  getStringOrDefault(v: string | number | undefined | null, def: string = ''): string {
    if (typeof v === 'number') {
      return v.toString(10);
    }
    if (v) return v;
    return def;
  },

  isEmailValid(mail: string): boolean {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return (true)
    }
    return (false)
  },
  generateVoucher(): string {
    return voucherCode.generate({ count: 1, length: 6, charset: 'alphanumeric' }).pop().toUpperCase();
  },
  getFileIcon(contentType: string): string {
    const mimeTypes: any = {
      'image': 'fa-file-image',
      'audio': 'fa-file-audio',
      'video': 'fa-file-video',
      'pdf': 'fa-file-pdf',
      'msword': 'fa-file-word',
      'vnd.ms-word': 'fa-file-word',
      'vnd.oasis.opendocument.text': 'fa-file-word',
      'vnd.openxmlformats-officedocument.wordprocessingml': 'fa-file-word',
      'vnd.ms-excel': 'fa-file-excel',
      'vnd.openxmlformats-officedocument.spreadsheetml': 'fa-file-excel',
      'vnd.oasis.opendocument.spreadsheet': 'fa-file-excel',
      'vnd.ms-powerpoint': 'fa-file-powerpoint',
      'vnd.openxmlformats-officedocument.presentationml': 'ffa-file-powerpoint',
      'vnd.oasis.opendocument.presentation': 'fa-file-powerpoint',
      'plain': 'fa-file-alt',
      'html': 'fa-file-code',
      'json': 'fa-file-code',
      'vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel',
      'vnd.openxmlformats-officedocument.presentationml.presentation': 'fa-file-powerpoint',
      'gzip': 'fa-file-archive',
      'zip': 'fa-file-archive',
      'x-zip-compressed': 'fa-file-archive',
      'octet-stream': 'fa-file-archive'
    }
    const parts = contentType.split('/');
    if (parts.length === 2) {
      const [part1, part2] = parts;
      if (mimeTypes[part1]) {
        return mimeTypes[part1];
      }
      if (mimeTypes[part2]) {
        return mimeTypes[part2];
      }
    }
    return 'fa-file';
  }
}
export default utils
