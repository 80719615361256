import { Context } from '../context';
import { Config } from '../config';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { LoginWindow } from './loginWindow.service';
import { certToPEM, rsaPublicKeyToPEM } from './rsa.service';

export class Auth {

  private accessToken?: string;

  constructor(
    private readonly config: Config,
    private readonly context: Context,
  ) { }

  private setTokens(accessToken: string, idToken: string) {
    this.accessToken = accessToken;
    localStorage.setItem(
      'accessToken',
      accessToken
    );
    localStorage.setItem(
      'idToken',
      idToken,
    );
  }

  public async getAccessToken(): Promise<string> {
    let accessToken: string | null;
    if (this.accessToken) {
      accessToken = this.accessToken;
    } else {
      accessToken = localStorage.getItem('accessToken');
    }
    if (accessToken && await this.isTokenValid(accessToken)) {
      return accessToken;
    } else if (accessToken) {
      this.logout();
    }
    throw new Error('No valid access token');
  }

  async attemptCookieLogin() {
    const accessToken = localStorage.getItem('accessToken')
    const idToken = localStorage.getItem('idToken')
    if (accessToken && await this.isTokenValid(accessToken) && idToken && await this.isTokenValid(idToken)) {
      this.accessToken = accessToken;
      const user = await this.decodeToken(idToken);
      this.context.setData({ isSignedIn: true, initializing: false, userEmail: user.email});
    } else {
      this.context.setData({ isSignedIn: false, initializing: false });
    }
  }

  async login(): Promise<boolean> {
    try {
      const response = await LoginWindow.show({ url: this.config.OAUTH_LOGIN!, width: 400, height: 450 });
      if (await this.isTokenValid(response.accessToken) && await this.isTokenValid(response.idToken)) {
        const user = await this.decodeToken(response.idToken);
        this.setTokens(response.accessToken, response.idToken);
        this.context.setData({ isSignedIn: true, initializing: false, userEmail: user.email });
        return true;
      }
      return false;
    } catch (e) {
      console.log('Login was aborted');
      return false;
    }
  }

  getLogoutUri(): string {
    return this.config.OAUTH_LOGOUT!;
  }

  logout() {
    this.accessToken = undefined;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    this.context.setData({ isSignedIn: false, userEmail: undefined});
  }

  private async isTokenValid(token: string): Promise<boolean> {
    try {
      const decodedToken = await this.decodeToken(token);
      return decodedToken['cognito:groups'].includes('admins');
    } catch (e) {
      return false;
    }
  }

  private decodeToken(token: string): Promise<any> {
    const getKey = async (kid: string): Promise<string> => {
      const response = await fetch(this.config.JWT_KEY_ENDPOINT!);
      if (response.ok) {
        let keys = (await response.json()).keys;
        if (!keys) {
          keys = [];
        }
        const key = keys.find((k: any) => k.kid === kid && k.kty === 'RSA' && k.use === 'sig')
        if (!key) {
          throw new Error('Could not find RSA key with matching KID');
        }
        if (key.x5c && key.x5c.length) {
          return certToPEM(key.x5c[0]);
        } else {
          return rsaPublicKeyToPEM(key.n, key.e);
        }
      } else {
        throw new Error('Could not fetch JWT keys');
      }
    }
    return new Promise(async (resolve, reject) => {
      jwt.verify(token, (header: { kid: string }, callback: (e: Error | null | undefined, key?: string) => void) => {
        getKey(header.kid).then((key) => {
          callback(null, key);
        }).catch((e) => {
          callback(new Error(e));
        });
      }, (err: any, decoded: any) => {
        if (err) reject(err)
        else (resolve(decoded))
      })
    })
  }

}