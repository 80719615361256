import React from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useVisaInstantApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { Country } from '../../services/types/visaInstant/country.type';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { CardRequest } from '../../services/types/visaInstant/cardRequest.type';
import { CardRequestStatus } from '../../services/types/visaInstant/cardRequestStatus.enum';
import { DeliveryMethod } from '../../services/types/visaInstant/deliveryMethod.type';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

const Row = (props: { deliveryMethods: DeliveryMethod[]; cardRequest: CardRequest; countries: Country[] }) => {
  const { cardRequest, deliveryMethods, countries } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation('visaInstantCardRequests');
  const country = countries.find(c => c.id === cardRequest.country_id);
  const deliveryMethod = deliveryMethods.find(d => d.id === cardRequest.delivery_method_id);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/visa-instant/card-requests/' + cardRequest.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {cardRequest.id}
        </TableCell>
        <TableCell>
          {cardRequest.user_id}
        </TableCell>
        <TableCell>
          {cardRequest.name}
        </TableCell>
        <TableCell>
          {country ? country.name : cardRequest.country_id}
        </TableCell>
        <TableCell>
          <Chip label={t(cardRequest.status)} color={['PROCESSING', 'NEW', 'SHIPPED'].includes(cardRequest.status) ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          {deliveryMethod ? deliveryMethod.name[i18n.language] : cardRequest.delivery_method_id}
        </TableCell>
        <TableCell>
          {moment(cardRequest.created_at).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {moment(cardRequest.updated_at ? cardRequest.updated_at : cardRequest.created_at).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const CardRequests = () => {
  const { t } = useTranslation(['visaInstantCardRequests']);
  const api = useVisaInstantApi();
  const history = useHistory();

  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [cardRequests, setCardRequests] = React.useState<CardRequest[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [deliveryMethods, setDeliveryMethods] = React.useState<DeliveryMethod[]>([]);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);

  const cardRequestStates = [
    CardRequestStatus.NEW,
    CardRequestStatus.PENDING,
    CardRequestStatus.CANCELLED,
    CardRequestStatus.PROCESSING,
    CardRequestStatus.SHIPPED
  ];

  const [selectedCardRequestStatus, setSelectedCardRequestStatus] = React.useState<CardRequestStatus|null>();
  const [userPhone, setUserPhone] = React.useState('');
  const [receiverName, setReceiverName] = React.useState('');

  const [page, setPage] = React.useState(0);

  const limit = 25;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { card_requests, count } = await api.listCardRequests({
        countryId: selectedCountry?.id,
        status: selectedCardRequestStatus?selectedCardRequestStatus:undefined,
        userPhone: userPhone.length > 0 ? userPhone : undefined,
        userName: receiverName.length > 0 ? receiverName : undefined,
      }, limit, page * limit);
      setCardRequests(card_requests);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
    });
    utils.runAsync(async () => {
      setDeliveryMethods(await api.getDeliveryMethods());
    });
  }, []);

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(0);
      loadData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedCountry,
    selectedCardRequestStatus,
    userPhone,
    receiverName
  ]);


  return (
    <Grid container spacing={2}>
      <Grid item container justify='flex-end' xs={12}>
        <Button variant='outlined' color='secondary' startIcon={<EmailIcon />} onClick={() => { history.push('/visa-instant/delivery-file') }}>
          {t('NEW_DELIVERY_FILE')}
        </Button>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              onChange={(event, value) => {
                setSelectedCardRequestStatus(value);
              }}
              value={selectedCardRequestStatus}
              options={cardRequestStates}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => t(option)}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_STATUS')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              onChange={(event, value) => {
                selectCountry(value);
              }}
              value={selectedCountry}
              options={countries}
              disabled={loading}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_COUNTRY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label={t('SEARCH_PHONE')}
              value={userPhone}
              disabled={loading}
              onChange={(e) => setUserPhone(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label={t('SEARCH_RECEIVER_NAME')}
              disabled={loading}
              value={receiverName}
              onChange={(e) => setReceiverName(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('ID')}</TableCell>
                      <TableCell>{t('USER_ID')}</TableCell>
                      <TableCell>{t('NAME')}</TableCell>
                      <TableCell>{t('COUNTRY')}</TableCell>
                      <TableCell>{t('STATUS')}</TableCell>
                      <TableCell>{t('DELIVERY_METHOD')}</TableCell>
                      <TableCell>{t('CREATED_AT')}</TableCell>
                      <TableCell>{t('UPDATED_AT')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cardRequests.map(c =>
                      <Row
                        countries={countries}
                        deliveryMethods={deliveryMethods}
                        cardRequest={c}
                        key={c.id}
                      />)}
                    {loading && cardRequests.length === 0 &&
                      <TableCell colSpan={9}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && cardRequests.length === 0 &&
                      <TableCell colSpan={9}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
