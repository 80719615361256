import React from 'react';
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useRevolusendApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import { Currency } from '../../services/types/revolusend/currency.type';

import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  })
);

export const Currencies = () => {
  const { t } = useTranslation(['revolusendCurrencies']);
  const api = useRevolusendApi();
  const [loading, setLoading] = React.useState(true);

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [filteredCurrencies, setFilteredCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<Currency | null>(null);

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setCurrencies(await api.listRevolusendCurrencies());
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(loadData, []);

  React.useEffect(() => {
    if (selectedCurrency) {
      setFilteredCurrencies(currencies.filter(c => c.id === selectedCurrency.id))
    } else {
      setFilteredCurrencies(currencies);
    }
  }, [selectedCurrency]);

  React.useEffect(() => {
    if (selectedCurrency) {
      setFilteredCurrencies(currencies.filter(c => c.id === selectedCurrency.id))
    } else {
      setFilteredCurrencies(currencies);
    }
  }, [currencies]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            onChange={(event, value) => {
              selectCurrency(value);
            }}
            value={selectedCurrency}
            options={currencies}
            autoHighlight
            getOptionLabel={(option) => option.iso}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option) => option.iso}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('SEARCH_CURRENCY')}
                variant='standard'
                fullWidth={true}
                InputProps={{
                  ...params.InputProps,
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('CURRENCY')}</TableCell>
                          <TableCell>{t('RATE')}</TableCell>
                          <TableCell>{t('UPDATED')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredCurrencies.map(c =>
                          <TableRow key={c.id}>
                            <TableCell>{c.iso}</TableCell>
                            <TableCell>{c.rate}</TableCell>
                            <TableCell>{moment(c.update_at).format('llll')}</TableCell>
                          </TableRow>
                        )}
                        {loading && currencies.length === 0 &&
                          <TableCell colSpan={3}>
                            <Box padding={2}>
                              <Grid container justify='center' item xs={12}>
                                <CircularProgress />
                              </Grid>
                            </Box>
                          </TableCell>
                        }
                        {!loading && currencies.length === 0 &&
                          <TableCell colSpan={3}>
                            <Box padding={2}>
                              <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                              <img className={classes.img} src={notFoundImg} />
                            </Box>
                          </TableCell>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid >
      </Grid>
    </>
  )
}
