export enum KycStatus {
  KYC_PENDING = 'KYC_PENDING',
  KYC_PROCESSING = 'KYC_PROCESSING',
  KYC_OK = 'KYC_OK',
  KYC_KO = 'KYC_KO',
}

export type RevolupayUser = {
  id: number;
  status: KycStatus;
  created: string;
  updated?: string;
}