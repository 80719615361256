import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDirectBankingMiddlewareApi } from '../../context';
import utils from '../../services/utils.service';
import { Attachments } from '../../components/attachments';
import moment from 'moment';
import DetailsIcon from '@material-ui/icons/Info';
import { RefundListItem } from '../../services/types/directBanking/refundListItem.type';
import { BatchDetails } from '../../services/types/directBanking/batchDetails.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  })
);

const Row = (props: { refund: RefundListItem, selected: boolean, toggle: (item: RefundListItem) => void }) => {
  const { refund: refund, selected, toggle } = props;
  const history = useHistory();
  const { t } = useTranslation(['directBankingBatches']);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {'ID: ' + refund.order.external_reference}
          <IconButton size='small' onClick={() => { history.push('/revolusend/transfers/' + refund.order.external_reference) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {'ID: ' + refund.order.id}
          <IconButton size='small' onClick={() => { history.push('/directbanking/orders/' + refund.order.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(refund.amount)} ${refund.order.currency_iso_code}`}
        </TableCell>
        <TableCell>
          {refund.payment.issuer_name}
        </TableCell>
        <TableCell>
          {refund.payment.issuer_bank_account}
        </TableCell>
        <TableCell>
          <Chip label={t(refund.status)} color={'primary'} />
        </TableCell>
        <TableCell>
          {moment(refund.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const DirectBankingBatchDetails = (props: RouteComponentProps<{ id: string }>) => {
  const batchId = props.match.params.id
  const { t, i18n } = useTranslation(['directBankingBatches', 'directBankingOrders']);
  const classes = useStyles();
  const api = useDirectBankingMiddlewareApi();
  const history = useHistory();

  moment.locale(i18n.language);

  const [batchDetails, setBatchDetails] = React.useState<BatchDetails | null>(null);
  const [loading, setLoading] = React.useState(true);

  const [loadingTransactions, setLoadingTransactions] = React.useState(true);
  const [refunds, setRefunds] = React.useState<RefundListItem[]>([]);
  const [refundStates, setRefundStates] = React.useState<{ [key: number]: boolean }>({});
  const [totalAmount, setTotalAmount] = React.useState(0);

  const loadRefunds = (refunds: RefundListItem[]) => {
    setLoadingTransactions(true);
    const count = refunds.length
    let amount = 0;
    for (const refund of refunds) {
      amount += refund.amount;
    }
    setTotalAmount(amount);
    setRefundStates(refunds.reduce((p, c) => {
      p[c.id] = c.status === 'PROCESSED';
      return p;
    }, {} as any))
    setRefunds(refunds);
    setLoadingTransactions(false);
  };

  const loadBatchDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const batchDetails = await api.getDirectBankingBatchDetails(batchId);
      setBatchDetails(batchDetails);
      loadRefunds(batchDetails.refunds);
      setLoading(false);
    }, (e) => {
      if (e) {
        history.push('/directbanking/batches');
      }
    });
  }

  React.useEffect(() => {
    loadBatchDetails();
  }, []);

  if (loading && batchDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  const selectedTransactions: number[] = [];
  for (const transactionId in refundStates) {
    if (refundStates[transactionId]) {
      selectedTransactions.push(Number(transactionId));
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('BATCH')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CONSUMER')} secondary={batchDetails?.consumer.name} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TOTAL_TRANSACTIONS')} secondary={batchDetails?.batch.count} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={t('TOTAL_AMOUNT')}
                          secondary={
                            `${utils.currencyFormat(totalAmount)} EUR`
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CREATED')} secondary={moment(batchDetails?.batch.created).format('lll')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={t('PROCESSED')}
                          secondary={
                            batchDetails?.batch.processed ? moment(batchDetails?.batch.processed).format('lll') : t('NOT_YET')
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12}>

                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button component={'a'} href={batchDetails?.document.url} target='_blank'>
                  {t('DOWNLOAD_BATCH_FILE')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Attachments entity='directBankingBatch' id={batchId} />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography variant='h5'>{t('REFUNDS')}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('directBankingOrders:TRANSACTION')}</TableCell>
                          <TableCell>{t('directBankingOrders:REFUND')}</TableCell>
                          <TableCell>{t('directBankingOrders:AMOUNT')}</TableCell>
                          <TableCell>{t('directBankingOrders:ISSUER')}</TableCell>
                          <TableCell>{t('directBankingOrders:IBAN')}</TableCell>
                          <TableCell>{t('directBankingOrders:STATUS')}</TableCell>
                          <TableCell>{t('directBankingOrders:CREATED')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {refunds.map(t =>
                          <Row
                            toggle={t => setRefundStates({ ...refundStates, [t.id]: !refundStates[t.id] })}
                            selected={refundStates[t.id]}
                            refund={t}
                            key={t.id}
                          />)}
                        {loadingTransactions && refunds.length === 0 &&
                          <TableCell colSpan={10}>
                            <Box padding={2}>
                              <Grid container justify='center' item xs={12}>
                                <CircularProgress />
                              </Grid>
                            </Box>
                          </TableCell>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}