import React from 'react';
import {
    Box,
    Chip,
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useVisaInstantApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import DetailsIcon from '@material-ui/icons/CreditCard';
import { useHistory } from 'react-router-dom';
import { Card } from '../../services/types/visaInstant/card.type';
import { Currency } from '../../services/types/visaInstant/currency.type';
import moment from 'moment';
import { Transaction } from '../../services/types/visaInstant/transaction.type';
import { TransactionListItem } from '../../services/types/visaInstant/transactionListItem.type';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            width: 400,
            maxWidth: '100%',
            display: 'block',
            margin: '0 auto',
        },
        noBottomBorder: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
    })
);

const Row = (
    props: {
        embedded: boolean;
        transaction: Transaction;
        card: Card;
        currencies: Currency[];
    }) => {
    const { transaction, currencies, card, embedded } = props;
    const history = useHistory();
    const { t, i18n } = useTranslation('visaInstantCards');
    const currency = currencies.find(c => c.id === transaction.currency_id);
    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <Grid container spacing={2}>
                        {!embedded &&
                            <Grid item xs={12} md={6}>
                                <IconButton size='small' onClick={() => { history.push('/visa-instant/cards/' + card.id) }}>
                                    <DetailsIcon />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                </TableCell>
                <TableCell>{transaction.id}</TableCell>
                {!embedded &&
                    <TableCell>
                        {`${card.last4} - MP ${card.mp}`}
                    </TableCell>
                }
                <TableCell>
                    {utils.getStringOrDefault(transaction.external_id, '-')}
                </TableCell>
                <TableCell>
                    {transaction.description}
                </TableCell>
                <TableCell>
                    {`${utils.currencyFormat(transaction.amount)} ${currency!.iso}`}
                </TableCell>
                <TableCell>
                    {transaction.exchange_rate}
                </TableCell>
                <TableCell>
                    {`${utils.currencyFormat(transaction.amount * transaction.exchange_rate)} EUR`}
                </TableCell>
                <TableCell>
                    <Chip label={transaction.valid ? t('YES') : t('NO')} color={transaction.valid ? 'primary' : 'default'} />
                </TableCell>
                <TableCell>
                    {moment(transaction.created).format('lll')}
                </TableCell>
                <TableCell>
                    {transaction.modified ? moment(transaction.modified).format('lll') : '-'}
                </TableCell>
                <TableCell>
                    {utils.getStringOrDefault(transaction.file_name, '-')}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export const Transactions = ({ embedded = false, cardId }: { embedded: boolean; cardId?: number }) => {
    const { t } = useTranslation(['visaInstantCards']);
    const api = useVisaInstantApi();

    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const [transactions, setTransactions] = React.useState<TransactionListItem[]>([]);
    const [currencies, setCurrencies] = React.useState<Currency[]>([]);

    const colSpan = embedded ? 11 : 12;

    const [page, setPage] = React.useState(0);
    const limit = 25;

    const classes = useStyles();

    const loadData = () => {
        utils.runAsync(async () => {
            setLoading(true);
            const { transactions, count } = await api.listTransactions({
                cardId
            }, limit, page * limit);
            setCurrencies(await api.getCurrencies());
            setTransactions(transactions);
            setCount(count);
        }, () => {
            setLoading(false);
        })
    }

    React.useEffect(loadData, [page]);

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container>
                            {embedded &&
                                <Grid item xs={12}>
                                    <Box padding={2}>
                                        <Typography variant='h5'>
                                            {t('TRANSACTIONS')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>{t('ID')}</TableCell>
                                                {!embedded &&
                                                    <TableCell>{t('CARD')}</TableCell>
                                                }
                                                <TableCell>{t('EXTERNAL_ID')}</TableCell>
                                                <TableCell>{t('DESCRIPTION')}</TableCell>
                                                <TableCell>{t('AMOUNT')}</TableCell>
                                                <TableCell>{t('EXCHANGE_RATE')}</TableCell>
                                                <TableCell>{t('AMOUNT_IN_EUR')}</TableCell>
                                                <TableCell>{t('VALID')}</TableCell>
                                                <TableCell>{t('CREATED')}</TableCell>
                                                <TableCell>{t('UPDATED')}</TableCell>
                                                <TableCell>{t('FILE_NAME')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {transactions.map(t =>
                                                <Row
                                                    embedded={embedded}
                                                    transaction={t.transaction}
                                                    card={t.card}
                                                    currencies={currencies}
                                                    key={t.transaction.id}
                                                />)}
                                            {loading && transactions.length === 0 &&
                                                <TableCell colSpan={colSpan}>
                                                    <Box padding={2}>
                                                        <Grid container justify='center' item xs={12}>
                                                            <CircularProgress />
                                                        </Grid>
                                                    </Box>
                                                </TableCell>
                                            }
                                            {!loading && transactions.length === 0 &&
                                                <TableCell colSpan={colSpan}>
                                                    <Box padding={2}>
                                                        <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                                                        <img className={classes.img} src={notFoundImg} />
                                                    </Box>
                                                </TableCell>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[limit]}
                                    count={count}
                                    rowsPerPage={limit}
                                    page={page}
                                    onChangePage={(event, page) => { setPage(page) }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
