import React from 'react';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import logo from '../assets/img/logo.png'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  img: {
    width: '500px',
    maxWidth: '90%'
  },
}));

export const Preloader = () => {

  const style = useStyles();

  return (
    <main className={style.main}>
      <img className={style.img} src={logo} />
      <CircularProgress color='secondary' />
    </main>
  )

}