import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import { loadCSS } from 'fg-loadcss';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDirectBankingMiddlewareApi, useRevolusendApi } from '../context';
import { Attachment } from '../services/types/revolusend/attachment.type';
import utils from '../services/utils.service';
import filesize from 'filesize';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);

const UploadModal = ({
  id,
  entity,
  show,
  onClose,
}: {
  id: string,
  entity: string,
  show: boolean,
  onClose: () => void
}) => {
  const theme = useTheme();
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[400],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.getContrastText(theme.palette.grey[200]),
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = { borderColor: theme.palette.secondary.main };

  const { t } = useTranslation(['revolusendAttachments']);
  const revolusendApi = useRevolusendApi();
  const directBankingApi = useDirectBankingMiddlewareApi();

  const [loading, setLoading] = React.useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setLoading(true);
    const promises: Promise<void>[] = [];
    for (const file of acceptedFiles) {
      promises.push(new Promise((resolve, reject) => {
        if (entity === 'batch') {
          revolusendApi.getRevolusendUploadUrlForBatchAttament(id, file.name, file.type).then((url) => {
            fetch(
              url,
              {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: file
              }
            )
              .then(() => resolve())
              .catch(reject);
          }).catch(reject);
        } else if (entity === 'transaction') {
          revolusendApi.getRevolusendUploadUrlForTransactionAttament(id, file.name, file.type).then((url) => {
            fetch(
              url,
              {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: file
              }
            )
              .then(() => resolve())
              .catch(reject);
          }).catch(reject);

        } else {
          directBankingApi.getDirectBankingUploadUrlForAttachment(id, file.name, file.type).then((url) => {
            fetch(
              url,
              {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: file
              }
            )
              .then(() => resolve())
              .catch(reject);
          }).catch(reject);
        }
      }));
    }
    Promise.all(promises).then(() => {
      setLoading(false);
      onClose();
    }).catch(() => setLoading(false));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop, maxSize: 25600000 });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
  }), [isDragActive]);
  return (
    <Dialog fullWidth open={show} onClose={onClose}>
      <DialogTitle>{t('UPLOAD_MODAL')}</DialogTitle>
      <DialogContent>
        <div {...getRootProps({ style })}>
          <input {...getInputProps({ disabled: loading })} />
          {
            loading ? <p>{t('LOADING')}</p> : isDragActive ? <p>{t('DROP_ACTIVE')}</p> : <p>{t('DROP_INACTIVE')}</p>
          }
          {loading &&
            <CircularProgress />
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('DONE')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export const Attachments = ({ entity, id }: { entity: 'transaction' | 'batch' | 'directBankingBatch', id: string }) => {
  const { t, i18n } = useTranslation(['revolusendAttachments']);
  moment.locale(i18n.language);
  const revolusendApi = useRevolusendApi();
  const directBankingApi = useDirectBankingMiddlewareApi();
  const [loadingAttachments, setLoadingAttachments] = React.useState(true);
  const [attachments, setAttachments] = React.useState<Attachment[]>([]);
  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const classes = useStyles();


  const loadAttachments = () => {
    utils.runAsync(async () => {
      setLoadingAttachments(true);
      let unsortedAttachments;
      if (entity === 'transaction') {
        unsortedAttachments = await revolusendApi.getRevolusendTransactionAttachments(id);
      } else if (entity === 'batch') {
        unsortedAttachments = await revolusendApi.getRevolusendBatchAttachments(id);
      } else {
        unsortedAttachments = await directBankingApi.getDirectBankingAttachments(id);
      }
      setAttachments(unsortedAttachments.sort((a, b) => moment(b.last_modified).unix() - moment(a.last_modified).unix()));

    }, () => {
      setLoadingAttachments(false);
    })
  }

  React.useEffect(() => {
    loadAttachments();
    const node = loadCSS('https://use.fontawesome.com/releases/v5.12.0/css/all.css', document.querySelector('#font-awesome-css'));
    return () => { node.parentNode!.removeChild(node) };
  }, [entity, id]);

  return (
    <>
      <UploadModal
        id={id}
        entity={entity}
        show={showUploadModal}
        onClose={() => {
          setShowUploadModal(false);
          loadAttachments();
        }} />
      <Card>
        <CardHeader title={t('ATTACHMENTS')} />
        <CardContent>
          {loadingAttachments &&
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          }
          <List dense>
            {attachments.map(a => (
              <ListItem key={a.url}>
                <ListItemIcon>
                  <Icon className={`fa ${utils.getFileIcon(a.content_type)}`} color='secondary' fontSize='large' />
                </ListItemIcon>
                <ListItemText
                  primary={a.path.split('/').pop()}
                  secondary={`${filesize(a.size)} - ${moment(a.last_modified).format('lll')}`}
                />
                <ListItemSecondaryAction>
                  <Link href={a.url} target='_blank'>
                    <IconButton edge='end' aria-label='download'>
                      <CloudDownloadIcon color='primary' />
                    </IconButton>
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={() => setShowUploadModal(true)}>
            {t('UPLOAD_ATTACHMENT')}
          </Button>
        </CardActions>
      </Card>

    </>

  )
}