import React from 'react';
import {
    Box,
    Chip,
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useVisaInstantApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import { Card } from '../../services/types/visaInstant/card.type';
import { CardStatus } from '../../services/types/visaInstant/cardStatus.enum';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            width: 400,
            maxWidth: '100%',
            display: 'block',
            margin: '0 auto',
        },
        noBottomBorder: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
    })
);

const Row = (props: { card: Card; }) => {
    const { card } = props;
    const history = useHistory();
    const { t, i18n } = useTranslation('visaInstantCards');
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton size='small' onClick={() => { history.push('/visa-instant/cards/' + card.id) }}>
                        <DetailsIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {card.id}
                </TableCell>
                <TableCell>
                    {card.last4}
                </TableCell>
                <TableCell>
                    {card.mp}
                </TableCell>
                <TableCell>
                    {card.expiry ? card.expiry : '-'}
                </TableCell>
                <TableCell>
                    {card.user_id}
                </TableCell>
                <TableCell>
                    <Chip label={t(card.status)} color={['ACTIVE'].includes(card.status) ? 'primary' : 'default'} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export const Cards = () => {
    const { t } = useTranslation(['visaInstantCards']);
    const api = useVisaInstantApi();

    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const [cards, setCards] = React.useState<Card[]>([]);

    const cardStates = [
        CardStatus.ACTIVE,
        CardStatus.BLOCKED,
        CardStatus.CANCELLED
    ];

    const [selectedCardStatus, setSelectedCardStatus] = React.useState<CardStatus | null>();
    const [userPhone, setUserPhone] = React.useState('');
    const [cardMp, setCardMp] = React.useState('');
    const [cardLast4, setCardLast4] = React.useState('');

    const [page, setPage] = React.useState(0);

    const limit = 25;

    const classes = useStyles();

    const loadData = () => {
        utils.runAsync(async () => {
            setLoading(true);
            const { cards, count } = await api.listCards({
                status: selectedCardStatus ? selectedCardStatus : undefined,
                userPhone: userPhone.length > 0 ? userPhone : undefined,
                mp: cardMp.length > 0 ? cardMp : undefined,
                last4: cardLast4.length > 0 ? cardLast4 : undefined,
            }, limit, page * limit);
            setCards(cards);
            setCount(count);
        }, () => {
            setLoading(false);
        })
    }

    React.useEffect(loadData, [page]);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setPage(0);
            loadData();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [
        selectedCardStatus,
        userPhone,
        cardMp,
        cardLast4
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12}>
                <Grid item container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            onChange={(event, value) => {
                                setSelectedCardStatus(value);
                            }}
                            value={selectedCardStatus}
                            options={cardStates}
                            autoHighlight
                            disabled={loading}
                            getOptionLabel={(option) => t(option)}
                            getOptionSelected={(option, value) => option === value}
                            renderOption={(option) => t(option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('SEARCH_STATUS')}
                                    variant='standard'
                                    fullWidth={true}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label={t('SEARCH_PHONE')}
                            value={userPhone}
                            disabled={loading}
                            onChange={(e) => setUserPhone(e.target.value)}
                            variant='standard'
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label={t('SEARCH_MP')}
                            value={cardMp}
                            disabled={loading}
                            onChange={(e) => setCardMp(e.target.value)}
                            variant='standard'
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label={t('SEARCH_LAST4')}
                            value={cardLast4}
                            disabled={loading}
                            onChange={(e) => setCardLast4(e.target.value)}
                            variant='standard'
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Paper>
                    <Grid container>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>{t('ID')}</TableCell>
                                            <TableCell>{t('LAST4')}</TableCell>
                                            <TableCell>{t('MP')}</TableCell>
                                            <TableCell>{t('EXPIRY')}</TableCell>
                                            <TableCell>{t('USER_ID')}</TableCell>
                                            <TableCell>{t('STATUS')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cards.map(c =>
                                            <Row
                                                card={c}
                                                key={c.id}
                                            />)}
                                        {loading && cards.length === 0 &&
                                            <TableCell colSpan={7}>
                                                <Box padding={2}>
                                                    <Grid container justify='center' item xs={12}>
                                                        <CircularProgress />
                                                    </Grid>
                                                </Box>
                                            </TableCell>
                                        }
                                        {!loading && cards.length === 0 &&
                                            <TableCell colSpan={7}>
                                                <Box padding={2}>
                                                    <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                                                    <img className={classes.img} src={notFoundImg} />
                                                </Box>
                                            </TableCell>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[limit]}
                                count={count}
                                rowsPerPage={limit}
                                page={page}
                                onChangePage={(event, page) => { setPage(page) }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid >
    )
}
