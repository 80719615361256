import React from 'react';
import { useRevoluexApi } from '../../context';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemText, makeStyles, Menu, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import moment from 'moment';
import { Profile } from '../../services/types/revolusend/profile.type';
import { LoadingButton } from '../../components/loadingButton';
import { PromotionType } from '../../services/types/revolusend/promotion.type';
import { Attachments } from '../../components/attachments';
import { TopupDetails as TopupDetailsType } from '../../services/types/revoluex/topupDetails.type';
import { TopupStatus } from '../../services/types/revoluex/topup.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
  })
);


const ApproveModal = (props: { topupId?: number, open: boolean, onClose: () => void }) => {
  const { open, onClose, topupId } = props;
  const api = useRevoluexApi();
  const { t } = useTranslation('revoluexTopups');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const approve = () => {
    if (!topupId) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.approveTopup(topupId);
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }


  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('APPROVE_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('APPROVE_MODAL_DESCRIPTION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={approve}>
          {t('APPROVE_BUTTON_TITLE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

export const TopupDetails = (props: RouteComponentProps<{ id: string }>) => {
  const topupId = props.match.params.id
  const { t } = useTranslation('revoluexTopups');
  const classes = useStyles();
  const api = useRevoluexApi();
  const history = useHistory();

  const [topupDetails, setTopupDetails] = React.useState<TopupDetailsType | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [showApproveModal, setShowApproveModal] = React.useState(false);


  const loadTopupDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setTopupDetails(await api.getTopupDetails(parseInt(topupId, 10)));
      setLoading(false);
    }, (e) => {
      if (e) {
        history.push('/revoluex/topups');
      }
    });
  }


  React.useEffect(() => {
    loadTopupDetails();
  }, []);

  if (loading && topupDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }


  return (
    <>
      <ApproveModal
        topupId={topupDetails?.topup.id}
        open={showApproveModal}
        onClose={() => {
          setShowApproveModal(false);
          loadTopupDetails();
        }} />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('TOPUP')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('ID')} secondary={topupDetails?.topup.id} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('SUB_USER_ID')} secondary={topupDetails?.topup.sub_user_id} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('NAME')} secondary={topupDetails?.revolupay_user.revolupay_user_name} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('EMAIL')} secondary={topupDetails?.revolupay_user.revolupay_user_email} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CRYPTOCURRENCY')} secondary={topupDetails?.topup.crypto_currency_code.toUpperCase()} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CRYPTOCHAIN')} secondary={topupDetails?.topup.crypto_chain_code.toUpperCase()} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('REQUESTED_AMOUNT')} secondary={`${topupDetails?.topup.crypto_amount} ${topupDetails?.topup.crypto_currency_code.toUpperCase()}`} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DEPOSIT_AMOUNT')} secondary={topupDetails?.deposit?.amount ? `${topupDetails?.deposit?.amount} ${topupDetails.topup.crypto_currency_code.toUpperCase()}` : '-'} />
                      </ListItem>

                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TRANSFER_AMOUNT')} secondary={topupDetails?.topup.transfer_amount ? `${utils.currencyFormat(topupDetails.topup.transfer_amount)} €UR` : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('FEE')} secondary={topupDetails?.topup.fee ? `${utils.currencyFormat(topupDetails.topup.fee)} €UR` : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('STATUS')} secondary={topupDetails?.topup.status} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CREATED')} secondary={moment(topupDetails?.topup.created).format('lll')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('UPDATED')} secondary={topupDetails?.topup.updated ? moment(topupDetails?.topup.updated).format('lll') : '-'} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TXHASH')} secondary={utils.getStringOrDefault(topupDetails?.deposit?.tx_hash, '-')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('DEPOSIT_RECEIVED_AT')} secondary={topupDetails?.deposit?.created ? moment(topupDetails?.deposit?.created).format('lll') : '-'} />
                      </ListItem>
                    </List>
                  </Grid>

                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button disabled={topupDetails?.topup.status !== TopupStatus.CONFIRMED} color='primary' onClick={() => setShowApproveModal(true)}>
                  {t('APPROVE')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('ORDER')} />
              <CardContent>
                <List dense disablePadding>
                  <ListItem disableGutters>
                    <ListItemText primary={t('EXCHANGE_ID')} secondary={utils.getStringOrDefault(topupDetails?.order?.external_id, '-')} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('STATUS')} secondary={utils.getStringOrDefault(topupDetails?.order?.status, '-')} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('CRYPTO_AMOUNT')} secondary={topupDetails?.order?.amount_crypto ? `${topupDetails?.order?.amount_crypto} ${topupDetails.topup.crypto_currency_code.toUpperCase()}` : '-'} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('CONVERTED_AMOUNT')} secondary={topupDetails?.order?.converted_amount ? `${utils.currencyFormat(topupDetails.order.converted_amount)} €UR (${topupDetails.order.amount_stable_coin} ${topupDetails.order.stable_coin})` : '-'} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('FEE')} secondary={topupDetails?.order?.fee ? `${topupDetails.order.fee} ${topupDetails.order.stable_coin}` : '-'} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('CREATED')} secondary={topupDetails?.order?.created ? moment(topupDetails?.order.created).format('lll') : '-'} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={t('UPDATED')} secondary={topupDetails?.order?.updated ? moment(topupDetails?.order.updated).format('lll') : '-'} />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}