import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useVisaInstantApi } from '../../context';
import utils from '../../services/utils.service';
import moment from 'moment';
import DetailsIcon from '@material-ui/icons/Info';
import { LoadingButton } from '../../components/loadingButton';
import { Autocomplete } from '@material-ui/lab';
import { AlertDialog } from '../../components/alertDialog';
import { CardRequest } from '../../services/types/visaInstant/cardRequest.type';
import { CardRequestStatus } from '../../services/types/visaInstant/cardRequestStatus.enum';
import { DeliveryMethod } from '../../services/types/visaInstant/deliveryMethod.type';
import { Country } from '../../services/types/visaInstant/country.type';


const Row = (props: {
  countries: Country[],
  deliveryMethods: DeliveryMethod[],
  cardRequest: CardRequest,
  selected: boolean,
  toggle: (item: CardRequest) => void
}) => {
  const {
    cardRequest,
    selected,
    toggle,
    countries,
    deliveryMethods
  } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation('visaInstantCardRequests');
  const country = countries.find(c => c.id === cardRequest.country_id);
  const deliveryMethod = deliveryMethods.find(d => d.id === cardRequest.delivery_method_id);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={selected}
            onChange={() => toggle(cardRequest)}
            color='secondary'
          />
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/visa-instant/card-requests/' + cardRequest.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {cardRequest.id}
        </TableCell>
        <TableCell>
          {cardRequest.user_id}
        </TableCell>
        <TableCell>
          {cardRequest.name}
        </TableCell>
        <TableCell>
          {country ? country.name : cardRequest.country_id}
        </TableCell>
        <TableCell>
          <Chip label={t(cardRequest.status)} color={['PROCESSING', 'NEW', 'SHIPPED'].includes(cardRequest.status) ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          {deliveryMethod ? deliveryMethod.name[i18n.language] : cardRequest.delivery_method_id}
        </TableCell>
        <TableCell>
          {moment(cardRequest.created_at).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {moment(cardRequest.updated_at ? cardRequest.updated_at : cardRequest.created_at).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const DeliveryFile = () => {
  const { t, i18n } = useTranslation(['visaInstantCardRequests']);
  const api = useVisaInstantApi();
  const history = useHistory();

  moment.locale(i18n.language);

  const [loading, setLoading] = React.useState(true);
  const [loadingCardRequests, setLoadingCardRequests] = React.useState(false);
  const [cardRequestStates, setCardRequestStates] = React.useState<{ [key: number]: boolean }>({});
  const [cardRequests, setCardRequests] = React.useState<CardRequest[]>([]);

  const [deliveryMethods, setDeliveryMethods] = React.useState<DeliveryMethod[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedDeliveryMehtod, setSelectedDeliveryMethod] = React.useState<DeliveryMethod | null>(null);

  const [submitError, setSubmitError] = React.useState(false);

  const buildDeliveryFile = () => {

    const selectedCardRequestIds: number[] = [];
    for (const cardRequestId in cardRequestStates) {
      if (cardRequestStates[cardRequestId]) {
        selectedCardRequestIds.push(Number(cardRequestId));
      }
    }

    utils.runAsync(async () => {
      setLoading(true);
      const downloadUrl = await api.buildShippingFile(selectedCardRequestIds);
      window.location.href = downloadUrl;
    }, (e) => {
      setLoading(false);
      if (e) {
        setSubmitError(true);
      }
    });
  }

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setDeliveryMethods(await api.getDeliveryMethods());
      setCountries(await api.getCountries());
    }, () => {
      setLoading(false);
    })
  }

  const loadCardRequests = () => {
    utils.runAsync(async () => {
      setLoadingCardRequests(true);
      let count = 0;
      let pCardRequests: CardRequest[] = [];
      do {
        const { card_requests: crs, count: cnt } = await api.listCardRequests({
          status: CardRequestStatus.PROCESSING,
          deliveryMethodId: selectedDeliveryMehtod?.id,
        }, 50, pCardRequests.length);
        count = cnt;
        pCardRequests.push(...crs);
      } while (pCardRequests.length < count);
      setCardRequests(pCardRequests);
      setCardRequestStates(pCardRequests.reduce((p, c) => {
        p[c.id] = true;
        return p;
      }, {} as any))

    }, () => {
      setLoadingCardRequests(false);
    })
  }

  React.useEffect(() => {
    loadData();
  }, [])

  React.useEffect(() => {
    if (selectedDeliveryMehtod !== null) {
      loadCardRequests();
    }

  }, [selectedDeliveryMehtod]);


  return (
    <>
      <AlertDialog
        title={t('ERROR_CREATING_DELIVERY_FILE_TITLE')}
        message={t('ERROR_CREATING_DELIVERY_FILE_MESSAGE')}
        open={submitError}
        onClose={() => setSubmitError(false)}
      />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>{t('CREATE_NEW_DELIVERY_FILE')}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        <Typography gutterBottom>
                          {t('CREATE_NEW_DELIVERY_FILE_HELPTEXT')}
                        </Typography>
                      </Grid>

                      <Grid item container spacing={2} style={{ marginTop: 4 }}>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            onChange={(event, value) => {
                              setSelectedDeliveryMethod(value);
                            }}
                            value={selectedDeliveryMehtod}
                            options={deliveryMethods}
                            disabled={loading}
                            autoHighlight
                            getOptionLabel={(option) => option.name[i18n.language]}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option) => option.name[i18n.language]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('SELECT_DELIVERY_METHOD')}
                                variant='standard'
                                fullWidth={true}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                              />
                            )} />
                        </Grid>
                      </Grid>


                    </Grid>
                  </Box>
                </Grid>
                {(!loadingCardRequests && selectedDeliveryMehtod) &&
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Box paddingY={3} paddingX={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant='h6'>{t('CARD_REQUESTS')}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {cardRequests.length > 0 &&
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>{t('ID')}</TableCell>
                              <TableCell>{t('USER_ID')}</TableCell>
                              <TableCell>{t('NAME')}</TableCell>
                              <TableCell>{t('COUNTRY')}</TableCell>
                              <TableCell>{t('STATUS')}</TableCell>
                              <TableCell>{t('DELIVERY_METHOD')}</TableCell>
                              <TableCell>{t('CREATED_AT')}</TableCell>
                              <TableCell>{t('UPDATED_AT')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cardRequests.map(cr =>
                              <Row
                                toggle={c => setCardRequestStates({ ...cardRequestStates, [c.id]: !cardRequestStates[c.id] })}
                                selected={cardRequestStates[cr.id]}
                                cardRequest={cr}
                                countries={countries}
                                deliveryMethods={deliveryMethods}
                                key={cr.id}
                              />)}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    }
                    {cardRequests.length === 0 &&
                      <Grid item xs={12} style={{ padding: 20 }}>
                        <Grid container justify='center' item xs={12}>
                          <Typography variant='h5'>
                            {t('NO_PENDING_SHIPMENT_CARD_REQUESTS')}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                }
                {loadingCardRequests &&
                  <Grid item xs={12} style={{ padding: 20 }}>
                    <Grid container justify='center' item xs={12}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                }

                <Grid item container justify='flex-end' xs={12}>
                  <Box marginTop={6} paddingY={1} paddingX={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <LoadingButton loading={loading} disabled={loadingCardRequests || !selectedDeliveryMehtod || loading} variant='contained' color='primary' onClick={() => { buildDeliveryFile(); }}>
                          {t('CREATE')}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}