import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation, Redirect
} from 'react-router-dom';
import { Layout } from './layout/layout';
import { useAuth, useContext } from './context';
import { Login } from './login/login';
import { Logout } from './login/logout';
import { Transfers } from './revolusend/transfers/transfers';
import { Products } from './revolusend/products/products';
import { Preloader } from './components/preloader';
import { TransferDetails } from './revolusend/transfers/transferDetails';
import { Promos } from './revolusend/promos/promos';
import { Currencies } from './revolusend/currencies/currencies';
import { Batches } from './revolusend/batches/batches';
import { BatchDetails } from './revolusend/batches/batchDetails';
import { TopupDetails } from './revoluex/topups/topupDetails';
import { DepositDetails } from './revoluex/deposits/depositDetails';
import { Topups } from './revoluex/topups/topups';
import { Topups as VisaInstantTopups } from './visaInstant/topups/topups';
import { Transactions as VisaInstantTransactions } from './visaInstant/transactions/transactions';
import { Deposits } from './revoluex/deposits/deposits';
import { Chains } from './revoluex/chains/chains';
import { Config } from './revoluex/config/config';
import { Tenants } from './revolusend/tenants/tenants';
import { Reports } from './revolusend/reports/reports';
import { Users } from './revolusend/users/users';
import { UserDetails } from './revolusend/users/userDetails';
import { NewBatch } from './revolusend/batches/newBatch';
import { CardRequests } from './visaInstant/cardRequests/cardRequests';
import { CardRequestDetails } from './visaInstant/cardRequests/cardRequestDetails';
import { Cards } from './visaInstant/cards/cards';
import { CardDetails } from './visaInstant/cards/cardDetails';
import { DeliveryFile } from './visaInstant/cardRequests/deliveryFile';
import { B2BUsers } from './revolusend/users/b2bUsers';
import { Config as RevolusendConfig } from './revolusend/config/config';
import { Orders } from './directBanking/orders/orders';
import { DirectBankingBatches } from './directBanking/batches/directBankingBatches';
import { OrdersDetails } from './directBanking/orders/ordersDetails';
import { DirectBankingNewBatch } from './directBanking/batches/directBankingNewBatch';
import { DirectBankingBatchDetails } from './directBanking/batches/directBankingBatchDetails';

const PrivateRoute = (
  { name, component: Component, ...rest }:
    { name: string, component: any, exact?: boolean, path: string[] | string }
) => {
  const context = useContext();
  return (
    <DomRoute
      {...rest}
      render={(props) => {
        if (context.data.isSignedIn) {
          return (<Layout name={name}><Component {...props} /></Layout>)
        } else {
          return <Redirect to='/login' />
        }
      }}
    />
  )
}

const PublicRoute = (
  { component: Component, ...rest }:
    { component: any, exact?: boolean, path: string[] | string }
) => {
  return (
    <DomRoute
      {...rest}
      render={(props) => {
        return (<Component {...props} />)
      }}
    />
  )
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export const Router = () => {
  const auth = useAuth();
  const context = useContext();
  React.useEffect(() => {
    auth.attemptCookieLogin();
  }, []);
  if (context.data.initializing) {
    return (<Preloader />);
  } else {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <PublicRoute exact path='/login' component={Login} />
          <PrivateRoute exact name={'LOGOUT'} path='/logout' component={Logout} />
          <PrivateRoute exact name={'TRANSFERS'} path='/revolusend/transfers' component={Transfers} />
          <PrivateRoute exact name={'PRODUCTS'} path='/revolusend/products' component={Products} />
          <PrivateRoute exact name={'TRANSFER_DETAILS'} path='/revolusend/transfers/:id' component={TransferDetails} />
          <PrivateRoute exact name={'PROMOS'} path='/revolusend/promos' component={Promos} />
          <PrivateRoute exact name={'CURRENCIES'} path='/revolusend/currencies' component={Currencies} />
          <PrivateRoute exact name={'BATCHES'} path='/revolusend/batches' component={Batches} />
          <PrivateRoute exact name={'BATCH_DETAILS'} path='/revolusend/batches/:id' component={BatchDetails} />
          <PrivateRoute exact name={'TENANTS'} path='/revolusend/tenants' component={Tenants} />
          <PrivateRoute exact name={'REPORTS'} path='/revolusend/reports' component={Reports} />
          <PrivateRoute exact name={'USERS'} path='/revolusend/users' component={Users} />
          <PrivateRoute exact name={'USER_DETAILS'} path='/revolusend/users/:id' component={UserDetails} />
          <PrivateRoute exact name={'NEW_BATCH'} path='/revolusend/newbatch' component={NewBatch} />
          <PrivateRoute exact name={'B2B_USERS'} path='/revolusend/b2b-users' component={B2BUsers} />
          <PrivateRoute exact name={'SETTINGS'} path='/revolusend/settings' component={RevolusendConfig} />

          <PrivateRoute exact name={'CHAINS'} path='/revoluex/chains' component={Chains} />
          <PrivateRoute exact name={'TOPUPS'} path='/revoluex/topups' component={Topups} />
          <PrivateRoute exact name={'DEPOSITS'} path='/revoluex/deposits' component={Deposits} />
          <PrivateRoute exact name={'TOPUP_DETAILS'} path='/revoluex/topups/:id' component={TopupDetails} />
          <PrivateRoute exact name={'DEPOSIT_DETAILS'} path='/revoluex/deposits/:id' component={DepositDetails} />
          <PrivateRoute exact name={'CONFIG'} path='/revoluex/config' component={Config} />

          <PrivateRoute exact name={'CARD_REQUESTS'} path='/visa-instant/card-requests' component={CardRequests} />
          <PrivateRoute exact name={'CARD_REQUESTS'} path='/visa-instant/card-requests/:id' component={CardRequestDetails} />
          <PrivateRoute exact name={'CARDS'} path='/visa-instant/cards' component={Cards} />
          <PrivateRoute exact name={'CARDS'} path='/visa-instant/cards/:id' component={CardDetails} />
          <PrivateRoute exact name={'CARD_TOPUPS'} path='/visa-instant/topups' component={VisaInstantTopups} />
          <PrivateRoute exact name={'CARD_TRANSACTIONS'} path='/visa-instant/transactions' component={VisaInstantTransactions} />
          <PrivateRoute exact name={'DELIVERY_FILE'} path='/visa-instant/delivery-file' component={DeliveryFile} />

          <PrivateRoute exact name={'ORDERS'} path='/directbanking/orders' component={Orders} />
          <PrivateRoute exact name={'ORDER_DETAILS'} path='/directbanking/orders/:id' component={OrdersDetails} />
          <PrivateRoute exact name={'BATCHES'} path='/directbanking/batches' component={DirectBankingBatches} />
          <PrivateRoute exact name={'NEW_BATCH'} path='/directbanking/newbatch' component={DirectBankingNewBatch} />
          <PrivateRoute exact name={'BATCH_DETAILS'} path='/directbanking/batches/:id' component={DirectBankingBatchDetails} />

          <Redirect to='/revolusend/transfers' />
        </Switch>
      </BrowserRouter>
    );
  }
}
