import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useRevolusendApi } from '../../context';
import utils from '../../services/utils.service';
import { BatchDetails as BatchDetailsAlias } from '../../services/types/revolusend/batchDetails.type';
import { Attachments } from '../../components/attachments';
import moment from 'moment';
import { TransactionListItem } from '../../services/types/revolusend/transactionListItem.type';
import DetailsIcon from '@material-ui/icons/Info';
import { LoadingButton } from '../../components/loadingButton';
import { TransactionTypeCode } from '../../services/types/revolusend/transactionType';
import { TransactionStatus } from '../../services/types/revolusend/transactionStatus.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      justifyContent: 'flex-end'
    },
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  })
);

const Row = (props: { transaction: TransactionListItem, selected: boolean, toggle: (item: TransactionListItem) => void }) => {
  const { transaction, selected, toggle } = props;
  const history = useHistory();
  const { t } = useTranslation(['revolusendTransfers', 'revolusendDeliveryMethod']);

  const getTransactionStatusLabel = () => {
    return transaction.status;
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {transaction.status === 'PENDING' &&
            <Checkbox
              checked={selected}
              onChange={() => toggle(transaction)}
              color='secondary'
            />
          }
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={() => { history.push('/revolusend/transfers/' + transaction.id) }}>
            <DetailsIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {`${transaction.sender.first_name} ${transaction.sender.last_name} (${transaction.sender.email})`}
        </TableCell>
        <TableCell>
          {`${transaction.receiver.first_name} ${transaction.receiver.last_name} (${transaction.receiver.email})`}
        </TableCell>
        <TableCell>
          {transaction.receiver.account_fields?.map(a => a.value).join(' - ')}
        </TableCell>
        <TableCell>
          {transaction.product.country.name}
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(transaction.receive_amount)} ${transaction.product.currency.iso}`}
        </TableCell>
        <TableCell>
          <Chip label={t(getTransactionStatusLabel())} color={['ACCEPTED', 'DELIVERED', 'FAILED', 'ISSUED'].includes(transaction.status) ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          <Chip label={transaction.refunded_on ? t('YES') : t('NO')} color={transaction.refunded_on ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          {moment(transaction.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ProcessModal = (props: { transactionIds: number[], open: boolean, onClose: () => void, batchId: number }) => {
  const { open, onClose, transactionIds, batchId } = props;
  const api = useRevolusendApi();
  const { t } = useTranslation('revolusendBatches');
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setSuccess(true);
    setLoading(false);
  }, [open]);

  const process = () => {
    utils.runAsync(async () => {
      setLoading(true);
      await api.proccessRevolusendBatchTransactions(batchId, transactionIds, success ? 'SUCCESS' : 'ERROR');
      onClose();
    }, (e) => {
      setLoading(false);
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('PROCESS_MODAL')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('PROCESS_MODAL_DESCRIPTION', { transactionCount: transactionIds.length })}
        </DialogContentText>
        <Box display='flex' justifyContent='center'>
          <ButtonGroup>
            <Button
              onClick={() => setSuccess(true)}
              variant={success ? 'contained' : 'outlined'}
              color={success ? 'primary' : 'default'}>
              {t('PROCESS_SUCCESS')}
            </Button>
            <Button
              onClick={() => setSuccess(false)}
              variant={success ? 'outlined' : 'contained'}
              color={success ? 'default' : 'primary'}>
              {t('PROCESS_ERROR')}
            </Button>
          </ButtonGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton color='primary' loading={loading} disabled={loading} onClick={process}>
          {t('PROCESS')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

export const BatchDetails = (props: RouteComponentProps<{ id: string }>) => {
  const batchId = props.match.params.id
  const { t, i18n } = useTranslation(['revolusendBatches', 'revolusendTransfers', 'revolusendDeliveryMethod']);
  const classes = useStyles();
  const api = useRevolusendApi();
  const history = useHistory();

  moment.locale(i18n.language);

  const [batchDetails, setBatchDetails] = React.useState<BatchDetailsAlias | null>(null);
  const [loading, setLoading] = React.useState(true);

  const [loadingTransactions, setLoadingTransactions] = React.useState(true);
  const [transactions, setTransactions] = React.useState<TransactionListItem[]>([]);
  const [transactionStates, setTransactionStates] = React.useState<{ [key: number]: boolean }>({});
  const [totalAmount, setTotalAmount] = React.useState(0);

  const [showProcessModal, setShowProcessModal] = React.useState(false);

  const loadTransactions = () => {
    utils.runAsync(async () => {
      setLoadingTransactions(true);
      let count = 0;
      let pTransactions: TransactionListItem[] = [];
      do {
        const { transactions: trx, count: cnt } = await api.listRevolusendTransactions({
          batchId: Number(batchId),
          paid: true
        }, 50, pTransactions.length, 'desc');
        count = cnt;
        pTransactions.push(...trx);
      } while (pTransactions.length < count);
      pTransactions = pTransactions.sort((a, b) => {
        let aValue = a.status === 'PENDING' ? 0 : 1;
        let bValue = b.status === 'PENDING' ? 0 : 1;
        return aValue - bValue;
      })
      let amount = 0;
      for (const transaction of pTransactions) {
        amount += transaction.receive_amount;
      }
      setTotalAmount(amount);
      setTransactionStates(pTransactions.reduce((p, c) => {
        p[c.id] = c.status === 'PENDING';
        return p;
      }, {} as any))
      setTransactions(pTransactions);
    }, () => {
      setLoadingTransactions(false);
    })
  }

  const loadBatchDetails = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setBatchDetails(await api.getRevolusendBatchDetails(batchId));
      setLoading(false);
    }, (e) => {
      if (e) {
        history.push('/revolusend/batches');
      }
    });
  }

  React.useEffect(() => {
    loadBatchDetails();
    loadTransactions();
  }, []);

  if (loading && batchDetails === null) {
    return (
      <Grid container justify='center'>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  const selectedTransactions: number[] = [];
  for (const transactionId in transactionStates) {
    if (transactionStates[transactionId]) {
      selectedTransactions.push(Number(transactionId));
    }
  }

  return (
    <>
      <ProcessModal
        batchId={Number(batchId)}
        transactionIds={selectedTransactions}
        open={showProcessModal}
        onClose={() => {
          loadBatchDetails();
          loadTransactions();
          setShowProcessModal(false);
        }} />
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={t('BATCH')} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('PROVIDER')} secondary={batchDetails?.provider.name} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('CREATED')} secondary={moment(batchDetails?.batch.created).format('lll')} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={t('PROCESSED')}
                          secondary={
                            batchDetails?.batch.processed ? moment(batchDetails?.batch.processed).format('lll') : t('NOT_YET')
                          }
                        />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={t('TOTAL_AMOUNT')}
                          secondary={
                            `${utils.currencyFormat(totalAmount)} EUR`
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List dense disablePadding>
                      <ListItem disableGutters>
                        <ListItemText primary={t('TOTAL_TRANSACTIONS')} secondary={batchDetails?.batch.transaction_count} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('PENDING_TRANSACTIONS')} secondary={batchDetails?.batch.pending_transaction_count} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('SUCCESS_TRANSACTIONS')} secondary={batchDetails?.batch.success_transaction_count} />
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemText primary={t('FAILED_TRANSACTIONS')} secondary={batchDetails?.batch.failed_transaction_count} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12}>

                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button component={'a'} href={batchDetails?.document.url} target='_blank'>
                  {t('DOWNLOAD_BATCH_FILE')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Attachments entity='batch' id={batchId} />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <Box paddingY={3} paddingX={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography variant='h5'>{t('TRANSFERS')}</Typography>
                      </Grid>
                      <Grid container item xs={4} justify='flex-end'>
                        <Button
                          onClick={() => setShowProcessModal(true)}
                          disabled={batchDetails?.batch.processed ? true : false}
                          variant='contained'
                          color='primary'>
                          {t('PROCESS')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>{t('revolusendTransfers:ISSUER')}</TableCell>
                          <TableCell>{t('revolusendTransfers:RECEIVER')}</TableCell>
                          <TableCell>{t('revolusendTransfers:ACCOUNT')}</TableCell>
                          <TableCell>{t('revolusendTransfers:COUNTRY')}</TableCell>
                          <TableCell>{t('revolusendTransfers:AMOUNT')}</TableCell>
                          <TableCell>{t('revolusendTransfers:STATUS')}</TableCell>
                          <TableCell>{t('revolusendTransfers:REFUNDED')}</TableCell>
                          <TableCell>{t('revolusendTransfers:CREATED')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions.map(t =>
                          <Row
                            toggle={t => setTransactionStates({ ...transactionStates, [t.id]: !transactionStates[t.id] })}
                            selected={transactionStates[t.id]}
                            transaction={t}
                            key={t.id}
                          />)}
                        {loadingTransactions && transactions.length === 0 &&
                          <TableCell colSpan={10}>
                            <Box padding={2}>
                              <Grid container justify='center' item xs={12}>
                                <CircularProgress />
                              </Grid>
                            </Box>
                          </TableCell>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}