import React, { BaseSyntheticEvent } from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useRevolusendApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { Country } from '../../services/types/revolusend/country.type';
import { Currency } from '../../services/types/revolusend/currency.type';
import { TransactionType, TransactionTypeCode } from '../../services/types/revolusend/transactionType';
import DetailsIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { TransactionListItem } from '../../services/types/revolusend/transactionListItem.type';
import { useHistory } from 'react-router-dom';
import { Provider } from '../../services/types/revolusend/provider.type';
import { Tenant } from '../../services/types/revolusend/tenant.type';
import { TransactionStatus } from '../../services/types/revolusend/transactionStatus.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);



const Row = (props: { transaction: TransactionListItem }) => {
  const { transaction } = props;
  const history = useHistory();
  const { t } = useTranslation(['revolusendTransfers', 'revolusendDeliveryMethod']);

  const getTransactionStatusLabel = () => {
    return transaction.status;
  }

  const getBeneficiaryName = () => {
    if (transaction.receiver.email) {
      return `${transaction.receiver.first_name} ${transaction.receiver.last_name} (${transaction.receiver.email})`
    }
    return `${transaction.receiver.first_name ?? ''} ${transaction.receiver.last_name ?? ''}`;
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <a href={'/revolusend/transfers/' + transaction.id} target='_blank'>
            <IconButton size='small'>
              <DetailsIcon />
            </IconButton>
          </a>
        </TableCell>
        <TableCell>{transaction.id}</TableCell>
        <TableCell>
          {transaction.tenant.name}
        </TableCell>
        <TableCell>
          {`${transaction.sender.first_name} ${transaction.sender.last_name} (${transaction.sender.email})`}
        </TableCell>
        <TableCell>
          {getBeneficiaryName()}
        </TableCell>
        <TableCell>
          {transaction.product.country.name}
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(transaction.receive_amount)} ${transaction.product.currency.iso}`}
        </TableCell>
        <TableCell>
          {`${utils.currencyFormat(transaction.amount)} ${transaction.source_currency.iso}`}
        </TableCell>
        <TableCell>
          <Chip label={transaction.paid ? t('YES') : t('NO')} color={transaction.paid ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          <Chip label={t(getTransactionStatusLabel())} color={['ACCEPTED', 'DELIVERED', 'ISSUED'].includes(transaction.status) ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          <Chip label={transaction.refunded_on ? t('YES') : t('NO')} color={transaction.refunded_on ? 'primary' : 'default'} />
        </TableCell>
        <TableCell>
          {t(`revolusendDeliveryMethod:${transaction.product.transaction_type.code}`)}
        </TableCell>
        <TableCell>
          {transaction.reference}
        </TableCell>
        <TableCell>
          {transaction.tenant_external_id ?? '-'}
        </TableCell>
        <TableCell>
          {moment(transaction.created).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
        <TableCell>
          {moment(transaction.modified).format('DD-MM-YYYY HH:mm:ss')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Transfers = () => {
  const { t } = useTranslation(['revolusendTransfers', 'revolusendDeliveryMethod']);
  const api = useRevolusendApi();
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = React.useState<TransactionListItem[]>([]);

  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [selectedTenant, selectTenant] = React.useState<Tenant | null>(null);

  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);

  const [providers, setProviders] = React.useState<Provider[]>([]);
  const [selectedProvider, selectProvider] = React.useState<Provider | null>(null);

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<Currency | null>(null);

  const [transactionTypes, setTransactionTypes] = React.useState<TransactionType[]>([]);
  const [selectedTransactionType, selectTransactionType] = React.useState<TransactionType | null>(null);

  const [senderEmail, setSenderEmail] = React.useState('');
  const [tenantExternalId, setTenantExternalId] = React.useState('');
  const [reference, setReference] = React.useState('');

  const states = [
    'ACCEPTED',
    'DELIVERED',
    'ISSUED',
    'UNDER_REVIEW',
    'CANCELLED',
    'FAILED',
    'PROCESSING',
    'PENDING'
  ];

  const [selectedStatus, selectStatus] = React.useState<string | null>(null);
  const [paid, setPaid] = React.useState(true);
  const [pendingRefund, setPendingRefund] = React.useState(false);

  const [page, setPage] = React.useState(0);


  const limit = 15;

  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const { transactions, count } = await api.listRevolusendTransactions({
        providerId: selectedProvider?.id,
        tenantId: selectedTenant?.id,
        countryId: selectedCountry?.id,
        currencyId: selectedCurrency?.id,
        transactionTypeId: selectedTransactionType?.id,
        status: selectedStatus ? selectedStatus : undefined,
        senderEmail: senderEmail.length > 0 ? senderEmail : undefined,
        tenantExternalId: tenantExternalId.length > 0 ? tenantExternalId : undefined,
        reference: reference.length > 0 ? reference : undefined,
        paid,
        onlyPendingRefund: pendingRefund
      }, limit, page * limit, 'desc');
      setTransactions(transactions);
      setCount(count);
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      setProviders(await api.listRevolusendProviders());
    });
    utils.runAsync(async () => {
      setCountries(await api.listRevolusendCountries());
    });
    utils.runAsync(async () => {
      setCurrencies(await api.listRevolusendCurrencies());
    });
    utils.runAsync(async () => {
      setTransactionTypes(await api.listRevolusendTransactionTypes());
    });
    utils.runAsync(async () => {
      setTenants(await api.listTenants());
    });
  }, []);

  React.useEffect(loadData, [page]);

  React.useEffect(() => {
    if (pendingRefund) {
      selectStatus(null);
      setPaid(true);
    }
    const delayDebounceFn = setTimeout(() => {
      setPage(0);
      loadData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedCountry,
    selectedCurrency,
    selectedTransactionType,
    selectedStatus,
    senderEmail,
    tenantExternalId,
    reference,
    selectedProvider,
    selectedTenant,
    paid,
    pendingRefund
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectTenant(value);
              }}
              value={selectedTenant}
              options={tenants}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_TENANT')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectProvider(value);
              }}
              value={selectedProvider}
              options={providers}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_PROVIDER')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectStatus(value);
              }}
              value={selectedStatus}
              options={states}
              autoHighlight
              disabled={loading || pendingRefund}
              getOptionLabel={(option) => t(option)}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_STATUS')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCountry(value);
              }}
              value={selectedCountry}
              options={countries}
              disabled={loading}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_COUNTRY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCurrency(value);
              }}
              value={selectedCurrency}
              options={currencies}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => option.iso}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_CURRENCY')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectTransactionType(value);
              }}
              value={selectedTransactionType}
              options={transactionTypes}
              autoHighlight
              disabled={loading}
              getOptionLabel={(option) => t('revolusendDeliveryMethod:' + option.code)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => t('revolusendDeliveryMethod:' + option.code)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('SEARCH_TRANSACTION_TYPE')}
                  variant='standard'
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('SEARCH_SENDER')}
              value={senderEmail}
              disabled={loading}
              onChange={(e) => setSenderEmail(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('TENANT_EXTERNAL_ID')}
              disabled={loading}
              value={tenantExternalId}
              onChange={(e) => setTenantExternalId(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('SEARCH_REFERENCE')}
              value={reference}
              disabled={loading}
              onChange={(e) => setReference(e.target.value)}
              variant='standard'
              fullWidth={true}
            />
          </Grid>
          <Grid container justify='center' item xs={10} sm={2}>
            <FormControlLabel
              disabled={loading || pendingRefund}
              control={<Switch checked={paid} onChange={() => setPaid(!paid)} />}
              label={t('PAID_CHECK')}
            />
          </Grid>
          <Grid container justify='center' item xs={10} sm={2}>
            <FormControlLabel
              disabled={loading}
              control={<Switch checked={pendingRefund} onChange={() => setPendingRefund(!pendingRefund)} />}
              label={t('REFUNDED_CHECK')}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('ID')}</TableCell>
                      <TableCell>{t('TENANT')}</TableCell>
                      <TableCell>{t('ISSUER')}</TableCell>
                      <TableCell>{t('RECEIVER')}</TableCell>
                      <TableCell>{t('COUNTRY')}</TableCell>
                      <TableCell>{t('AMOUNT')}</TableCell>
                      <TableCell>{t('PAYMENT')}</TableCell>
                      <TableCell>{t('PAID')}</TableCell>
                      <TableCell>{t('STATUS')}</TableCell>
                      <TableCell>{t('REFUNDED')}</TableCell>
                      <TableCell>{t('TYPE')}</TableCell>
                      <TableCell>{t('REFERENCE')}</TableCell>
                      <TableCell>{t('TENANT_EXTERNAL_ID')}</TableCell>
                      <TableCell>{t('CREATED')}</TableCell>
                      <TableCell>{t('UPDATED')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map(t =>
                      <Row
                        transaction={t}
                        key={t.id}
                      />)}
                    {loading && transactions.length === 0 &&
                      <TableCell colSpan={16}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && transactions.length === 0 &&
                      <TableCell colSpan={16}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[limit]}
                count={count}
                rowsPerPage={limit}
                page={page}
                onChangePage={(event, page) => { setPage(page) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
