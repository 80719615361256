import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Container, Typography } from '@material-ui/core';
import { useAuth, useContext } from '../context';
import utils from '../services/utils.service';
import { Redirect } from 'react-router-dom';
import logo from '../assets/img/logo.png'
import background from '../assets/img/background.svg'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    height: '100vh',
    background: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  jumbotron: {
    width: 600,
    maxWidth: '90%',
    minHeight: 300,
    backgroundColor: 'rgba(255,255,255,0.7)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20
  },
  img: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%'
  },
  button: {
    marginBottom: 10
  }
}));

export const Login = ({ children }: { children: any }) => {
  const style = useStyles();
  const { t } = useTranslation('login');
  const auth = useAuth();
  const context = useContext();


  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const login = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setError(false);
      await auth.login();
    }, (e) => {
      setLoading(false);
      if (e) {
        setError(true);
      }
    })
  }

  if (context.data.isSignedIn) {
    return <Redirect to='/dashboard' />
  }

  return (
    <div className={style.main}>
      <div className={style.jumbotron}>
        <img className={style.img} src={logo} />
        <div className={style.wrapper}>
          <Button
            className={style.button}
            onClick={login}
            disabled={loading}
            variant='contained'
            size='large'
            color='primary'>
            {t('LOGIN')}
          </Button>
          {loading &&
            <CircularProgress color='secondary' />
          }
          {!loading && error &&
            <Typography color='error' variant='subtitle2'>
              {t('ABORTED')}
            </Typography>
          }
        </div>
      </div>
    </div>
  )
};

