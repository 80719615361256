import React from 'react';
import { Box, Button, CircularProgress, Collapse, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core';
import { Tenant } from '../../services/types/revolusend/tenant.type';
import { useRevolusendApi } from '../../context';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { TenantMarkup } from '../../services/types/revolusend/tenantMarkup.type';
import { LoadingButton } from '../../components/loadingButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import notFoundImg from '../../assets/img/not-found.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);



type TenantFormErrors = {
  name: boolean,
  markup: boolean
}

const EditTenantModal = (
  props: {
    open: boolean,
    onClose: () => void,
    tenant?: Tenant,
  }) => {
  const api = useRevolusendApi();
  const { open, onClose, tenant } = props;
  const { t } = useTranslation('revolusendTenants');

  const [name, setName] = React.useState('');
  const [markup, setMarkup] = React.useState('0');
  const [loading, setLoading] = React.useState(false);

  const [errors, setErros] = React.useState<TenantFormErrors>({
    name: false,
    markup: false,
  });

  React.useEffect(() => {
    setMarkup(utils.getStringOrDefault(tenant?.exchange_markup));
    setName(utils.getStringOrDefault(tenant?.name));
    setErros({
      name: false,
      markup: false,
    });
  }, [props]);

  const validate = () => {
    const formErrors = {
      name: false,
      markup: false,
    }
    if (
      markup.length > 0 && (
        isNaN(Number.parseFloat(markup)) ||
        Number.parseFloat(markup) > 100 ||
        Number.parseFloat(markup) < 0
      )
    ) {
      formErrors.markup = true;
    }
    if (name.length === 0) {
      formErrors.name = true;
    }
    setErros(formErrors);
    return Object.values(formErrors).find(v => v === true) ? false : true
  }

  const update = () => {
    if (validate()) {
      utils.runAsync(async () => {
        setLoading(true);
        await api.updateTenant({ id: tenant!.id, name, exchange_markup: parseFloat(markup) });
        onClose();
      }, (e) => {
        setLoading(false);
      });
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('UPSERT_TENANT')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('UPSERT_TENANT_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('TENANT_NAME')}
              value={name}
              error={errors.name}
              helperText={errors.name ? t('REQUIRED_ERROR') : ''}
              onChange={(e) => setName(e.target.value)}
              type='text'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('PERCENTAGE_AMOUNT')}
              value={markup}
              error={errors.markup}
              helperText={errors.markup ? t('PERCENTAGE_AMOUNT_ERROR') : ''}
              onChange={(e) => setMarkup(e.target.value)}
              type='number'
              InputProps={{
                endAdornment: <InputAdornment position='end'>{'%'}</InputAdornment>
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton disabled={loading} loading={loading} onClick={update} color='primary'>
          {t('UPDATE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}

const Row = (props: { tenant: Tenant, updateTenants: () => void }) => {
  const { tenant, updateTenants } = props;
  const { t } = useTranslation(['revolusendTenants']);
  const classes = useStyles();

  const [editTenant, setEditTenant] = React.useState<Tenant | undefined>(undefined);
  const [showEditTenantModal, setShowUpsertTenantModal] = React.useState(false);


  return (
    <React.Fragment>
      <EditTenantModal
        open={showEditTenantModal}
        onClose={() => {
          updateTenants();
          setShowUpsertTenantModal(false);
          setEditTenant(undefined);
        }}
        tenant={editTenant}
      />
      <TableRow className={classes.noBottomBorder}>
        <TableCell>{tenant.id}</TableCell>
        <TableCell>{tenant.name}</TableCell>
        <TableCell>{tenant.exchange_markup} %</TableCell>
        <TableCell>
          <IconButton size='small' onClick={() => {
            setEditTenant(tenant);
            setShowUpsertTenantModal(true);
          }}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Tenants = () => {
  const { t } = useTranslation(['revolusendTenants']);
  const api = useRevolusendApi();

  const [loading, setLoading] = React.useState(true);

  const [tenants, setTenants] = React.useState<Tenant[]>([]);


  const classes = useStyles();

  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setTenants(await api.listTenants());
    }, () => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('ID')}</TableCell>
                      <TableCell>{t('NAME')}</TableCell>
                      <TableCell>{t('MARKUP')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tenants.map(t =>
                      <Row
                        tenant={t}
                        key={t.id}
                        updateTenants={() => { loadData() }}
                      />)}
                    {loading && tenants.length === 0 &&
                      <TableCell colSpan={5}>
                        <Box padding={2}>
                          <Grid container justify='center' item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Box>
                      </TableCell>
                    }
                    {!loading && tenants.length === 0 &&
                      <TableCell colSpan={5}>
                        <Box padding={2}>
                          <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                          <img className={classes.img} src={notFoundImg} />
                        </Box>
                      </TableCell>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
