import { Config } from '../config';
import { Auth } from './auth.service';
import { Consumer } from './types/directBanking/consumer.type';
import { OrderListItem } from './types/directBanking/orderListItem.type';
import { DirectBankingOrderDetails } from './types/directBanking/OrderDetails.type';
import { BatchListItem } from './types/directBanking/batchListItem.type';
import { RefundListItem } from './types/directBanking/refundListItem.type';
import { BatchDetails } from './types/directBanking/batchDetails.type';
import { Attachment } from './types/directBanking/attachment.type';

export class DirectBankingMiddlewareApi {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async listDirectBankingConsumers(): Promise<Consumer[]> {
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/consumers',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch direct banking consumers')
    };
    return (await response.json()).consumers;
  }

  async listDirectBankingOrders(
    filters: {
      consumerId?: number;
      fromDate?: Date;
      toDate?: Date;
      status?: string;
      externalReference?: string;
      hasRefunds?: boolean,
      paid: boolean
    },
    limit: number,
    offset: number,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<{ count: number; orders: OrderListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    if (filters.consumerId) {
      params.append('consumer_id', filters.consumerId.toString(10));
    }
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.externalReference) {
      params.append('external_reference', filters.externalReference);
    }
    if (filters.paid === true) {
      params.append('paid', filters.paid.toString());
    }
    if (filters.hasRefunds === true) {
      params.append('has_refunds', filters.hasRefunds.toString());
    }

    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/orders?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch revolusend transactions');
    }
    const result =  await response.json()
    return result
  }

  async getDirectBankingOrderDetails(id: string | number): Promise<DirectBankingOrderDetails> {
    console.log('dentro de getDirectBankingOrderDetails')
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/orders/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch direct banking order details');
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async issueDirectBankingRefund(orderId: number, amount: number) {
    console.log('algo')
    const payload: any = {
      amount: amount
    }
    
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/orders/' + orderId + '/refund',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        },
        body: JSON.stringify(payload)
      }
    )
    if (!response.ok) {
      console.log(response)
      throw new Error('Could not create the refund');
    }
  }

  async listDirectBankingBatches(
    filters: {
      consumerId?: number;
      start?: Date;
      end?: Date;
    },
    limit: number,
    offset: number,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<{ count: number; batches: BatchListItem[] }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    if (filters.consumerId) {
      params.append('consumer_id', filters.consumerId.toString(10));
    }
    if (filters.start) {
      params.append('start', filters.start.toISOString());
    }
    if (filters.end) {
      params.append('end', filters.end.toISOString());
    }

    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/batches?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch direct banking batches');
    }
    const result = await response.json();
    return result;
  }
  async listDirectBankingRefunds(
    filters: {
      consumerId?: number;
      status?: 'CREATED'|'PROCESSED';
    }
  ): Promise<RefundListItem[]> {
    const params = new URLSearchParams();
    if (filters.consumerId) {
      params.append('consumer_id', filters.consumerId.toString(10));
    }
    if (filters.status) {
      params.append('status', filters.status);
    }
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/refunds?' + params.toString(),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch direct banking refunds');
    }
    const result = await response.json();
    return result.refunds;
  }

  async newBatch(
    companyName: string,
    companyId: string,
    bic: string,
    account: string,
    refundIds: number[]
  ): Promise<number> {
    const response = await fetch(
      `${this.config.DIRECTBANKING_MIDDLEWARE_API_URL}/admin/batches`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refund_ids: refundIds,
          company_name: companyName,
          company_id: companyId,
          bic,
          account
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not create batch');
    } else {
      return (await response.json()).id;
    }
  }

  async getDirectBankingBatchDetails(id: string | number): Promise<BatchDetails> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/batches/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken()
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch directbanking batch details');
    }
    return response.json();
  }

  async getDirectBankingUploadUrlForAttachment(id: string | number, name: string, type: string): Promise<string> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/batches/' + id + '/attachments',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name, content_type: type
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch directbanking batch attachment upload url');
    }
    return (await response.json()).url;
  }

  async getDirectBankingAttachments(id: string | number): Promise<Attachment[]> {
    if (typeof id === 'number') {
      id = id.toString(10);
    }
    const response = await fetch(
      this.config.DIRECTBANKING_MIDDLEWARE_API_URL + '/admin/batches/' + id + '/attachments',
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + await this.auth.getAccessToken(),
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch directbanking batch attachments');
    }
    return (await response.json()).attachments;
  }


}