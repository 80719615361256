import React from 'react';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../../services/utils.service';
import { useVisaInstantApi } from '../../context';
import notFoundImg from '../../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/CreditCard';
import { useHistory } from 'react-router-dom';
import { Card } from '../../services/types/visaInstant/card.type';
import { Topup } from '../../services/types/visaInstant/topup.type';
import { TopupStatus } from '../../services/types/visaInstant/topupStatus.enum';
import { Currency } from '../../services/types/visaInstant/currency.type';
import moment from 'moment';
import { TopupListItem } from '../../services/types/visaInstant/topupListItem.type';
import CheckIcon from '@material-ui/icons/Check';
import { LoadingButton } from '../../components/loadingButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            width: 400,
            maxWidth: '100%',
            display: 'block',
            margin: '0 auto',
        },
        noBottomBorder: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
    })
);
const MarkAsSuccessModal = (props: { topupId?: number, open: boolean, onClose: () => void }) => {
    const { open, onClose, topupId } = props;
    const api = useVisaInstantApi();
    const { t } = useTranslation('visaInstantCards');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(false);
    }, [open]);

    const markAsSuccess = () => {
        if (!topupId) {
            return;
        }
        utils.runAsync(async () => {
            setLoading(true);
            await api.markTopupAsSuccess(topupId);
            onClose();
        }, (e) => {
            setLoading(false);
        })
    }

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>{t('MARK_TOPUP_AS_SUCCESS_MODAL_TITLE')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography gutterBottom>
                        {t('MARK_TOPUP_AS_SUCCESS_MODAL_DESCRIPTION')}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('CANCEL')}
                </Button>
                <LoadingButton color='primary' loading={loading} disabled={loading} onClick={markAsSuccess}>
                    {t('MARK_AS_SUCCESS_BUTTON_TITLE')}
                </LoadingButton>
            </DialogActions>
        </Dialog >
    )
}

const Row = (
    props: {
        embedded: boolean;
        card: Card;
        topup: Topup;
        currencies: Currency[];
        onMarkAsSuccess: () => void;
    }) => {
    const { card, topup, currencies, onMarkAsSuccess, embedded } = props;
    const history = useHistory();
    const { t, i18n } = useTranslation('visaInstantCards');
    const currency = currencies.find(c => c.id === topup.paid_currency_id);
    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <Grid container spacing={2}>
                        {!embedded &&
                            <Grid item xs={12} md={6}>
                                <IconButton size='small' onClick={() => { history.push('/visa-instant/cards/' + card.id) }}>
                                    <DetailsIcon />
                                </IconButton>
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            {[TopupStatus.NEW,TopupStatus.PROCESSED].includes(topup.status) &&
                                <IconButton size='small' onClick={() => {
                                    onMarkAsSuccess();
                                }}>
                                    <CheckIcon />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>{topup.id}</TableCell>
                {!embedded &&
                    <TableCell>
                        {`${card.last4} - MP ${card.mp}`}
                    </TableCell>
                }
                <TableCell>
                    <Chip label={t(topup.status)} color={[TopupStatus.NEW, TopupStatus.PROCESSED, TopupStatus.SUCCESS].includes(topup.status) ? 'primary' : 'default'} />
                </TableCell>
                <TableCell>
                    {`${utils.currencyFormat(topup.amount)} EUR`}
                </TableCell>
                <TableCell>
                    {`${utils.currencyFormat(topup.paid_amount)} ${currency?.iso}`}
                </TableCell>
                <TableCell>
                    {`${utils.currencyFormat(topup.paid_fee)} ${currency?.iso}`}
                </TableCell>
                <TableCell>
                    {moment(topup.created).format('lll')}
                </TableCell>
                <TableCell>
                    {topup.modified ? moment(topup.modified).format('lll') : '-'}
                </TableCell>
                <TableCell>
                    {topup.file_name ? topup.file_name : '-'}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export const Topups = ({ embedded = false, cardId }: { embedded: boolean; cardId?: number }) => {
    const { t } = useTranslation(['visaInstantCards']);
    const api = useVisaInstantApi();

    const colSpan = embedded ? 9 : 10;

    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const [topups, setTopups] = React.useState<TopupListItem[]>([]);
    const [currencies, setCurrencies] = React.useState<Currency[]>([]);
    const [markAsSuccessTopup, setMarkAsSuccessTopup] = React.useState<number | undefined>();

    const topupStates = [
        TopupStatus.PENDING,
        TopupStatus.NEW,
        TopupStatus.PROCESSED,
        TopupStatus.SUCCESS
    ];

    const [selectedTopupStatus, setSelectedTopupStatus] = React.useState<TopupStatus | null>();
    const [page, setPage] = React.useState(0);
    const limit = 25;

    const classes = useStyles();

    const loadData = () => {
        utils.runAsync(async () => {
            setLoading(true);
            const { topups, count } = await api.listTopups({
                status: selectedTopupStatus ? selectedTopupStatus : undefined,
                cardId
            }, limit, page * limit);
            setCurrencies(await api.getCurrencies());
            setTopups(topups);
            setCount(count);
        }, () => {
            setLoading(false);
        })
    }

    React.useEffect(loadData, [page]);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setPage(0);
            loadData();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [selectedTopupStatus]);

    return (
        <>
            <MarkAsSuccessModal
                open={markAsSuccessTopup !== undefined}
                topupId={markAsSuccessTopup}
                onClose={() => {
                    setMarkAsSuccessTopup(undefined);
                    loadData();
                }} />
            <Grid container spacing={2}>
                {!embedded &&
                    <Grid item container xs={12}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    onChange={(event, value) => {
                                        setSelectedTopupStatus(value);
                                    }}
                                    value={selectedTopupStatus}
                                    options={topupStates}
                                    autoHighlight
                                    disabled={loading}
                                    getOptionLabel={(option) => t(option)}
                                    getOptionSelected={(option, value) => option === value}
                                    renderOption={(option) => t(option)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('TOPUP_SEARCH_STATUS')}
                                            variant='standard'
                                            fullWidth={true}
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper>
                        <Grid container>
                            {embedded &&
                                <Grid item xs={12}>
                                    <Box padding={2}>
                                        <Typography variant='h5'>
                                            {t('TOPUPS')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>{t('ID')}</TableCell>
                                                {!embedded &&
                                                    <TableCell>{t('CARD')}</TableCell>
                                                }
                                                <TableCell>{t('STATUS')}</TableCell>
                                                <TableCell>{t('AMOUNT')}</TableCell>
                                                <TableCell>{t('PAYMENT_AMOUNT')}</TableCell>
                                                <TableCell>{t('FEE')}</TableCell>
                                                <TableCell>{t('CREATED')}</TableCell>
                                                <TableCell>{t('UPDATED')}</TableCell>
                                                <TableCell>{t('FILE_NAME')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topups.map(t =>
                                                <Row
                                                    embedded={embedded}
                                                    topup={t.topup}
                                                    card={t.card}
                                                    currencies={currencies}
                                                    onMarkAsSuccess={() => {
                                                        setMarkAsSuccessTopup(t.topup.id);
                                                    }}
                                                    key={t.topup.id}
                                                />)}
                                            {loading && topups.length === 0 &&
                                                <TableCell colSpan={colSpan}>
                                                    <Box padding={2}>
                                                        <Grid container justify='center' item xs={12}>
                                                            <CircularProgress />
                                                        </Grid>
                                                    </Box>
                                                </TableCell>
                                            }
                                            {!loading && topups.length === 0 &&
                                                <TableCell colSpan={colSpan}>
                                                    <Box padding={2}>
                                                        <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                                                        <img className={classes.img} src={notFoundImg} />
                                                    </Box>
                                                </TableCell>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[limit]}
                                    count={count}
                                    rowsPerPage={limit}
                                    page={page}
                                    onChangePage={(event, page) => { setPage(page) }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
